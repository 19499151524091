import React from "react";

import ResourceSelect from "./ResourceSelect";

import useDivisionSelect from "./hooks/useDivisionSelect";

function DivisionSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected } = useDivisionSelect();

  return (
    <ResourceSelect
      label="Division"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
    />
  );
}

export default DivisionSelect;
