import React, { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";

import { FormFeedback, FormGroup, Label, Input, Button } from "reactstrap";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import AbilityButton from "@/components/AbilityButton";
import { Can } from "@/components/Ability";
import HockeyYearField from "@/components/HockeyYearField";

import GameGeneralSettingsFieldset from "@/components/GameGeneralSettingsFieldset";
import GameStatsSettingsFieldset from "@/components/GameStatsSettingsFieldset";
import { SeasonPenaltySettingsFieldset } from "@/components/GamePenaltySettingsFieldsets/SeasonPenaltySettings";
import GameFlaggingSettingsFieldset from "@/components/GameFlaggingSettingsFieldset";
import PlayerOfTheGameFieldset from "./components/PlayerOfTheGameFieldset";
import LiveScoringFieldset from "./components/LiveScoringFieldset";
import ConfirmSeasonArchivingModal from "@/components/ConfirmSeasonArchivingModal";
import LeagueAppFieldset from "./components/LeagueAppFieldSet/LeagueAppFieldset"

import { useSeasonForm } from "./hooks";

// season settings form
function SeasonForm({ initialValues, associationId, leagueId, seasonId }) {
  const {
    fields,
    submit,
    deleteSeason,
    archiveSeason,
    isPristine,
    isInvalid,
    isSubmitting,
    isDeleting,
    isArchiving,
  } = useSeasonForm({
    initialValues,
    associationId,
    leagueId,
    seasonId,
  });

  const [isModalOpen, setModalIsOpen] = useState(false);

  const toggleModal = useCallback(() => !isArchiving && setModalIsOpen(!isModalOpen), [
    setModalIsOpen,
    isArchiving,
    isModalOpen
  ]);

  return (
    <DashboardForm
      namespace="season"
      className="season-form"
      isOperating={isSubmitting || isDeleting}
      onSubmit={submit}
    >
      <ContentSection>
        <FormItem>
          <FormGroup className="col-md-4 required">
            <Label for="seasonTitle">Season Name</Label>
            <Input
              id="seasonTitle"
              name="title"
              placeholder="e.g. ETA 2018-2019"
              {...fields.title.input}
            />

            {fields.title.input.invalid &&
              fields.title.errors.map((error) => <FormFeedback key={error}>{error}</FormFeedback>)}
          </FormGroup>

          <FormGroup className="col-md-4">
            <Label for="seasonExternalId">External ID</Label>
            <Input
              id="seasonExternalId"
              name="externalId"
              placeholder="Optional"
              {...fields.externalId.input}
            />

            {fields.externalId.input.invalid &&
              fields.externalId.errors.map((error) => (
                <FormFeedback key={error}>{error}</FormFeedback>
              ))}
          </FormGroup>

          <FormGroup className="col-md-4 required">
            <HockeyYearField startDate={fields.startDate} endDate={fields.endDate} />
          </FormGroup>
        </FormItem>
      </ContentSection>

      <GameGeneralSettingsFieldset
        value={fields.generalSettings.value}
        onChange={fields.generalSettings.setValue}
      />

      <GameStatsSettingsFieldset
        value={fields.statsSettings.value}
        onChange={fields.statsSettings.setValue}
      />

      <SeasonPenaltySettingsFieldset
        value={fields.penaltySettings.value}
        onChange={fields.penaltySettings.setValue}
      />

      <GameFlaggingSettingsFieldset
        value={fields.flaggingSettings.value}
        onChange={fields.flaggingSettings.setValue}
        penaltyCodes={fields.penaltySettings.value.penaltyCodes}
      />

      <PlayerOfTheGameFieldset
        value={fields.playerOfTheGame.value}
        onChange={fields.playerOfTheGame.setValue}
        errors={fields.playerOfTheGame.errors}
      />

      <LiveScoringFieldset
        value={fields.liveScoringMode.value}
        onChange={fields.liveScoringMode.setValue}
      />

      <LeagueAppFieldset
        value={fields.leagueAppMode.value}
        onChange={fields.leagueAppMode.setValue}
      />

      <DashboardFormFooter>
        {seasonId ? (
          <Fragment>
            <div className="dashboard-form-footer__secondary">
              <Can I="update" this={{ type: "seasons", id: seasonId }}>
                <Button
                  color="secondary"
                  size="sm"
                  outline
                  onClick={toggleModal}
                  disabled={isArchiving}
                >
                  Archive Season
                </Button>
                <ConfirmSeasonArchivingModal
                  seasonTitle={fields.title.initialValue}
                  isArchiving={isArchiving}
                  disabled={isSubmitting || isDeleting}
                  onConfirm={archiveSeason}
                  isModalOpen={isModalOpen}
                  setModalIsOpen={setModalIsOpen}
                  toggleModal={toggleModal}
                  setIsArchiving={()=>{}}
                />
              </Can>

              <Can I="delete" this={{ type: "seasons", id: seasonId }}>
                <ConfirmDeletionModal
                  resourceTitle={fields.title.initialValue}
                  resourceType="Season"
                  isOperating={isSubmitting || isArchiving}
                  isDeleting={isDeleting}
                  onDelete={deleteSeason}
                />
              </Can>
            </div>

            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={{ type: "seasons", id: seasonId }}
                action="update"
                type="submit"
                color="success"
                outline={isPristine || isInvalid}
                disabled={isPristine || isInvalid}
              >
                {isSubmitting ? "Saving Changes…" : "Save Changes"}
              </AbilityButton>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
            <AbilityButton
              subject={{ type: "seasons", leagueId }}
              action="create"
              type="submit"
              color="success"
              outline={isPristine || isInvalid}
              disabled={isPristine || isInvalid}
            >
              {isSubmitting ? "Creating Season…" : "Create Season"}
            </AbilityButton>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

SeasonForm.propTypes = {
  associationId: PropTypes.string.isRequired,
  leagueId: PropTypes.string.isRequired,
  seasonId: PropTypes.string,
  initialValues: PropTypes.shape({}),
};

export default SeasonForm;
