import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SignatureImage from "@/components/SignatureImage";

class Referee extends PureComponent {
  static propTypes = {
    position: PropTypes.string,
    name: PropTypes.string,
    externalId: PropTypes.string,
    signature: PropTypes.string
  };

  static defaultProps = {
    position: "N/A",
    name: "N/A",
    externalId: "N/A"
  };

  render() {
    const { position, name, externalId, signature } = this.props;

    return (
      <tr>
        <td>{position}</td>
        <td className="text-uppercase">{name}</td>
        <td>{externalId}</td>
        <td>
          <SignatureImage src={signature} />
        </td>
      </tr>
    );
  }
}

export default Referee;
