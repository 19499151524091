const BROADCAST_PROVIDERS = {
  // Add these alphabetically
  "": "",
  BLACKDOGHOCKEY: "BlackDogHockey",
  BNPRODUCTIONS: "B&N Productions",
  GAMEON: "GameOnStream",
  HIGHBUTTONSPORTS: "High Button Sports",
  HNLIVE: "HNLIVE",
  HOCKEYTV: "HockeyTV",
  LIVEBARN: "LiveBarn",
  MIHOCKEYTV: "MiHockeyTV",
  MNHOCKEYTV: "MNHockeyTV",
  MNHOCKEYTVYOUTUBE: "MNHockeyTV(Youtube)",
  OJLLTV: "OJLL TV",
  SPORTLIVESTREAMS: "SportLiveStreams",
  USAHOCKEYTV: "USA Hockey TV",
  WSBN: "WSBN",
  YOUTHSPORTSPLUS: "YouthSportsPlus",
  // Add these alphabetically
};

export default BROADCAST_PROVIDERS;

//Code will match the name in GAMES API Exactly
export const list = Object.entries(BROADCAST_PROVIDERS).map(([key, value]) => ({
  code: key,
  title: value,
}));

export const BROADCAST_PROVIDERS_LIST = list;

export const BROADCAST_PROVIDER_VALUES = Object.keys(BROADCAST_PROVIDERS);
