import React from "react";

import ResourceSelect from "./ResourceSelect";

import useLeagueSelect from "./hooks/useLeagueSelect";

function LeagueSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected } = useLeagueSelect();

  return (
    <ResourceSelect
      label="League"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
    />
  );
}

export default LeagueSelect;
