import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Table, Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import EmptyListMessage from "@/components/EmptyListMessage";

import { usePenaltyDurationsList } from "./hooks/usePenaltyDurationsList";

import PenaltyDuration from "./PenaltyDuration";

function PenaltyDurations({ value, onChange }) {
  const list = usePenaltyDurationsList({ value, onChange });

  return (
    <Fragment>
      <TitleBar title="Penalty Durations" titleTag="h4" slim>
        <Button type="button" size="sm" color="success" onClick={list.appendNewPenaltyDuration} outline>
          + New Penalty Duration
        </Button>
      </TitleBar>

      {list.isNotBlank ? (
        <Table striped borderless>
          <thead>
            <tr>
              <th>Penalty Duration (min)</th>
              <th>Class</th>
              <th className="narrow">
                <Button className="delete-button" size="sm" color="danger" onClick={list.deleteAll} outline>
                  Delete All
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.penaltyDurations.map(({ id, ...props }) => (
              <PenaltyDuration key={id} {...props} />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>
          No penalty durations specified.
          <br />
          Add a new one.
        </EmptyListMessage>
      )}
    </Fragment>
  );
}

PenaltyDurations.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    length: PropTypes.string.isRequired,
    penaltyClass: PropTypes.string.isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PenaltyDurations;
