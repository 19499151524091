import { call, all, takeLatest, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import axios from "axios";
import * as FAuth from "firebase/auth";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadAccount, acceptInvitation } from "@/lib/api/account";
import { loginSetCredentialsAction } from "@/redux/login/actions";
import { getPassword, getPendingUpdate } from "@/redux/login/selectors";
import { tokenRefreshRoutine, tokenSetRoutine } from "@/redux/token/routines";
import { ensureTokenIsFreshSaga } from "@/redux/token/sagas";
import { config } from "../../config";
import { account } from "./actions";
import { screens as invitationScreens } from "./reducers/invitation";

import {
  loadAccountRoutine,
  accountValidationRoutine,
  accountUpdateRoutine,
  newPasswordValidationRoutine,
  acceptInvitationValidationRoutine,
  loadUserRoleTeamsRoutine,
  acceptInvitationRoutine,
  loadMergedTeamPreviewRoutine,
} from "./routines";

import {
  getFirebaseUser,
  getAccountUser,
  getAccountIsLoaded,
  getAccountAttributes,
  getAcceptInvitationAttributes,
  getUserTeamAttributes,
  getCodeInfo,
  getPreviewRequestInfo,
} from "./selectors";

export function* loadAccountSaga() {
  yield put(loadAccountRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadAccount);

    yield put(loadAccountRoutine.success(data));

    const password = yield select(getPassword);
    const pendingPasswordUpdate = yield select(getPendingUpdate);
    if (!!password && pendingPasswordUpdate) {
      const { firstName, lastName, email } = yield select(getAccountAttributes);
      const accessToken = yield call(ensureTokenIsFreshSaga);
      const url = `${config.AUTH_GATEWAY}/auth/v4/account`;
      yield call(
        () => axios.post(
          url,
          {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
          },
          {
            headers: {
              "Authorization": `Bearer ${accessToken}`,
            },
          },
        )
      );
      yield put(loginSetCredentialsAction({ password: null, pendingUpdatePassword: false }));
    }
  } catch (error) {
    yield put(loadAccountRoutine.failure({ error }));
  } finally {
    yield put(loadAccountRoutine.fulfill());
  }
}

export function* ensureAccountLoadedSaga() {
  const isLoaded = yield select(getAccountIsLoaded);
  if (!isLoaded) {
    yield put(loadAccountRoutine.trigger());
  }
}

export function* accountValidationSaga() {
  let errors = [];

  const { email, firstName, lastName } = yield select(getAccountAttributes);

  if (email.length === 0) {
    errors.push({ email: "is required" });
  }

  if (firstName.length === 0) {
    errors.push({ firstName: "is required" });
  }

  if (lastName.length === 0) {
    errors.push({ lastName: "is required" });
  }

  if (errors.length === 0) {
    yield put(accountValidationRoutine.success());
    yield put(accountUpdateRoutine());
  } else {
    yield put(accountValidationRoutine.failure({ errors }));
  }
}

export function* newPasswordValidationSaga() {
  let errors = [];

  const { password, passwordConfirmation } = yield select(getAccountAttributes);

  if (password.length === 0) {
    errors.push({ password: "Password is required." });
  }

  if (password.length < 8) {
    errors.push({ password: "Should be at least 8 characters long." });
  }

  if (password !== passwordConfirmation) {
    errors.push({
      passwordConfirmation: "Passwords don't match. Please try again."
    });
  }

  if (errors.length === 0) {
    yield put(newPasswordValidationRoutine.success());
    yield put(accountUpdateRoutine());
  } else {
    yield put(newPasswordValidationRoutine.failure({ errors }));
  }
}

export function* accountUpdateSaga() {
  yield put(accountUpdateRoutine.request());

  try {
    const attributes = yield select(getAccountAttributes);

    const user = yield select(getFirebaseUser);
    if (!user) {
      throw new Error("No user, please logout then back in");
    }

    // if email changed, verify current password
    if (user.email !== attributes.email) {
      const credential = FAuth.EmailAuthProvider.credential(
        user.email,
        attributes.password,
      );
      yield call(() => user.reauthenticateWithCredential(credential));
    }

    const accessToken = yield call(ensureTokenIsFreshSaga);
    const url = `${config.AUTH_GATEWAY}/auth/v4/account`;
    const response = yield call(
      () => axios.post(
        url,
        {
          firstName: attributes.firstName,
          lastName: attributes.lastName,
          email: attributes.email,
        },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        },
      )
    );

    yield put(accountUpdateRoutine.success(response.data));
    yield put(push("/account/edit"));
  } catch (error) {
    if ('code' in error) {
      switch (error.code) {
        case "auth/requires-recent-login":
          yield put(accountUpdateRoutine.failure({ error: "Please logout then back in first to refresh authentication" }));
          break;
        case "auth/wrong-password":
          yield put(accountUpdateRoutine.failure({ error: "Incorrect password" }));
          break;
        default:
          yield put(accountUpdateRoutine.failure({ error: error.toString() }));
      }
    } else if ('response' in error && 'status' in error.response) {
      switch (error.response.status) {
        case 400:
          yield put(accountUpdateRoutine.failure({ error: error.response.statusText }));
          break;
        default:
          yield put(accountUpdateRoutine.failure({ error: error.response.statusText }));
          break;
      }
    } else {
      yield put(accountUpdateRoutine.failure({ error: error.toString() }));
    }
  } finally {
    yield put(accountUpdateRoutine.fulfill());
  }
}

export function* acceptInvitationValidationSaga() {
  yield put(acceptInvitationValidationRoutine.request());

  let errors = [];

  const { code } = yield select(getAcceptInvitationAttributes);

  if (code.length === 0) {
    errors.push({ code: "Code is required." });
  }

  let codeInfo = null;
  try {
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const requestOptions = !!accessToken ? {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    } : {};

    const response = yield call(
      () => axios.get(
        `${config.AUTH_GATEWAY}/auth/v4/invitation-code/${code}`,
        requestOptions,
      )
    );

    codeInfo = response.data;
    yield put(account.invitation.setCodeInfo({ codeInfo }));
  } catch (error) {
    errors.push({ code: "Invalid code" });
  }

  if (errors.length === 0) {
    yield put(acceptInvitationValidationRoutine.success());
    if (codeInfo.teams.length === 1 && codeInfo.invitation.roles.length == 1) {
      // is a team invitation, so we need to figure out what to do with it
      if (Object.values(codeInfo.userAlreadyHasTeamRoles).length === 1 && Object.values(codeInfo.userAlreadyHasTeamRoles).filter(v => !v).length === 0) {
        // user already has the team role
        // do nothing, return to start
        yield put(push("/"));
      } else {
        // user needs to verify that they are accepting an invitation to the correct team
        yield put(loadUserRoleTeamsRoutine());
        yield put(account.invitation.showScreen(invitationScreens.VERIFY_TEAM_INVITATION));
      }
    } else {
      // accept invitation code as normal, no more verification steps required
      yield put(acceptInvitationRoutine());
    }
  } else {
    yield put(acceptInvitationValidationRoutine.failure({ errors }));
  }

  yield put(acceptInvitationValidationRoutine.fulfill());
}

export function* loadUserTeamsSaga() {
  yield put(loadUserRoleTeamsRoutine.request());

  const userTeamAttributes = yield select(getUserTeamAttributes);
  if (userTeamAttributes.isLoaded) {
    yield put(loadUserRoleTeamsRoutine.fulfill());
    return;
  }

  const user = yield select(getAccountUser);
  if (!user || !user.roles) {
    yield put(loadUserRoleTeamsRoutine.success());
    yield put(loadUserRoleTeamsRoutine.fulfill());
    return;
  }

  const teamIds = user.roles.filter(role => role.level.type === "teams").map(role => role.level.id);
  if (teamIds.length === 0) {
    yield put(loadUserRoleTeamsRoutine.success());
    yield put(loadUserRoleTeamsRoutine.fulfill());
    return;
  }

  try {
    // call the data-gateway to get the user's teams
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const filter = `filter[teams]=${teamIds.join(",")}`;
    const result = yield call(
      () => axios.get(
        `${config.BFF_API}/teams/v4?${filter}`,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    const data = result.data;
    if (!data || data.status != "success") {
      throw new Error("Invalid response from data-gateway");
    }

    yield put(loadUserRoleTeamsRoutine.success({ teams: data.data }));
  } catch (error) {
    yield put(loadUserRoleTeamsRoutine.failure());
  }

  yield put(loadUserRoleTeamsRoutine.fulfill());
}

export function* acceptInvitationSaga() {
  yield put(acceptInvitationRoutine.request());

  try {
    const attributes = yield select(getAcceptInvitationAttributes);
    const codeInfo = yield select(getCodeInfo);
    const accessToken = yield call(ensureTokenIsFreshSaga);

    const { data } = yield call(gamesheetAPIRequest, acceptInvitation, {
      attributes
    });

    // send to BFF, it will refresh user roles and optionally merge prototeams 
    yield call(
      () => axios.post(
        `${config.BFF_API}/accept-invite`,
        {
          attributes,
          codeInfo,
        },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    yield put(acceptInvitationRoutine.success(data));
    yield put(tokenRefreshRoutine({ skipRolesChangeTest: true }));
    yield put(push("/"));
  } catch ({ response }) {
    switch (response.status) {
      case 400:
        yield put(acceptInvitationRoutine.failure({ errors: response.data.errors }));
        break;
      default:
        yield put(acceptInvitationRoutine.failure({ error: response.statusText }));
    }
  } finally {
    yield put(acceptInvitationRoutine.fulfill());
  }
}

export function* loadMergedTeamPreviewSaga() {
  yield put(loadMergedTeamPreviewRoutine.request());

  try {
    const accessToken = yield call(ensureTokenIsFreshSaga);
    const previewInfo = yield select(getPreviewRequestInfo);

    const response = yield call(
      () => axios.post(
        `${config.BFF_API}/prototeams`,
        {
          event: "preview-shuffle-proto-team",
          attributes: {
            schema: "preview-shuffle-team-event"
          },
          data: {
            id: previewInfo.prototeamId,
            seasonTeam: previewInfo.seasonTeam.id,
          }
        },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
        }
      )
    );

    const data = response.data.data["preview-invitation"];
    const combinedWithExistingData = {
      ...previewInfo.seasonTeam,
      ...data,
    };

    yield put(loadMergedTeamPreviewRoutine.success({ mergedTeam: combinedWithExistingData }));
  } catch (e) {
    console.log("Load Merge Preview Error", e);
    yield put(loadMergedTeamPreviewRoutine.failure());
  }

  yield put(loadMergedTeamPreviewRoutine.fulfill());
}

export function* accountFlow() {
  yield all([
    takeLatest([loadAccountRoutine.TRIGGER, acceptInvitationRoutine.SUCCESS], loadAccountSaga),
    takeLatest(accountValidationRoutine.TRIGGER, accountValidationSaga),
    takeLatest(newPasswordValidationRoutine.TRIGGER, newPasswordValidationSaga),
    takeLatest(accountUpdateRoutine.TRIGGER, accountUpdateSaga),
    takeLatest(acceptInvitationValidationRoutine.TRIGGER, acceptInvitationValidationSaga),
    takeLatest(loadUserRoleTeamsRoutine.TRIGGER, loadUserTeamsSaga),
    takeLatest(acceptInvitationRoutine.TRIGGER, acceptInvitationSaga),
    takeLatest(tokenSetRoutine.FULFILL, ensureAccountLoadedSaga),
    takeLatest(loadMergedTeamPreviewRoutine.TRIGGER, loadMergedTeamPreviewSaga),
  ]);
}
