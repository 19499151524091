import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import {
  getMeta,
  actions,
  submittingRoutine,
} from "@/redux/cloneSeasonForm";

export const defaultState = {
  originalSeason: {
    id: ""
  },
  newSeason: {
    title: ""
  },
  options: {
    ipadKeys: true,
    divisions: true,
    teams: true,
    teamRosters: false,
    seasonRoster: false,
    referees: true,
    prototeams: false
  }
}

// assumes season is loaded
export function useCloneSeasonForm() {
  // state
  const {
    season,
    seasonLeagueId
  } = useCurrentSeasonContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultState);
  const { isSubmitting } = useSelector(getMeta);
  // -- end state

  // selectors
  const newSeasonTitle = `${season.title} Copy`;
  const cloneIpadKeys = !!values.options.ipadKeys;
  const cloneDivisions = !!values.options.divisions
  const cloneTeams = !!values.options.teams;
  const cloneTeamRosters = !!values.options.teamRosters;
  const cloneSeasonRoster = !!values.options.seasonRoster;
  const cloneReferees = !!values.options.referees;
  const clonePrototeams = !!values.options.prototeams;
  // -- end selectors

  // reducers
  const setOptionValue = (key, val) => {
    setValues(old => ({
      ...old,
      options: {
        ...old.options,
        [key]: val
      }
    }));
  }

  const setIpadKeysValue = (val) => {
    setOptionValue('ipadKeys', val)
  }

  const setDivisionsValue = (val) => {
    setOptionValue('divisions', val)
    if (val === false){
      setOptionValue("teams", false)
      setOptionValue("teamRosters", false)
      setOptionValue("prototeams", false)
    } 
  }

  const setTeamsValue = (val) => {
    setOptionValue('teams', val)
    if (val === true){
      setOptionValue("divisions", true)
    } else {
      setOptionValue("teamRosters", false)
      setOptionValue("prototeams", false)
    }
  }

  const setTeamRostersValue = (val) => {
    setOptionValue('teamRosters', val)
    if (val === true){
      setOptionValue("divisions", true)
      setOptionValue("teams", true)
    }
  }

  const setSeasonRosterValue = (val) => {
    setOptionValue('seasonRoster', val)
  }

  const setRefereesValue = (val) => {
    setOptionValue('referees', val) 
  }

  const setPrototeamsValue = (val) => {
    console.log(values.options)
    if (values.options.teams === true){
      setOptionValue('prototeams', val) 
    }
  }

  // -- end reducers

  // useEffects
  useEffect(() => {
    setValues(old => {
      return {
        ...old,
        newSeason: {
          ...old.newSeason,
          title: newSeasonTitle,
        },
      }
    });
  }, [setValues, newSeasonTitle]);

  useEffect(() => {
    setValues(old => {
      return {
        ...old,
        originalSeason: {
          ...old.originalSeason,
          ...season,
        },
      }
    });
  }, [setValues, season])
  // -- end useEffects

  // callbacks
  const submit = useCallback(() => {
    dispatch(submittingRoutine({ data: formatPayload(values), leagueId: seasonLeagueId }));
  }, [
    dispatch,
    submittingRoutine,
    seasonLeagueId,
    values
  ]);

  const cancel = useCallback(() => {
    setValues(defaultState);
    dispatch(actions.clear());
    history.push(`/leagues/${seasonLeagueId}/seasons`);
  }, [
    dispatch,
    actions,
    history,
    seasonLeagueId,
  ])
  // -- end callbacks

  return {
    season,
    seasonLeagueId,
    isSubmitting,
    newSeasonTitle,
    cloneIpadKeys,
    cloneDivisions,
    cloneTeams,
    cloneTeamRosters,
    cloneSeasonRoster,
    cloneReferees,
    clonePrototeams,
    setIpadKeysValue,
    setDivisionsValue,
    setTeamsValue,
    setTeamRostersValue,
    setSeasonRosterValue,
    setRefereesValue,
    setPrototeamsValue,
    submit,
    cancel
  };
}

const formatPayload = (values) => {
  return {
    old: {
      id: Number(values.originalSeason.id)
    },
    new: {
      title: values.newSeason.title
    },
    options: values.options
  }
}
