import { useCallback } from "react";

export default function useGameGeneralSettingsFields({ value, onChange }) {
  const { periodLengths, gameCategories, shootout, signatures_required, default_player_status } = value;

  const changePeriodLengths = useCallback(periodLengths => onChange({ ...value, periodLengths }), [onChange, value]);
  const changeGameCategories = useCallback(gameCategories => onChange({ ...value, gameCategories }), [onChange, value]);
  const changeShootout = useCallback(shootout => onChange({ ...value, shootout }), [onChange, value]);

  // GAME-613
  const changeSignaturesRequired = useCallback(
    ({ target: { value: signatures_required } }) => onChange({ ...value, signatures_required }),
    [onChange, value]
  );

  // GAME-612
  const changeDefaultPlayerStatus = useCallback(
    ({ target: { value: default_player_status } }) => onChange({ ...value, default_player_status }),
    [onChange, value]
  );

  return {
    periodLengths,
    changePeriodLengths,
    gameCategories,
    changeGameCategories,
    shootout,
    changeShootout,
    signatures_required,
    changeSignaturesRequired,
    default_player_status,
    changeDefaultPlayerStatus
  };
}
