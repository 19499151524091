import _capitalize from "lodash/capitalize";
import _isEqual from "lodash/isEqual";

const selectRoot = state => state.roleForm;
const selectAssociationList = state => selectRoot(state).associationList;
const selectLeagueList = state => selectRoot(state).leagueList;
const selectSeasonList = state => selectRoot(state).seasonList;
const selectDivisionList = state => selectRoot(state).divisionList;
const selectTeamList = state => selectRoot(state).teamList;

// Global User
export const getRoleIsGlobal = state => selectRoot(state).roleIsGlobal;

// Associations
export const getAssociationId = state => selectRoot(state).associationId;

export const getAssociationList = state => {
  const { ids, data } = selectAssociationList(state);
  const selectedId = getAssociationId(state);

  return ids.map(id => ({
    ...data[id],
    id,
    isSelected: selectedId === id
  })).filter(x => ('title' in x));
};

export const getAssociationListIsLoaded = state => selectAssociationList(state).isLoaded;

export const getAssociationListIsLoading = state => selectAssociationList(state).isLoading;

// Leagues
export const getLeagueId = state => selectRoot(state).leagueId;

export const getLeagueList = (state, associationId) => {
  const { ids, data } = selectLeagueList(state)[associationId] || {
    ids: [],
    data: {}
  };

  const selectedId = getLeagueId(state);

  return ids.map(id => ({
    ...data[id],
    id,
    associationId,
    isSelected: selectedId === id
  })).filter(x => ('title' in x));
};

export const getLeagueListIsLoaded = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoaded;

export const getLeagueListIsLoading = (state, associationId) =>
  !!(selectLeagueList(state)[associationId] || {}).isLoading;

// Seasons
export const getSeasonId = state => selectRoot(state).seasonId;

export const getSeasonList = (state, leagueId) => {
  const { ids, data } = selectSeasonList(state)[leagueId] || {
    ids: [],
    data: {}
  };

  const selectedId = getSeasonId(state);

  return ids.map(id => ({
    ...data[id],
    id,
    leagueId,
    isSelected: selectedId === id
  })).filter(x => ('title' in x));
};

export const getSeasonListIsLoaded = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoaded;

export const getSeasonListIsLoading = (state, leagueId) => !!(selectSeasonList(state)[leagueId] || {}).isLoading;

// Divisions
export const getDivisionId = state => selectRoot(state).divisionId;

export const getDivisionList = (state, seasonId) => {
  const { ids, data } = selectDivisionList(state)[seasonId] || {
    ids: [],
    data: {}
  };

  const selectedId = getDivisionId(state);

  return ids.map(id => ({
    ...data[id],
    id,
    seasonId,
    isSelected: selectedId === id
  })).filter(x => ('title' in x));
};

export const getDivisionListIsLoaded = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoaded;

export const getDivisionListIsLoading = (state, seasonId) => !!(selectDivisionList(state)[seasonId] || {}).isLoading;

// Teams
export const getTeamId = state => selectRoot(state).teamId;

export const getTeamList = (state, divisionId) => {
  const { ids, data } = selectTeamList(state)[divisionId] || {
    ids: [],
    data: {}
  };

  const selectedId = getTeamId(state);

  return ids.map(id => ({
    ...data[id],
    id,
    divisionId,
    isSelected: selectedId === id
  })).filter(x => ('title' in x));
};

export const getTeamListIsLoaded = (state, divisionId) => !!(selectTeamList(state)[divisionId] || {}).isLoaded;

export const getTeamListIsLoading = (state, divisionId) => !!(selectTeamList(state)[divisionId] || {}).isLoading;

// Title

export const getTitle = state => selectRoot(state).title;

// Misc

export const getLevel = state => {
  const isGlobal = getRoleIsGlobal(state);
  
  if (isGlobal) {
    return {
      id: "*",
      type: ""
    }
  } else {
    return selectRoot(state).level;
  }
}

export const getInitRole = state => selectRoot(state).initRole;

export const getRole = state => {
  const title = getTitle(state);
  const level = getLevel(state);
  const isGlobal = getRoleIsGlobal(state);

  return { title, level, isGlobal };
};

export const getIsValid = state => {
  const { title, level } = getRole(state);

  return title !== "" && level !== null;
};

export const getIsInvalid = state => !getIsValid(state);

export const getFullRoleName = (state, action) => {
  const isValid = getIsValid(state);

  if (!isValid) {
    return "";
  }

  const { title, level, isGlobal } = getRole(state);

  if (isGlobal) {
    return `Global ${_capitalize(title)}`;
  } else {
    return `${_capitalize(level.type.replace(/s$/, ""))} ${_capitalize(title)}`;
  }
};

export const getIsVisible = state => selectRoot(state).isVisible;

export const getIsInitializing = state => selectRoot(state).isInitializing;

export const getAssociationTitle = (state, id) => (selectAssociationList(state).data[id] || {}).title || "";

export const getLeagueTitle = (state, id) => {
  const leagues = Object.values(selectLeagueList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (leagues[id] || {}).title || "";
};

export const getSeasonTitle = (state, id) => {
  const seasons = Object.values(selectSeasonList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (seasons[id] || {}).title || "";
};

export const getDivisionTitle = (state, id) => {
  const divisions = Object.values(selectDivisionList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (divisions[id] || {}).title || "";
};

export const getTeamTitle = (state, id) => {
  const teams = Object.values(selectTeamList(state)).reduce((result, { data }) => ({ ...result, ...data }), {});

  return (teams[id] || {}).title || "";
};

export const getResourceTitle = state => {
  const { level, isGlobal } = getRole(state);

  if (!level || isGlobal) {
    return null;
  }

  const { type, id } = level;

  switch (type) {
    case "associations":
      return getAssociationTitle(state, id);
    case "leagues":
      return getLeagueTitle(state, id);
    case "seasons":
      return getSeasonTitle(state, id);
    case "divisions":
      return getDivisionTitle(state, id);
    case "teams":
      return getTeamTitle(state, id);
    default:
      throw new Error(`Unable to obtain title for unknown resource type ${type}`);
  }
};

export const getIsPristine = state => _isEqual(getRole(state), getInitRole(state));
