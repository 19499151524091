import React from "react";

import ResourceSelect from "./ResourceSelect";

import useTeamSelect from "./hooks/useTeamSelect";

function TeamSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected } = useTeamSelect();

  return (
    <ResourceSelect
      label="Team"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
    />
  );
}

export default TeamSelect;
