import React from "react";
import PropTypes from "prop-types";

import { CustomInput } from "reactstrap";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

function LiveScoringModeField({ mode, label, description, checked, onChange }) {
  const namespace = useDashboardFormNamespaceContext();

  const id = `${namespace}__live-scoring__option-${mode}`;

  return (
    <CustomInput
      className="live-scoring__option"
      type="radio"
      id={id}
      name={mode}
      label={label}
      onChange={onChange}
      checked={checked}
    >
      <span className="live-scoring__option__description">{description}</span>
    </CustomInput>
  );
}

LiveScoringModeField.propTypes = {
  mode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LiveScoringModeField;
