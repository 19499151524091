import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Goal from "./components/Goal";

class ScoringSheet extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    goals: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    caption: "Scoring",
    goals: []
  };

  render() {
    const { caption, goals } = this.props;

    return (
      <Table striped borderless size="sm" className="layout-fixed">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="6">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">Per.</th>
            <th scope="col">Time</th>
            <th scope="col">G</th>
            <th scope="col">A</th>
            <th scope="col">A</th>
            <th scope="col">Goalie</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td colSpan="6">Total: {goals.length}</td>
          </tr>
        </tfoot>
        <tbody>
          {goals.map(goal => (
            <Goal key={`${goal.period}-${goal.time}`} {...goal} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default ScoringSheet;
