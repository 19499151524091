import React from "react";

import { Table } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";
import PageLoader from "@/components/PageLoader";
import TotalCount from "@/components/TotalCount";

import Game from "./Game";

import useCompletedGamesList from "./hooks/useCompletedGamesList";
import useCompletedGamesListPagination from "./hooks/useCompletedGamesListPagination";

import "./List.scss";

function List() {
  const games = useCompletedGamesList();

  const { isListLoaded, isListLoading, totalCount, filteredCount, isFilterDirty } = useCompletedGamesListPagination();

  if (isListLoaded && !isListLoading && games.length === 0) {
    return (
      <EmptyListMessage>
        {isFilterDirty
          ? "We couldn't find any games matching your criteria. Try adjusting your search terms or reducing your filter selections."
          : "No games have been played so far."}
      </EmptyListMessage>
    );
  }

  return (
    <PageLoader isLoaded={isListLoaded} isLoading={isListLoading}>
      <div className="games-page__list">
        <TotalCount plural="games" singular="game" totalCount={totalCount} filteredCount={filteredCount} />
        <Table striped borderless>
          <thead>
            <tr>
              <th>Visiting team</th>
              <th className="score">&nbsp;</th>
              <th>Home team</th>
              <th>Divisions</th>
              <th>Date</th>
              <th>Number</th>
              <th>Location</th>
              <th>Notes</th>
              <th>Viewed</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {games.map(game => (
              <Game key={game.id} {...game} />
            ))}
          </tbody>
        </Table>
      </div>
    </PageLoader>
  );
}

export default List;
