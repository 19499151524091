import { put, all, takeLatest, actionChannel, take, race, select, call } from "redux-saga/effects";

import { buffers } from "redux-saga";
import build from "redux-object";

import updateTeamRoster from "@/lib/core/updateTeamRoster";
import deleteMemberFromTeamRoster from "@/lib/core/deleteMemberFromTeamRoster";

import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { getTeam, getMemberField } from "../selectors";

import rosterUpdatingSaga from "./updatingSaga";

export default function createMembersFlow(membersType, options) {
  const { validators, validate, routines, actions, selectors, services } = options;

  const fieldValidatingSaga = function* ({ payload }) {
    const { name, value, memberId } = payload;

    yield put(routines.fieldValidating.request({ name, value, memberId }));

    const validator = validators[name];

    if (!validator) {
      throw new Error(`Unknown form field ${name}`);
    }

    const errors = validator(value);

    if (errors.length === 0) {
      yield put(routines.fieldValidating.success({ name, value, memberId }));
    } else {
      yield put(routines.fieldValidating.failure({ name, value, errors, memberId }));
    }

    yield put(routines.fieldValidating.fulfill({ name, value }));
  };

  const creatingSaga = function* ({ attributes, seasonId }) {
    const { data } = yield call(gamesheetAPIRequest, services.createMember, {
      seasonId,
      attributes
    });

    return build(data, membersType)[0];
  };

  const updatingSaga = function* ({ attributes, id, seasonId }) {
    const { data } = yield call(gamesheetAPIRequest, services.updateMember, {
      seasonId,
      attributes,
      identity: { id }
    });

    return build(data, membersType, id);
  };

  const savingSaga = function* ({ payload: { id, seasonId, attributes } }) {
    yield put(routines.saving.request({ id }));

    try {
      const member = yield call(id === "" ? creatingSaga : updatingSaga, {
        seasonId,
        id,
        attributes
      });

      yield put(routines.saving.success({ id, member }));
    } catch (error) {
      const response = error.response;

      yield put(routines.saving.failure({ id, response }));
    } finally {
      yield put(routines.saving.fulfill({ id }));
    }
  };

  const validatingSaga = function* ({ payload: { memberId } }) {
    const attributes = yield select(selectors.getAllMemberAttributes, memberId);

    yield put(routines.validating.request({ memberId }));

    const errors = validate(attributes);

    if (errors) {
      yield put(routines.validating.failure({ errors, memberId }));
    } else {
      yield put(routines.validating.success({ memberId }));
    }

    yield put(routines.validating.fulfill({ memberId }));
  };

  const submittingSaga = function* ({ payload: { memberId } }) {
    yield put(routines.submitting.request({ memberId }));

    const {
      season: { id: seasonId },
      roster: currentRoster
    } = yield select(getTeam);

    try {
      const validationSuccessChannel = yield actionChannel(routines.validating.SUCCESS, buffers.dropping(1));

      const validationFulfillChannel = yield actionChannel(routines.validating.FULFILL, buffers.dropping(1));

      yield put(routines.validating({ memberId }));

      const { success: validatingSuccess } = yield race({
        success: take(validationSuccessChannel),
        fulfill: take(validationFulfillChannel)
      });

      if (!validatingSuccess) {
        yield put(routines.submitting.failure({ memberId }));
        yield put(routines.submitting.fullfil({ memberId }));

        return;
      }

      const savingSuccessChannel = yield actionChannel(routines.saving.SUCCESS, buffers.dropping(1));

      const savingFulfillChannel = yield actionChannel(routines.saving.FULFILL, buffers.dropping(1));

      const seasonMemberAttributes = yield select(selectors.getSeasonMemberAttributes, memberId);

      // only update member if their name changes
      const { isDirty: firstNameDirty } = yield select(getMemberField, "firstName", { type: membersType, id: memberId });
      const { isDirty: lastNameDirty } = yield select(getMemberField, "lastName", { type: membersType, id: memberId });
      if (firstNameDirty || lastNameDirty) {
        yield put(
          routines.saving({
            id: memberId,
            seasonId,
            attributes: seasonMemberAttributes
          })
        );
      } else {
        // fake a success
        yield put(routines.saving.success({ member: { id: memberId, externalId: seasonMemberAttributes.externalId }}));
      }

      const { success: savingSuccess } = yield race({
        success: take(savingSuccessChannel),
        fulfill: take(savingFulfillChannel)
      });

      if (!savingSuccess) {
        yield put(routines.submitting.failure({ memberId }));
        yield put(routines.submitting.fulfill({ memberId }));

        return;
      }

      const { id, externalId } = savingSuccess.payload.member;

      const rosterMemberAttributes = yield select(selectors.getRosterMemberAttributes, memberId);

      const rosterMember = { id, ...rosterMemberAttributes };

      const nextRoster = updateTeamRoster(currentRoster, {
        [membersType]: [rosterMember]
      });

      yield rosterUpdatingSaga({
        roster: nextRoster
      });

      yield put(
        routines.submitting.success({
          memberId,
          member: {
            ...seasonMemberAttributes,
            ...rosterMemberAttributes,
            id,
            externalId
          }
        })
      );
    } catch (error) {
      yield put(routines.submitting.failure({ memberId, error }));
    } finally {
      yield put(routines.submitting.fulfill({ memberId }));
    }
  };

  const removingSaga = function* ({ payload: { memberId } }) {
    yield put(routines.removing.request({ memberId }));

    try {
      const team = yield select(getTeam);
      const currentRoster = team.roster;
      const nextRoster = deleteMemberFromTeamRoster(currentRoster, {
        type: membersType,
        id: memberId
      });

      yield rosterUpdatingSaga({
        roster: nextRoster
      });

      yield put(routines.removing.success({ memberId }));
    } catch (error) {
      yield put(routines.removing.failure({ memberId, error }));
    } finally {
      yield put(routines.removing.fulfill({ memberId }));
    }
  };

  return function* () {
    yield all([
      takeLatest(actions.changeField, fieldValidatingSaga),
      takeLatest(routines.validating.TRIGGER, validatingSaga),
      takeLatest(routines.submitting.TRIGGER, submittingSaga),
      takeLatest(routines.saving.TRIGGER, savingSaga),
      takeLatest(routines.removing.TRIGGER, removingSaga)
    ]);
  };
}
