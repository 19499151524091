import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import CoachPenaltyReportCoachesListMember from "./Member";

class CoachesListTable extends PureComponent {
  static propTypes = {
    coaches: PropTypes.arrayOf(
      PropTypes.shape({
        externalId: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    coaches: []
  };

  render() {
    const { coaches } = this.props;

    return (
      <Table striped borderless>
        <thead>
          <tr>
            <th scope="col">Bench Staff</th>
            <th scope="col">External ID</th>
            <th scope="col">League</th>
          </tr>
        </thead>
        <tbody>
          {coaches.map(({ externalId, ...rest }) => (
            <CoachPenaltyReportCoachesListMember key={externalId} externalId={externalId} {...rest} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default CoachesListTable;
