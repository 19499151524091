import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAssociationId,
  getLeagueList,
  getLeagueListIsLoaded,
  getLeagueListIsLoading,
  getRoleIsGlobal,
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useLeagueSelect() {
  const associationId = useSelector(getAssociationId);
  const options = useSelector(state => getLeagueList(state, associationId));
  const roleIsGlobal = useSelector(getRoleIsGlobal);

  const isLoaded = useSelector(state => getLeagueListIsLoaded(state, associationId));

  const isLoading = useSelector(state => getLeagueListIsLoading(state, associationId));

  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ isSelected, id, ...rest }) => {
      const action = isSelected ? "deselectLeague" : "selectLeague";
      const fn = actions[action];

      dispatch(fn({ id, ...rest }));
    },
    [dispatch]
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected
  };
}
