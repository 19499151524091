import moment from "moment";
import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";

const initialState = {
  home: {
    team: "",
    division: ""
  },
  visitor: {
    team: "",
    division: ""
  },
  startTime: "",
  scheduledStartTime: "",
  number: "",
  gameType: "",
  location: "",
  scorekeeper: {
    name: "",
    phone: ""
  },
  broadcaster: ""
};

function reduceLoadingSuccess(state, { payload: { game } }) {
  const { visitorDivision, visitorTeam, homeDivision, homeTeam, gameType, scorekeeper } = game;
  const scheduledStartTime = game.scheduledStartTime && moment(game.scheduledStartTime).utc();
  return {
    gameType,
    scorekeeper,
    visitor: {
      division: (visitorDivision && visitorDivision.id) || "",
      team: (visitorTeam && visitorTeam.id) || ""
    },
    home: {
      division: (homeDivision && homeDivision.id) || "",
      team: (homeTeam && homeTeam.id) || ""
    },
    number: game.number,
    location: game.location,
    scheduledStartTime: scheduledStartTime ? scheduledStartTime.format("YYYY-MM-DD") : "",
    startTime: scheduledStartTime ? scheduledStartTime.format("HH:mm") : "",
    broadcaster: game.data.broadcaster
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
