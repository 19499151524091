import { connect } from "react-redux";

import selectors from "@/redux/playerPenaltyReport/selectors";

import Report from "@/components/TeamMemberPenaltyReport";

const {
  getPlayerPenaltyReportMember,
  getPlayerPenaltyReportCareerStats,
  getPlayerPenaltyReportMajorPenalties,
  getPlayerPenaltyReportInfractionHistory,
  getPlayerPenaltyReportStatsBySeason,
  getPlayerPenaltyReportIsLoading,
  getPlayerPenaltyReportIsLoaded
} = selectors;

const mapStateToProps = state => ({
  member: getPlayerPenaltyReportMember(state),
  memberTitle: "Player",
  careerStats: getPlayerPenaltyReportCareerStats(state),
  statsBySeason: getPlayerPenaltyReportStatsBySeason(state),
  majorPenalties: getPlayerPenaltyReportMajorPenalties(state),
  infractionHistory: getPlayerPenaltyReportInfractionHistory(state),
  isLoading: getPlayerPenaltyReportIsLoading(state),
  isLoaded: getPlayerPenaltyReportIsLoaded(state)
});

export default connect(mapStateToProps)(Report);
