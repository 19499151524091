// react
import { useEffect, useState, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

// redux
import { useDispatch } from "react-redux";

// type imports
import Service from '@/services';
import { CSVRosterImportStatus as ImportStatus } from "@/components-16.8/RosterImportWizard/types/importStatus";

// function imports
import readCsvFile from "../parser/read";
import validateCsvRecords from "../parser/validate";
import saveAs from "file-saver";

// errors
import ReadingError from "../parser/ReadingError";

// component state
import {
  lastMountState,
  seasonIdState,
  teamIdState,
  seasonState,
  playerImportResponsesState,
  coachesImportResponsesState,
  teamsUpdateResponsesState,
  componentStatusState,
  invalidRecordsState,
  recordsState,
  teamIdsState,
  playerImportProgressState,
  playerImportsState,
  playerRecordsState,
  coachImportProgressState,
  coachImportsState,
  coachRecordsState,
  validTeamIdsState,
  teamUpdatesAfterImportState,
  teamUpdateProgressState,
  seasonTeamsState,
  rosterLockedTeamIdsState
} from "@/components-16.8/RosterImportWizard/state";

const SEASON_ROSTER_IMPORT_CSV_TEMPLATE = [
  "TYPE *,FIRST NAME *,LAST NAME *,EXTERNAL ID,TEAM ID,JERSEY NUMBER,POSITION,DESIGNATION,AFFILIATED,BIRTHDATE,WEIGHT,HEIGHT,SHOT HAND,BIRTH COUNTRY,BIRTH STATE,BIRTH CITY,DRAFTED BY,COMMITTED TO,BIO\n",
  ",,,,,,,,,,,,,,,,,,"
];

const TEAM_ROSTER_IMPORT_CSV_TEMPLATE = [
  "TYPE *,FIRST NAME *,LAST NAME *,EXTERNAL ID,JERSEY NUMBER,POSITION,DESIGNATION,AFFILIATED,BIRTHDATE,WEIGHT,HEIGHT,SHOT HAND,BIRTH COUNTRY,BIRTH STATE,BIRTH CITY,DRAFTED BY,COMMITTED TO,BIO\n",
  ",,,,,,,,,,,,,,,,,"
];

// for updating external state
import { CurrentTeamLoadingRoutine } from "@/redux/teams/routines";

/**
 * useRosterImport Hook
 *
 * This hook manages the state of the RosterImportWizard.
 *
 */
export function useRosterImport( inSeasonId, inTeamId ) {

  // for talking to redux
  const dispatch = useDispatch();

  // -------------------------------------------------------------------------------------------------------------------| shared state setters and getters

  const setLastMount = useSetRecoilState(lastMountState);
  const [ seasonId, setSeasonId ] = useRecoilState(seasonIdState);
  const setPlayerImportResponses = useSetRecoilState(playerImportResponsesState);
  const setCoachImportResponses = useSetRecoilState(coachesImportResponsesState);
  const setTeamUpdateResponses = useSetRecoilState(teamsUpdateResponsesState);
  const [ teamId, setTeamId ] = useRecoilState(teamIdState);
  const [ records, setRecords ] = useRecoilState(recordsState);
  const [ status, setStatus ] = useRecoilState(componentStatusState);
  const [ playerImportProgress, setPlayerImportProgress ] = useRecoilState(playerImportProgressState);
  const [ coachImportProgress, setCoachImportProgress ] = useRecoilState(coachImportProgressState);
  const [ teamUpdateProgress, setTeamUpdateProgress ] = useRecoilState(teamUpdateProgressState);

  // -------------------------------------------------------------------------------------------------------------------| selectors (filtered state values)

  const validTeamIds = useRecoilValue(validTeamIdsState);
  const lockedTeamIds = useRecoilValue(rosterLockedTeamIdsState);
  const season = useRecoilValue(seasonState);
  const invalidRecords = useRecoilValue(invalidRecordsState);
  const players = useRecoilValue(playerRecordsState);
  const coaches = useRecoilValue(coachRecordsState);
  const teamIds = useRecoilValue(teamIdsState);
  const playerImports = useRecoilValue(playerImportsState);
  const coachImports = useRecoilValue(coachImportsState);
  const teamUpdatesAfterImport = useRecoilValue(teamUpdatesAfterImportState);
  const teams = useRecoilValue(seasonTeamsState);

  // -------------------------------------------------------------------------------------------------------------------| local state values

  const [ error, setError ] = useState(null);
  const [ isPlayersImported, setIsPlayersImported ] = useState(false);
  const [ isCoachesImported, setIsCoachesImported ] = useState(false);
  const [ isSeasonImport, isTeamImport ] = useMemo(() => [ !teamId, !!teamId ], [ teamId ]);
  const team = useMemo(() => teams.find( t => t.id === teamId), [ teamId, teams.length ]);

  // -------------------------------------------------------------------------------------------------------------------| triggers

  useEffect(() => {
    !!inSeasonId && setLastMount(Date.now());
  }, [ inSeasonId ])

  // TRIGGER: run evrytime inSeasonId or inTeamId changes
  // EFFECT: sets the seasonId and teamId if they're provided, ignores otherwise
  useEffect(() => {
    !!inSeasonId && seasonId !== inSeasonId && setSeasonId( inSeasonId );
    !!inTeamId && teamId !== inTeamId && setTeamId( inTeamId );
  }, [ inSeasonId, inTeamId ]);

  // TRIGGER: anytime the records have changed which only happens when a new file is being parsed.
  // EFFECT: Once that happens, check to see if any of them are invalid
  useEffect(() => {
    status === ImportStatus.PARSING && setStatus( invalidRecords.length ? ImportStatus.VALIDATION_FAILED : ImportStatus.PARSED);
  }, [ status, JSON.stringify(records), JSON.stringify(invalidRecords) ]);

  // TRIGGER: anytime the error changes, check to see what kind of error it is and if it's a ReadingError
  // EFFECT: Set status to PARSING_FAILED or leave status alone
  useEffect(() => {
    error instanceof ReadingError && setStatus(ImportStatus.PARSING_FAILED);
    if (error) console.log( error );
  }, [ error && error.message ]);

  // 
  useEffect(() => {
    if( isPlayersImported && isCoachesImported ){
      runImportOn( teamUpdatesAfterImport, setTeamUpdateProgress, () => {}, (queue) => {
        setTeamUpdateResponses(queue.resolved);
        setStatus(ImportStatus.COMPLETE);
      });
    }
  }, [ isPlayersImported, isCoachesImported ]);

  // TRIGGER: import is complete
  // EFFECT: load updated team roster into Redux when this component unmounts
  useEffect(() => {
    if (status === ImportStatus.COMPLETE) {
      return () => dispatch(CurrentTeamLoadingRoutine({ seasonId, id: teamId }));
    }
  }, [ status, seasonId, teamId ]);

  // -------------------------------------------------------------------------------------------------------------------| functions

  async function runImportOn( imports, onQueueProgress, onResolve, onDone=()=>{} ){
    return new Promise(( resolve, reject ) => {
      Service.asyncCommandQueue({
        MAX_CONCURRENT: 6,
        requests: [...imports],
        onResolve,
        onProgress: (queue) => {
          onQueueProgress({
            pending: queue.pending.length,
            sent: queue.sent.length,
            resolved: queue.resolved.length,
            rejected: queue.rejected.length
          });
        },
        onDone: ( queue ) => {
          onDone(queue);
          resolve(queue);
        }
      });
    })
  }

  // -------------------------------------------------------------------------------------------------------------------| handlers

  async function handleStartImport() {

    setStatus(ImportStatus.IMPORTING);
    
    // import players
    runImportOn( playerImports, setPlayerImportProgress, ()=>{}, (queue) => {
      setPlayerImportResponses(queue.resolved);
      setIsPlayersImported(true);
    });

    // import coaches
    runImportOn( coachImports, setCoachImportProgress, () => {}, (queue) => {
      setCoachImportResponses(queue.resolved)
      setIsCoachesImported(true);
    });

  }

  function handleResetImport() {
    setRecords([]);
    setStatus(ImportStatus.PENDING);
  }

  async function handleFileSelection( file ) {

    // set the status to parsing, which will show the loading screen
    setStatus(ImportStatus.PARSING);
    setRecords([]);
    setError(null);

    try {
      // read the csv file and parse it
      // THROWS `ReadingError`
      const { records: parsedRecords } = await readCsvFile(file, { teamId });
      
      // records are PARSED without error, validate them
      const validatedRecords = await validateCsvRecords(parsedRecords, {
        validTeamIds: validTeamIds,
        lockedTeamIds: lockedTeamIds,
        skipTeamIdValidation: !!teamId
      });

      setRecords(validatedRecords);

    } catch (e){
      setError(e);
    }
  }

  function handleDownloadTemplate(){
    const ROSTER_IMPORT_CSV_TEMPLATE = isTeamImport ? TEAM_ROSTER_IMPORT_CSV_TEMPLATE : SEASON_ROSTER_IMPORT_CSV_TEMPLATE;
    const blob = new Blob(ROSTER_IMPORT_CSV_TEMPLATE, { type: "text/csv;charset=utf-8" });
    saveAs(blob, `gamesheet-${isTeamImport?'team':'season'}-roster-import-template.csv`);
  }

  // -------------------------------------------------------------------------------------------------------------------| expose

  return {

    isSeasonImport,
    isTeamImport,
    
    // state values
    records,
    status,
    error,
    invalidRecords,
    players,
    coaches,
    teamUpdatesAfterImport,
    teamIds,
    playerImportProgress,
    coachImportProgress,
    teamUpdateProgress,

    // data
    season,
    team,

    // handlers
    handleFileSelection,
    handleResetImport,
    handleStartImport,
    handleDownloadTemplate

  }

}
