import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import TeamPlayerForm from "@/components/TeamPlayerForm";

import ArchivedSeasonContent from "@/layouts/ArchivedSeasonContent";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";

class TeamRosterPlayersEdit extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        playerId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    teamId: PropTypes.string.isRequired,
    isTeamReady: PropTypes.bool.isRequired,
    isTeamOperating: PropTypes.bool.isRequired,
    isPlayerReady: PropTypes.bool.isRequired,
    isPlayerOperating: PropTypes.bool.isRequired,
    isPlayerForbidden: PropTypes.bool.isRequired,
    isPlayerArchived: PropTypes.bool.isRequired,
    team: PropTypes.shape({}),
    playersRoster: PropTypes.arrayOf(PropTypes.shape({})),
    player: PropTypes.shape({}),
    teamPlayer: PropTypes.shape({}),
    playerFullName: PropTypes.string,
    breadcrumbs: BreadcrumbsProps,
    loadTeam: PropTypes.func,
    loadPlayer: PropTypes.func,
    updateTeam: PropTypes.func,
    clearPlayer: PropTypes.func
  };

  static defaultProps = {
    team: null,
    player: null,
    playersRoster: [],
    teamPlayer: null,
    playerFullName: null,
    breadcrumbs: null,
    loadTeam() {},
    loadPlayer() {},
    updateTeam() {},
    clearPlayer() {}
  };

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  playerId = this.props.match.params.playerId;

  componentDidMount() {
    const { loadTeam, loadPlayer, seasonId, teamId } = this.props;

    loadPlayer({ id: this.playerId, seasonId });
    loadTeam(teamId);
  }

  componentWillUnmount() {
    this.props.clearPlayer();
  }

  goBack = () => {
    window.history.go(-1);
  };

  handleNextPlayersRoster = nextPlayersRoster => {
    const { team, updateTeam } = this.props;

    const attributes = {
      title: team.title,
      externalId: team.externalId,
      roster: { ...team.roster, players: nextPlayersRoster },
      data: team.data
    };

    updateTeam({
      seasonId: team.season.id,
      divisionId: team.division.id,
      attributes,
      id: team.id,
      logo: {
        url: team.logoUrl,
        file: null
      },
      tab: "players",
      redirect: () => {
        this.goBack();
      }
    });

    this.setState(state => ({ ...state, loading: true }));
  };

  render() {
    const {
      teamId,
      team,
      isTeamOperating,
      isTeamReady,
      isPlayerOperating,
      isPlayerReady,
      isPlayerForbidden,
      isPlayerArchived,
      breadcrumbs,
      playersRoster,
      teamPlayer,
      player
    } = this.props;

    const { loading } = this.state;

    return (
      <ArchivedSeasonContent condition={isPlayerArchived}>
        <ProtectedLayout
          subject={{ type: "teams", id: teamId }}
          action="update"
          skip={!isPlayerReady && !isPlayerForbidden}
        >
          {isPlayerReady && !loading ? (
            <Fragment>
              <Head title="Edit Player" />

              <Breadcrumbs items={breadcrumbs} />

              <TitleBar title="Edit Player" />

              {isTeamReady ? (
                <TeamPlayerForm
                  isOperating={isTeamOperating}
                  playersRoster={playersRoster}
                  playerId={player.id}
                  onSubmit={this.handleNextPlayersRoster}
                  onDelete={this.handleNextPlayersRoster}
                  rosterLocked={team.data.rosterLocked}
                  {...teamPlayer}
                />
              ) : (
                <PageLoader isLoading={isTeamOperating} />
              )}
            </Fragment>
          ) : (
            <PageLoader isLoading={isPlayerOperating || loading} />
          )}
        </ProtectedLayout>
      </ArchivedSeasonContent>
    );
  }
}

export default TeamRosterPlayersEdit;
