import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import TitleBar from "@/components/TitleBar";
import SeasonTeamsSelect from "@/components/SeasonTeamsSelect";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class PlayerTeam extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    isOperating: PropTypes.bool,
    values: PropTypes.shape({
      teamId: PropTypes.string,
      jersey: PropTypes.string,
      affiliated: PropTypes.bool,
      position: PropTypes.string,
      duty: PropTypes.string
    }),
    disallowTeamIds: PropTypes.arrayOf(PropTypes.string),
    changeValues: PropTypes.func,
    changeTeamId: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    isOperating: false,
    values: {
      teamId: "",
      jersey: "",
      affiliated: false,
      duty: ""
    },
    disallowTeamIds: [],
    changeValues() {},
    changeTeamId() {},
    onCancel() {},
    onSubmit() {}
  };

  handleInputChange = ({ target }) => {
    const { changeValues } = this.props;

    const value = target.name === "affiliated" ? target.value === "true" : target.value;

    changeValues({ [target.name]: value });
  };

  handleTeamChange = teamId => {
    const { changeTeamId } = this.props;

    changeTeamId({ id: teamId });
  };

  handleSubmit = () => {
    const { values, onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const {
      seasonId,
      values: { teamId, jersey, affiliated, position, duty },
      isOperating,
      onCancel,
      disallowTeamIds
    } = this.props;

    return (
      <DashboardForm className="player-form mt-5" isOperating={isOperating}>
        <TitleBar title="Add player to team" titleTag="h3" />
        <ContentSection>
          <FormGroup>
            <SeasonTeamsSelect
              disallowIds={disallowTeamIds}
              seasonId={seasonId}
              defaultValue={teamId}
              onChange={this.handleTeamChange}
            />
          </FormGroup>

          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="jersey">Jersey</Label>
              <Input
                id="teamPlayerJersey"
                name="jersey"
                defaultValue={jersey}
                placeholder="Jersey"
                disabled={teamId === ""}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label for="duty">Designation</Label>
              <Input
                type="select"
                id="teamPlayerDuty"
                name="duty"
                disabled={teamId === ""}
                defaultValue={duty}
                onChange={this.handleInputChange}
              >
                <option value="">No designation</option>
                <option value="captain">Captain</option>
                <option value="alternate_captain">Alternate Captain</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="position">Position</Label>
              <Input
                type="select"
                id="teamPlayerPosition"
                name="position"
                disabled={teamId === ""}
                defaultValue={position}
                onChange={this.handleInputChange}
              >
                <option value="" disabled>
                  Select position
                </option>
                <option value="right_wing">Right Wing</option>
                <option value="left_wing">Left Wing</option>
                <option value="centre">Centre</option>
                <option value="forward">Forward</option>
                <option value="defence">Defence</option>
                <option value="goalie">Goalie</option>
                <option value="transition">Transition</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="affiliated">Status</Label>
              <Input
                type="select"
                id="teamPlayerStatus"
                name="affiliated"
                disabled={teamId === ""}
                defaultValue={affiliated}
                onChange={this.handleInputChange}
              >
                <option value={false}>Regular</option>
                <option value={true}>Affiliated</option>
              </Input>
            </FormGroup>
          </FormItem>

          <DashboardFormFooter className="small-margin">
            <div className="dashboard-form-footer__primary">
              <Button outline color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button color="success" disabled={teamId === ""} onClick={this.handleSubmit}>
                Add
              </Button>
            </div>
          </DashboardFormFooter>
        </ContentSection>
      </DashboardForm>
    );
  }
}

export default PlayerTeam;
