import React from "react";
import PropTypes from "prop-types";

import { Input, Button } from "reactstrap";

import { usePenaltyClassOptions } from "./hooks/usePenaltyClassOptions";

function PenaltyDuration(props) {
  const { length, penaltyClass, onChangeLength, onChangeClass, deletePenaltyDuration } = props;
  const { penaltyClassOptions } = usePenaltyClassOptions();

  return (
    <tr>
      <td>
        <Input type="text" bsSize="sm" value={length} onChange={onChangeLength} />
      </td>
      <td>
        <Input type="select" bsSize="sm" onChange={onChangeClass} value={penaltyClass}>
          <option value="">None</option>
          {penaltyClassOptions.map((val) => (
            <option value={val} key={val}>{val}</option>
          ))}
        </Input>
      </td>
      <td>
        <Button className="delete-button" size="sm" color="danger" onClick={deletePenaltyDuration} outline>
          Delete
        </Button>
      </td>
    </tr>
  );
}

PenaltyDuration.propTypes = {
  length: PropTypes.string.isRequired,
  penaltyClass: PropTypes.string.isRequired,
  onChangeLength: PropTypes.func.isRequired,
  onChangeClass: PropTypes.func.isRequired,
  deletePenaltyDuration: PropTypes.func.isRequired
};

export default PenaltyDuration;
