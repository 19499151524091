import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup } from "reactstrap";

import FormSection from "@/components/FormSection";
import LiveScoringModeField from "./LiveScoringModeField";

import { Can } from "@/components/Ability";

import "./LiveScoringFieldset.scss";

const LIVE_SCORING_MODES = [
  {
    mode: "disabled",
    label: "Disabled",
    description:
      "Live scoring will be disabled on the iPad and live games will not be shown on the season's stats page."
  },
  {
    mode: "scores_only",
    label: "Scores only",
    description:
      "Live game scores will be published for the public on the Scores page and the live game feed page will be available only to users logged into the Dashboard that have the role of Administrator."
  },
  {
    mode: "private",
    label: "Private",
    description:
      "Live game scores and the live game feed page will be available only to users logged into the Dashboard that have the role of Administrator, Director, Convenor, or Observer for the target season."
  },
  {
    mode: "public",
    label: "Public",
    description: "Live game scores and the game feed will be published for the public."
  }
];

function useLiveScoringFieldset({ value: currentMode, onChange }) {
  const changeMode = useCallback(({ target: { name: mode } }) => onChange(mode), [onChange]);

  return LIVE_SCORING_MODES.map(({ mode, label, description }) => ({
    mode,
    label,
    description,
    onChange: changeMode,
    checked: mode === currentMode
  }));
}

function PlayerOfTheGameFieldset({ value, onChange }) {
  const liveScoringModes = useLiveScoringFieldset({ value, onChange });

  return (
    <Can I="update" a="liveScoringMode">
      <FormSection title="Live Scoring">
        <Row>
          <Col md="6">
            <FormGroup>
              {liveScoringModes.map(({ mode, ...rest }) => (
                <LiveScoringModeField key={mode} mode={mode} {...rest} />
              ))}
            </FormGroup>
          </Col>
        </Row>
      </FormSection>
    </Can>
  );
}

PlayerOfTheGameFieldset.propTypes = {
  value: PropTypes.oneOf(LIVE_SCORING_MODES.map(({ mode }) => mode)).isRequired,
  onChange: PropTypes.func
};

PlayerOfTheGameFieldset.defaultProps = {
  onChange() {}
};

export default PlayerOfTheGameFieldset;
