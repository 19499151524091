import React from "react";

import ResourceSelect from "./ResourceSelect";

import useSeasonSelect from "./hooks/useSeasonSelect";

function SeasonSelect() {
  const { isLoaded, isLoading, options, isEnabled, toggleSelected } = useSeasonSelect();

  return (
    <ResourceSelect
      label="Season"
      isLoaded={isLoaded}
      isLoading={isLoading}
      options={options}
      isEnabled={isEnabled}
      toggleSelected={toggleSelected}
    />
  );
}

export default SeasonSelect;
