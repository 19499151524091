import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getLeagueId,
  getSeasonList,
  getSeasonListIsLoaded,
  getSeasonListIsLoading,
  getRoleIsGlobal,
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useLeagueSelect() {
  const leagueId = useSelector(getLeagueId);
  const options = useSelector(state => getSeasonList(state, leagueId));
  const roleIsGlobal = useSelector(getRoleIsGlobal);

  const isLoaded = useSelector(state => getSeasonListIsLoaded(state, leagueId));

  const isLoading = useSelector(state => getSeasonListIsLoading(state, leagueId));

  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ isSelected, id, ...rest }) => {
      const action = isSelected ? "deselectSeason" : "selectSeason";
      const fn = actions[action];

      dispatch(fn({ id, ...rest }));
    },
    [dispatch]
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected
  };
}
