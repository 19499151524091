import { handleActions } from "redux-actions";

import { reportDataSourcesSelectSeasonListLoadingRoutine } from "../routines";
import actions from "../actions";

function updateLeagueSeasons(state, leagueId, data) {
  return {
    ...state,
    data: {
      ...state.data,
      [leagueId]: {
        ...state.data[leagueId],
        ...data
      }
    }
  };
}

const initialState = {
  data: {},
  selectedIds: [],
  includeArchived: true
};

function reduceLoadingRequest(state, { payload: { leagueId } }) {
  return updateLeagueSeasons(state, leagueId, { isLoading: true });
}

function reduceLoadingSuccess(state, { payload: { leagueId, seasons } }) {
  return updateLeagueSeasons(state, leagueId, {
    seasons: seasons.map(({ id, title, type, flaggedPenalties, settings }) => ({
      id,
      title,
      type,
      flaggedPenalties,
      penaltyCodes: (settings && settings.penaltyCodes) || []
    })),
    isLoaded: true
  });
}

function reduceLoadingFailure(state, { payload: { leagueId } }) {
  return updateLeagueSeasons(state, leagueId, { isLoaded: false });
}

function reduceLoadingFulfill(state, { payload: { leagueId } }) {
  return updateLeagueSeasons(state, leagueId, { isLoading: false });
}

function reduceSelected(state, { payload: { id, singleSelect = false } }) {
  return {
    ...state,
    selectedIds: [...(singleSelect ? [] : state.selectedIds), id]
  };
}

function reduceDeselected(state, { payload: { id } }) {
  return {
    ...state,
    selectedIds: state.selectedIds.filter(selectedId => selectedId !== id)
  };
}

function reduceSeasonsClearing(state) {
  return { ...state, selectedIds: [] };
}

function reduceAssociationChanged(state) {
  return {
    ...state,
    selectedIds: []
  };
}

function reduceLeagueSelected(state, { payload: { id: leagueId } }) {
  return {
    ...state,
    data: {
      ...state.data,
      [leagueId]: {
        isLoaded: false,
        isLoading: false,
        seasons: [],
        ...state.data[leagueId]
      }
    }
  };
}

function reduceLeaguesDeselected(state, { payload: { leagueId } }) {
  return { ...state, selectedIds: [] };
}

function reduceSeasonsToggleArchivedIncluded(state) {
  return { ...state, includeArchived: !state.includeArchived };
}

function reduceClearing(state) {
  return { ...initialState };
}

export default handleActions(
  {
    [reportDataSourcesSelectSeasonListLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [reportDataSourcesSelectSeasonListLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [reportDataSourcesSelectSeasonListLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [reportDataSourcesSelectSeasonListLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.seasonList.select]: reduceSelected,
    [actions.seasonList.deselect]: reduceDeselected,
    [actions.seasonList.clear]: reduceSeasonsClearing,
    [actions.associationList.select]: reduceAssociationChanged,
    [actions.associationList.deselect]: reduceAssociationChanged,
    [actions.leagueList.select]: reduceLeagueSelected,
    [actions.leagueList.deselect]: reduceLeaguesDeselected,
    [actions.leagueList.clear]: reduceSeasonsClearing,
    [actions.seasonList.toggleArchivedIncluded]: reduceSeasonsToggleArchivedIncluded,
    [actions.clear]: reduceClearing
  },
  initialState
);
