import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import Layout from "@/layouts/Layout";
import Head from "@/components/Head";
import TitleBar from "@/components/TitleBar";
import DashboardFormFooter from "@/components/DashboardFormFooter";

import chrome from "./assets/chrome.svg";
import safari from "./assets/safari.svg";
import firefox from "./assets/firefox.svg";
import opera from "./assets/opera.svg";

import "./Login.scss";

class Login extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    isLoading: false,
    error: null,
    email: "",
    password: "",
    onChange() {},
    onSubmit() {}
  };

  handleChange = ({ target }) => {
    this.props.onChange({
      [target.name]: target.value
    });
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("otp")) {
      const otp = searchParams.get("otp");
      this.props.onSubmit({ otp, type: "otp" });
    }
  }
  
  handleSubmit = event => {
    event.preventDefault();

    const { email, password } = this.props;

    this.props.onSubmit({ email, password, type:"usernameAndPassword" });
  };

  render() {
    const { isLoading, error, email, password } = this.props;

    return (
      <Layout className="login">
        <Head title="Welcome Back!" />

        <Row className="justify-content-center">
          <Col md="10" lg="6">
            <TitleBar title="Welcome Back!" />

            <div className="login-form">
              <form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input type="email" id="email" name="email" onChange={this.handleChange} defaultValue={email} />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    onChange={this.handleChange}
                    defaultValue={password}
                  />
                </FormGroup>

                {error && <Alert color={`${error ? "danger" : ""}`}>{error}</Alert>}

                <Link to="/password/reset">Forgot password?</Link>

                <DashboardFormFooter className="small-margin">
                  <div className="dashboard-form-footer__secondary">
                    <p className="login__create-account">
                      First time here? <a href="/account/new">Create an account</a>
                    </p>
                  </div>
                  <div className="dashboard-form-footer__primary">
                    <Button type="submit" disabled={isLoading} color="success">
                      {isLoading ? "Processing…" : "Login to Dashboard"}
                    </Button>
                  </div>
                </DashboardFormFooter>
              </form>

              <div className="login__supported-browsers">
                <p className="login__supported-browsers__title">Supported browsers:</p>
                <ul className="login__supported-browsers__container">
                  <li className="login__supported-browsers__item">
                    <a className="login__supported-browsers__item__link" href="https://www.google.com/chrome/">
                      <img className="login__supported-browsers__item__icon" src={chrome} alt="Chrome Icon" />
                      <span className="login__supported-browsers__item__label">Chrome</span>
                    </a>
                  </li>
                  <li className="login__supported-browsers__item">
                    <img className="login__supported-browsers__item__icon" src={safari} alt="Safari Icon" />
                    <span className="login__supported-browsers__item__label">Safari (Mac)</span>
                  </li>
                  <li className="login__supported-browsers__item">
                    <a
                      className="login__supported-browsers__item__link"
                      href="https://www.mozilla.org/en-CA/firefox/new/"
                    >
                      <img className="login__supported-browsers__item__icon" src={firefox} alt="Firefox Icon" />
                      <span className="login__supported-browsers__item__label">Firefox</span>
                    </a>
                  </li>
                  <li className="login__supported-browsers__item">
                    <a className="login__supported-browsers__item__link" href="https://www.opera.com/">
                      <img className="login__supported-browsers__item__icon" src={opera} alt="Opera Icon" />
                      <span className="login__supported-browsers__item__label">Opera</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Login;
