import React from "react";
import PropTypes from "prop-types";

import FormSection from "@/components/FormSection";

import { useSeasonPenaltySettingsFields } from "./hooks/useSeasonPenaltySettingsFields";

import PenaltyCodes from "./PenaltyCodes";

export function SeasonPenaltySettingsFieldset({ value, onChange }) {
  const { penaltyCodes } = useSeasonPenaltySettingsFields({
    value,
    onChange
  });

  return (
    <FormSection title="Penalty Settings">
      <PenaltyCodes {...penaltyCodes} />
    </FormSection>
  );
}

SeasonPenaltySettingsFieldset.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func
};

SeasonPenaltySettingsFieldset.defaultProps = {
  onChange() {}
};