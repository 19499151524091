import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";

import { usePenaltyCodesImporter } from "../hooks/usePenaltyCodesImporter";

import Header from "./Header";
import FilePicker from "./FilePicker";
import ValidationFailure from "./ValidationFailure";
import Confirmation from "./Confirmation";

import "./PenaltyCodesImporter.scss";

function PenaltyCodesImporter(props) {
  const { isOpen, close, penaltyCodes, changePenaltyCodes } = props;

  const {
    step,
    parseFile,
    parseError,
    invalidRows,
    reset,
    importablePenaltyCodes,
    importPenaltyCodes
  } = usePenaltyCodesImporter({ close, penaltyCodes, changePenaltyCodes });

  return (
    <Fragment>
      <Modal
        size="lg"
        fade={false}
        toggle={close}
        isOpen={isOpen}
        centered
        onClosed={reset}
        className="penalty-codes-importer"
      >
        <Header step={step} close={close} />

        {step === "select-file" && <FilePicker onSelect={parseFile} parseError={parseError} />}
        {step === "validation-failure" && <ValidationFailure invalidRows={invalidRows} onReSelect={parseFile} />}
        {step === "confirmation" && (
          <Confirmation onConfirm={importPenaltyCodes} numDelete={penaltyCodes.length} numAdd={importablePenaltyCodes.length} />
        )}
      </Modal>
    </Fragment>
  );
}

PenaltyCodesImporter.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  penaltyCodes: PropTypes.arrayOf(PropTypes.shape({})),
  changePenaltyCodes: PropTypes.func.isRequired
};

export default PenaltyCodesImporter;
