import React from "react";
import PropTypes from "prop-types";

import GameFlag from "./components/GameFlag";

function Flags({ id, flags, seasonId }) {
  return flags.length > 0 ? <GameFlag id={id} flags={flags} seasonId={seasonId} /> : <span>&nbsp;</span>;
}

Flags.propTypes = {
  id: PropTypes.string.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seasonId: PropTypes.string.isRequired
};

export default Flags;
