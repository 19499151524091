import React from "react";
import PropTypes from "prop-types";
import _toUpper from "lodash/toUpper";

import { Button } from "reactstrap";

import Field from "./Field";

import useCoachForm from "../hooks/useCoachForm";

function CoachForm({ coachId }) {
  const { triggerReset, isInvalid, isNotFilled, isPristine, isSaving, triggerSave } = useCoachForm(coachId);

  return (
    <tr>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="firstName" transform={_toUpper} />
      </td>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="lastName" transform={_toUpper} />
      </td>
      <td>
        <Field
          member={{ type: "coaches", id: coachId }}
          name="externalId"
          transform={_toUpper}
          placeholder="Optional"
        />
      </td>
      <td>
        <Field member={{ type: "coaches", id: coachId }} name="position" type="select">
          <option value="">None</option>
          <option value="head_coach">Head Coach</option>
          <option value="assistant_coach">Assistant Coach</option>
          <option value="trainer">Trainer</option>
          <option value="assistant_trainer">Assistant Trainer</option>
          <option value="manager">Manager</option>
          <option value="head_coach_at_large">Head Coach At Large</option>
          <option value="assistant_coach_at_large">Assistant Coach At Large</option>
          <option value="trainer_at_large">Trainer At Large</option>
        </Field>
      </td>
      <td className="text-right">
        <Button size="sm" color="secondary" outline onClick={triggerReset}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          color="success"
          outline={isInvalid || isNotFilled || isPristine}
          disabled={isInvalid || isNotFilled || isPristine}
          onClick={triggerSave}
          className="ml-1"
        >
          {isSaving ? "Saving…" : "Save"}
        </Button>
      </td>
    </tr>
  );
}

CoachForm.propTypes = {
  coachId: PropTypes.string.isRequired
};

export default CoachForm;
