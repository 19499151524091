import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";
import NotFoundPage from "@/routes/NotFoundPage";

import PlayerPenaltyReport from "./pages/PlayerPenaltyReport";
import CoachReport from "./pages/CoachReport";
import RefereeReport from "./pages/RefereeReport";
import PenaltyAccumulationReport from "./pages/PenaltyAccumulationReport";
import PenaltyTypeReport from "./pages/PenaltyTypeReport";
import TrainerVisitsReport from "./pages/TrainerVisitsReport";
import BillingReport from "./pages/BillingReport";
import SuspensionReport from "./pages/SuspensionReport";

class ReportsRoute extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <LoggedInRoute path={`${match.url}/player-penalty`} component={PlayerPenaltyReport} />
        <LoggedInRoute path={`${match.url}/bench-staff-penalty`} component={CoachReport} />
        <LoggedInRoute path={`${match.url}/referee`} component={RefereeReport} />
        <LoggedInRoute path={`${match.url}/penalty-accumulation`} component={PenaltyAccumulationReport} />
        <LoggedInRoute path={`${match.url}/penalty-type`} component={PenaltyTypeReport} />
        <LoggedInRoute path={`${match.url}/trainer-visits`} component={TrainerVisitsReport} />
        <LoggedInRoute path={`${match.url}/billing`} component={BillingReport} />
        <LoggedInRoute path={`${match.url}/suspension`} component={SuspensionReport} />

        <Route path="" component={NotFoundPage} />
      </Switch>
    );
  }
}

export default ReportsRoute;
