import { put, all, takeLatest, select, call } from "redux-saga/effects";
import build from "redux-object";
import _trimEnd from "lodash/trimEnd";

import { gamesheetAPIRequest } from "@/redux/api/sagas";
import { loadPlayerPenaltyReport } from "@/lib/api/reports";
import { loadPlayers } from "@/lib/api/players";

import { playerPenaltyReportLoadingRoutine, playerPenaltyReportListLoadingRoutine } from "./routines";

import selectors from "./selectors";

import actions from "./actions";

const {
  getPlayerPenaltyReportListSearchCurrentQuery,
  getPlayerPenaltyReportListOffset,
  getPlayerPenaltyReportListLimit
} = selectors;

function* playersLoadingSaga({ payload: { append = false } = {} }) {
  const query = yield select(getPlayerPenaltyReportListSearchCurrentQuery);

  const limit = yield select(getPlayerPenaltyReportListLimit);
  const offset = append ? yield select(getPlayerPenaltyReportListOffset) : 0;

  yield put(playerPenaltyReportListLoadingRoutine.request({ query, append }));

  const { data, rawData, meta } = yield call(gamesheetAPIRequest, loadPlayers, {
    include: "leagues",
    limit,
    offset,
    query
  });

  const players = build(data, "searchPersons") || [];

  yield put(
    playerPenaltyReportListLoadingRoutine.success({
      ids: rawData.map(({ id }) => id),
      totalCount: meta ? meta["total-count"] || 0 : 0,
      filteredCount: meta ? meta["filtered-count"] || 0 : 0,
      players,
      query,
      append
    })
  );

  yield put(playerPenaltyReportListLoadingRoutine.fulfill({ query, append }));
}

function* commitSearchQuerySaga() {
  const query = yield select(getPlayerPenaltyReportListSearchCurrentQuery);

  if (_trimEnd(query)) {
    yield put(playerPenaltyReportListLoadingRoutine());
  } else {
    yield put(actions.list.clear());
  }
}

function* reportLoadingSaga({ payload: { externalId } }) {
  yield put(playerPenaltyReportLoadingRoutine.request());

  try {
    const { data } = yield call(gamesheetAPIRequest, loadPlayerPenaltyReport, {
      externalId
    });

    const report = build(data, "playerPenaltyReports", externalId);

    yield put(playerPenaltyReportLoadingRoutine.success({ report }));
  } catch (error) {
    const responseStatus = error.response && error.response.status;

    yield put(playerPenaltyReportLoadingRoutine.failure({ responseStatus }));
  } finally {
    yield put(playerPenaltyReportLoadingRoutine.fulfill());
  }
}

export function* playerPenaltyReportFlow() {
  yield all([
    takeLatest(actions.list.search.commit, commitSearchQuerySaga),
    takeLatest(playerPenaltyReportListLoadingRoutine.TRIGGER, playersLoadingSaga),
    takeLatest(playerPenaltyReportLoadingRoutine.TRIGGER, reportLoadingSaga)
  ]);
}
