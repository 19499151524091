import { all, takeLatest, takeLeading, select, call, put, actionChannel, take } from "redux-saga/effects";
import { buffers } from "redux-saga";
import { decode } from "jsonwebtoken";
import axios from 'axios';
import _isEqual from "lodash/isEqual";

import { restart as restartAction } from "@/redux/app/actions";
import { loginRoutine } from "@/redux/login/routines";
import { logoutAction } from "@/redux/logout/actions";
import { tokenRefreshRoutine } from "@/redux/token/routines";
import { config } from "../../config";

import { tokenSetRoutine, tokenResetRoutine } from "./routines";
import { getTokenIsStale, getEncodedAccessToken, getEncodedRefreshToken } from "./selectors";
import { accountUpdateRoutine } from "@/redux/account/routines";

export function* getTokensFromLocalStorageSaga() {
  const access = yield call([localStorage, "getItem"], "accessToken");
  const refresh = yield call([localStorage, "getItem"], "refreshToken");
  const roles = yield call([localStorage, "getItem"], "rolesToken");

  return {
    access,
    refresh,
    roles,
  };
}

export function* setTokensToLocalStorageSaga({ payload: { access, refresh, roles } }) {
  yield call([localStorage, "setItem"], "accessToken", access);
  yield call([localStorage, "setItem"], "refreshToken", refresh);
  yield call([localStorage, "setItem"], "rolesToken", roles);
}

export function* removeTokensFromLocalStorageSaga() {
  yield call([localStorage, "removeItem"], "accessToken");
  yield call([localStorage, "removeItem"], "refreshToken");
  yield call([localStorage, "removeItem"], "rolesToken");
}

export function* setTokenSaga({ payload }) {
  yield put(tokenSetRoutine.request())
  yield call(setTokensToLocalStorageSaga, { payload });
  yield put(tokenSetRoutine.success(payload));
  yield put(tokenSetRoutine.fulfill());  
}

export function* refreshTokenSaga({ payload = {} }) {
  const { skipRolesChangeTest = false } = payload;

  yield put(tokenRefreshRoutine.request());

  try {
    const {
      refresh: currentRefreshToken,
      roles: currentRolesToken,
    } = yield call(getTokensFromLocalStorageSaga);

    const url = `${config.AUTH_GATEWAY}/auth/v4/refresh`;
    const response = yield call(
      () => axios.post(
        url,
        {},
        {
          headers: {
            "Authorization": `Bearer ${currentRefreshToken}`,
          },
        },
      )
    );

    if (response.status !== 200) {
      throw response
    }

    const nextRolesToken = response.data.roles;
    const { roles: nextRoles } = decode(nextRolesToken, { complete: true }).payload;
    const { roles: currentRoles } = decode(currentRolesToken, { complete: true }).payload;

    if (skipRolesChangeTest || _isEqual(currentRoles, nextRoles)) {
      yield put(tokenRefreshRoutine.success(response.data));
    } else {
      yield all([put(logoutAction()), put(restartAction())]);
    }
  } catch ({ response = {} }) {
    let message;

    switch (response.status) {
      case 403:
        message = "Token is wrong";
        break;
      default:
        message = "Something went wrong. Please try again";
    }

    yield put(tokenRefreshRoutine.failure(message));
    yield put(logoutAction());
  } finally {
    yield put(tokenRefreshRoutine.fulfill());
  }
}

export function* ensureTokenIsFreshSaga() {
  const refreshToken = yield select(getEncodedRefreshToken);

  if (!refreshToken) {
    return undefined;
  }

  const tokenIsStale = yield select(getTokenIsStale);

  if (tokenIsStale) {
    const fulfillChannel = yield actionChannel(tokenRefreshRoutine.FULFILL, buffers.dropping(1));

    yield put(tokenRefreshRoutine());
    yield take(fulfillChannel);
  }

  return yield select(getEncodedAccessToken);
}

export function* resetTokenSaga() {
  yield put(tokenResetRoutine.request());
  yield call(removeTokensFromLocalStorageSaga);
  yield put(tokenResetRoutine.success());
  yield put(tokenResetRoutine.fulfill());
}

export function* tokenFlow() {
  yield all([
    takeLatest([loginRoutine.SUCCESS, tokenRefreshRoutine.SUCCESS, accountUpdateRoutine.SUCCESS], setTokenSaga),
    takeLeading(tokenRefreshRoutine.TRIGGER, refreshTokenSaga),
    takeLatest(tokenResetRoutine.TRIGGER, resetTokenSaga)
  ]);
}
