import { signInWithCustomToken } from "firebase/auth";
import { firebase } from "@/firebase";
import { config } from "@/config";
import { useEffect } from "react";



export const useLoginWithOtp = () => {

  const pathname = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search);
  const query = searchParams.get("otp");

  useEffect(() => {

    if (!query) {
      return;
    }

    // send the otp to the auth gateway
    fetch(
      `${config.AUTH_GATEWAY}/auth/v4/otp/loginWithFirebase`, {
      method: 'POST',
      body: query
    })
      .then(response => response.json())

      // get the firebase token
      // sign in with the firebase token
      .then(async data => {
        await firebase.auth().setPersistence(PERSISTENCE_TYPE);
        return firebase.auth().signInWithCustomToken(data.firebase);

      })
      .catch(error => console.log('Error:', error));

    // remove otp query param
    // Build new URL with remaining query parameters
    // Replace current URL with new URL
    // searchParams.delete("otp");
    // const remainingQueryParams = searchParams.toString();
    // const newUrl = remainingQueryParams ? `${pathname}?${remainingQueryParams}` : pathname;
    // window.history.replaceState(null, "", newUrl);

  }, [pathname, query]);

};
