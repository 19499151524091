import { REFEREE_POSITIONS } from "@/constants";

const DEFAULT_LANG = "en";

export const getCodeTitle = (data, lang = "en", code) => {
  const defaultLang = data[DEFAULT_LANG];
  const isLangExist = !!data[lang];
  const currentLang = isLangExist ? data[lang] : defaultLang;
  const codeExists = !!currentLang[code];

  return codeExists ? currentLang[code] : code;
};

export const coachPosition = (code, lang = "en") => {
  const data = {
    en: {
      head_coach: "Head Coach",
      assistant_coach: "Assistant Coach",
      manager: "Manager",
      trainer: "Trainer",
      assistant_trainer: "Assistant Trainer",
      head_coach_at_large: "Head Coach at Large",
      assistant_coach_at_large: "Assistant Coach at Large",
      trainer_at_large: "Trainer at Large"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const playerPosition = (code, lang = "en") => {
  const data = {
    en: {
      center: "Centre",
      centre: "Centre",
      right_wing: "Right Wing",
      left_wing: "Left Wing",
      defence: "Defence",
      forward: "Forward",
      goalie: "Goalie",
      transition: "Transition"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const playerDuty = (code, lang = "en") => {
  const data = {
    en: {
      captain: "Captain",
      alternate_captain: "Alternate Captain"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const playerAffiliated = (code, lang = "en") => {
  const data = {
    en: {
      true: "Affiliated",
      false: "Regular"
    }
  };

  return getCodeTitle(data, lang, code);
};

export const refereePosition = code => REFEREE_POSITIONS[code];
