import nanoid from "nanoid";
import _uniq from "lodash/uniq";
import _orderBy from "lodash/orderBy";

import { playerPosition, playerDuty, coachPosition, refereePosition } from "@/utils/codeToTitle";

import { formatDate, formatTime, formatDateTime } from "@/utils/date";
import sortByPeriodAndTime from "@/utils/sortByPeriodAndTime";
import sortPenalties from "@/utils/sortPenalties";
import sortGamesheetHistoryRecords from "@/utils/sortGamesheetHistoryRecords";
import formatIceTime from "@/utils/formatIceTime";

const memberName = ({ firstName, lastName }) => (firstName && lastName ? `${firstName} ${lastName}` : undefined);

const memberIdentity = (state, { type, id }) => {
  switch (type) {
    case "players":
      return getGamesheetPlayerNumber(state, id);
    case "coaches":
      return getGamesheetCoachInitials(state, id);
    case "teams":
      return "T";
    default:
      return "N/A";
  }
};

const penalizedMemberName = (state, { type, id }) => {
  switch (type) {
    case "players":
      return getGamesheetPlayerName(state, id);
    case "coaches":
      return "COACH";
    case "teams":
      return "TEAM";
    default:
      return type;
  }
};

const gameNote = ({ text, audioUrl }) => ({
  type: audioUrl ? "audio" : "text",
  content: audioUrl || text
});

const sortPlayers = (playerA, playerB) => {
  if (playerA.number === "" && playerB.number) {
    return 0;
  }

  if (playerA.number === "") {
    return 1;
  }

  if (playerB.number === "") {
    return -1;
  }

  return parseInt(playerA.number) > parseInt(playerB.number) ? 1 : -1;
};

const filterPenaltiesWithNotes = penalties =>
  penalties.filter(({ refereeNote }) => refereeNote && refereeNote.refereeId && refereeNote.refereeId !== "");

const periodCaption = period => {
  switch (period) {
    case "1":
      return "1st";
    case "2":
      return "2nd";
    case "3":
      return "3rd";
    case "4":
      return "4th";
    default:
      return "OT";
  }
};

function calculateTeamShootoutScore(teamAttempts, oppositeAttempts) {
  const countGoals = attempts => attempts.filter(({ result }) => result === "goal").length;

  const goals = countGoals(teamAttempts);
  const oppositeGoals = countGoals(oppositeAttempts);

  return goals > oppositeGoals ? 1 : 0;
}

const selectRoot = state => state.gamesheet;
const selectMeta = state => selectRoot(state).meta;
const selectData = state => selectRoot(state).data;
const selectPlayers = state => selectData(state).players;
const selectPlayer = (state, id) => selectPlayers(state)[id] || {};
const selectCoaches = state => selectData(state).coaches;
const selectCoach = (state, id) => selectCoaches(state)[id] || {};
const selectProperties = state => selectData(state).properties;
const selectReferees = state => selectData(state).referees;
const selectScorekeeperNotes = state => selectData(state).scorekeeperNotes;

const selectTeamPlayersIds = (state, team) => selectData(state)[team].playersIds;

const selectTeamCoachesIds = (state, team) => selectData(state)[team].coachesIds;

const selectTeamSuspendedMembers = (state, team) => {
  const suspensions = selectData(state)[team].suspensions;

  const players = (suspensions.players || []).map(({ id, ...rest }) => ({
    ...rest,
    type: "players",
    name: memberName(selectPlayer(state, id)),
    id
  }));

  const coaches = (suspensions.coaches || []).map(({ id, ...rest }) => ({
    ...rest,
    type: "coaches",
    name: memberName(selectCoach(state, id)),
    id
  }));

  return [...players, ...coaches];
};

// duplicate of function in src/redux/gameForm/selectors.js
const getPenaltyLabel = (state, code) => {
  const data = selectData(state);
  if ("penaltyTypes" in data && code in data.penaltyTypes) {
    return data.penaltyTypes[code];
  }

  return code;
};

const generatePenaltyId = (...params) => {
  return params
    .map(item => {
      if (item === null || item === undefined) {
        return "";
      }
      item = item.toString();
      return item.replace(/[\W_]+/g, "_");
    })
    .join("-");
};

const selectPenaltyNotes = state => {
  const visitorPenalties = filterPenaltiesWithNotes(selectData(state).visitor.penalties).map((penalty, index) => ({
    id: generatePenaltyId(index, penalty.period, penalty.offTime, penalty.code, "visitor"),
    label: getPenaltyLabel(state, penalty.code),
    team: getGamesheetTeamTitle(state, "visitor"),
    ...penalty
  }));

  const homePenalties = filterPenaltiesWithNotes(selectData(state).home.penalties).map((penalty, index) => ({
    id: generatePenaltyId(index, penalty.period, penalty.offTime, penalty.code, "home"),
    label: getPenaltyLabel(state, penalty.code),
    team: getGamesheetTeamTitle(state, "home"),
    ...penalty
  }));

  return [...visitorPenalties, ...homePenalties].map(
    ({ id, label, period, team, penalized, length, code, refereeNote }) => {
      const referee = selectReferees(state)[refereeNote.refereeId];

      return {
        penaltyId: id,
        label: label,
        type: "penalty",
        number: memberIdentity(state, penalized),
        player: penalizedMemberName(state, penalized),
        note: gameNote(refereeNote),
        referee: {
          position: refereePosition(referee.position),
          name: referee.lastName
        },
        period,
        team,
        length,
        code
      };
    }
  );
};

const selectRefereeNotes = state => {
  const notes = selectData(state).refereeNotes || [];

  return notes.map(note => {
    const referee = selectReferees(state)[note.refereeId];

    return {
      type: "general",
      note: gameNote(note),
      referee: {
        position: refereePosition(referee.position),
        name: referee.lastName
      }
    };
  });
};

const selectTeamTitleById = (state, teamId) => {
  const visitor = selectData(state).visitor;
  const home = selectData(state).home;

  return [visitor, home].find(({ id }) => id === teamId).title;
};

const selectGeneralScorekeeperNotes = state => {
  const notes = selectScorekeeperNotes(state).generalNotes;

  return notes.map(({ ...note }) => ({
    type: "General",
    note: gameNote(note)
  }));
};

const selectInjuryScorekeeperNotes = state => {
  const notes = selectScorekeeperNotes(state).injuryNotes;

  return notes.map(({ period, time, teamId, injuredId, ...rest }) => {
    const team = selectTeamTitleById(state, teamId);
    const injured = getGamesheetPlayerNumber(state, injuredId) || "N/A";
    const data = `${team} injury #${injured} ${time}, period ${period}`;

    return {
      type: "Injury",
      note: gameNote(rest),
      data
    };
  });
};

const selectTimeoutScorekeeperNotes = state => {
  const notes = selectScorekeeperNotes(state).timeoutNotes;

  return notes.map(({ period, time, teamId, ...rest }) => {
    const team = selectTeamTitleById(state, teamId);
    const data = `${team} TO ${time}, period ${period}`;

    return {
      type: "Timeout",
      note: gameNote(rest),
      data
    };
  });
};

const selectGoaliesIceTime = state => selectData(state).goaliesIceTime;

const selectHistory = state => selectRoot(state).history;

export const getGamesheetIsLoading = state => selectMeta(state).isLoading;
export const getGamesheetIsLoaded = state => selectMeta(state).isLoaded;
export const getGamesheetIsForbidden = state => selectMeta(state).isForbidden;
export const getGamesheetError = state => selectMeta(state).error;

export const getGamesheetCoachInitials = (state, id) => {
  const { firstName, lastName } = selectCoach(state, id) || {
    firstName: "",
    lastName: ""
  };

  return firstName[0] + lastName[0];
};

export const getGamesheetTeamTitle = (state, team) => selectData(state)[team].title;

export const getGamesheetPlayerNumber = (state, playerId) =>
  playerId !== "" ? selectPlayer(state, playerId).number : "";

export const getGamesheetPlayerName = (state, playerId) => memberName(selectPlayer(state, playerId));

export const getGamesheetPlayerNote = (state, playerId) => {
  const player = selectPlayer(state, playerId);
  const notes = [];
  const position = playerPosition(player.position);
  const duty = playerDuty(player.duty);

  if (position) {
    if (position === "Goalie") {
      const iceTime = selectGoaliesIceTime(state)[playerId];

      if (iceTime > 0) {
        notes.push(`${position} (${formatIceTime(iceTime)})`);
      } else {
        notes.push(position);
      }
    } else {
      notes.push(position);
    }
  }

  if (duty) {
    notes.push(duty);
  }

  if (player.affiliated) {
    notes.push("Affiliated player");
  }

  return notes.join(", ");
};

export const getGamesheetPlayer = (state, playerId) => {
  const player = selectPlayer(state, playerId);
  const goaliesIceTime = selectGoaliesIceTime(state);

  return {
    id: playerId,
    number: player.number,
    name: memberName(player),
    lastName: player.lastName,
    note: getGamesheetPlayerNote(state, playerId),
    starting: player.starting,
    affiliated: player.affiliated,
    duty: player.duty,
    status: player.status,
    iceTime: player.position === "goalie" ? goaliesIceTime[playerId] : undefined,
    position: player.position
  };
};

export const getGamesheetCoach = (state, coachId) => {
  const coach = selectCoach(state, coachId);

  return {
    id: coachId,
    name: memberName(coach),
    position: coachPosition(coach.position),
    status: coach.status,
    signature: coach.signature
  };
};

export const getGamesheetTeamPlayers = (state, team) =>
  selectTeamPlayersIds(state, team)
    .map(id => getGamesheetPlayer(state, id))
    .filter(({ status }) => status === "playing")
    .sort(sortPlayers);

export const getGamesheetTeamInjuredPlayers = (state, team) =>
  selectTeamPlayersIds(state, team)
    .map(id => getGamesheetPlayer(state, id))
    .filter(({ status }) => status === "injured")
    .sort(sortPlayers);

export const getGamesheetTeamGoals = (state, team) => {
  const { goals } = selectData(state)[team];

  return (goals || [])
    .map(({ period, time, scorerId, assistAId, assistBId, goalieId }) => ({
      assistA: getGamesheetPlayerNumber(state, assistAId),
      assistB: getGamesheetPlayerNumber(state, assistBId),
      scorer: getGamesheetPlayerNumber(state, scorerId),
      goalie: goalieId && goalieId !== "" ? getGamesheetPlayerNumber(state, goalieId) : "EN",
      period,
      time,
      assistAName: getGamesheetPlayerName(state, assistAId),
      assistBName: getGamesheetPlayerName(state, assistBId),
      scorerName: getGamesheetPlayerName(state, scorerId),
      goalieName: goalieId && goalieId !== "" ? getGamesheetPlayerName(state, goalieId) : "Empty Net"
    }))
    .sort(sortByPeriodAndTime);
};

export const getGamesheetTeamPenalties = (state, team) => {
  const { penalties } = selectData(state)[team];

  return penalties
    .map(({ period, length, offTime, startTime, onTime, code, penalized, servedById }, index) => ({
      timeOff: offTime,
      timeStart: startTime,
      timeOn: onTime,
      member: memberIdentity(state, penalized),
      memberName: penalizedMemberName(state, penalized),
      servedBy: getGamesheetPlayerNumber(state, servedById),
      servedByName: getGamesheetPlayerName(state, servedById),
      period,
      length,
      code,
      label: getPenaltyLabel(state, code),
      id: generatePenaltyId(index, period, offTime, code, team)
    }))
    .sort(sortPenalties);
};

export const getGamesheetTeamTotalPenaltyMinutes = (state, team) => {
  const { penalties } = selectData(state)[team];

  return penalties
    .map(({ length }) => Number(length))
    .filter(length => length)
    .reduce((total, length) => total + length, 0);
};

export const getGamesheetTeamCoaches = (state, team) => {
  const coaches = selectTeamCoachesIds(state, team).map(id => getGamesheetCoach(state, id));

  return coaches.filter(({ status }) => status === "coaching");
};

export const getGamesheetGoalieStats = (state, team) => {
  const theirShots = selectData(state).shots[team === "home" ? "visitor" : "home"];
  const shotsByGoalie = theirShots.reduce((collector, shot) => {
    if (shot.goalieId in collector) {
      if (shot.period in collector[shot.goalieId]) {
        collector[shot.goalieId] = {
          ...collector[shot.goalieId],
          [shot.period]: collector[shot.goalieId][shot.period] + 1,
        };
      } else {
        collector[shot.goalieId] = {
          ...collector[shot.goalieId],
          [shot.period]: 1,
        };
      }
    } else {
      collector[shot.goalieId] = {
        [shot.period]: 1,
      };
    }

    return collector;
  }, {});

  const ourShots = selectData(state).shots[team];
  
  let periods = Array.from(new Set([ ...ourShots, ...theirShots ].map(shot => shot.period))).sort();
  if (periods.length < 3) {
    const periodLengths = selectData(state).properties.periods;
    if (periodLengths["1"] > 0 && !periods.includes("1")) periods.push("1");
    if (periodLengths["2"] > 0 && !periods.includes("2")) periods.push("2");
    if (periodLengths["3"] > 0 && !periods.includes("3")) periods.push("3");
    if (periodLengths["4"] > 0 && !periods.includes("4")) periods.push("4");
    periods = periods.sort();
  }

  const allToi = selectData(state).goaliesIceTime;
  const playerIds = selectData(state)[team].playersIds;
  const teamToi = Object.keys(allToi).filter(playerId => playerIds.includes(playerId));

  const stats = teamToi.reduce((collector, playerId) => ([
    ...collector,
    {
      playerId,
      jersey: getGamesheetPlayerNumber(state, playerId),
      toi: allToi[playerId],
      shots: shotsByGoalie[playerId] || {},
    },
  ]), []);

  return {
    stats,
    periods
  };
}

export const getGamesheetTeamData = (state, team) => {
  return {
    title: getGamesheetTeamTitle(state, team),
    players: getGamesheetTeamPlayers(state, team),
    injuredPlayers: getGamesheetTeamInjuredPlayers(state, team),
    suspendedMembers: selectTeamSuspendedMembers(state, team),
    goals: getGamesheetTeamGoals(state, team),
    goalieStats: getGamesheetGoalieStats(state, team),
    totalPenaltyMinutes: getGamesheetTeamTotalPenaltyMinutes(state, team),
    penalties: getGamesheetTeamPenalties(state, team),
    coaches: getGamesheetTeamCoaches(state, team),
    fairPlayEarned: getGamesheetTeamFairPlayEarned(state, team)
  };
};

export const getGamesheetTeamFairPlayEarned = (state, team) => {
  const { fairPlayEarned } = selectData(state)[team];
  return fairPlayEarned || false;
};

export const getGamesheetGameNumber = state => {
  const { number } = selectProperties(state);

  return number && number !== "" ? number : undefined;
};

export const getGamesheetGameDate = state => {
  const { startTime } = selectProperties(state);

  return startTime && startTime !== "" ? formatDate(startTime, "UTC") : undefined;
};

export const getGamesheetGameStartTime = state => {
  const { startTime } = selectProperties(state);

  return startTime && startTime !== "" ? formatTime(startTime, "UTC") : undefined;
};

export const getGamesheetGameEndTime = state => {
  const { endTime } = selectProperties(state);

  return endTime && endTime !== "" ? formatTime(endTime, "UTC") : undefined;
};

export const getGamesheetGameLocation = state => {
  const { location } = selectProperties(state);

  return location && location !== "" ? location : undefined;
};

export const getGamesheetVenue = state => ({
  date: getGamesheetGameDate(state),
  timeStart: getGamesheetGameStartTime(state),
  timeEnd: getGamesheetGameEndTime(state),
  location: getGamesheetGameLocation(state)
});

export const getGamesheetFinalScore = state => {
  const visitorGoals = selectData(state).visitor.goals.length;
  const homeGoals = selectData(state).home.goals.length;

  const visitorShootoutAttempts = selectData(state).shootout.visitorAttempts;
  const homeShootoutAttempts = selectData(state).shootout.homeAttempts;

  const visitorShootoutScore = calculateTeamShootoutScore(visitorShootoutAttempts, homeShootoutAttempts);

  const homeShootoutScore = calculateTeamShootoutScore(homeShootoutAttempts, visitorShootoutAttempts);

  return {
    visitor: visitorGoals + visitorShootoutScore,
    home: homeGoals + homeShootoutScore
  };
};

export const getGamesheetScorekeeper = state => {
  const { name, phone } = selectProperties(state).scorekeeper;

  return {
    name: name && name !== "" ? name : undefined,
    phone: phone && phone !== "" ? phone : undefined
  };
};

export const getGamesheetGamePeriods = state => {
  const { periods } = selectProperties(state);

  return Object.entries(periods || {}).map(([period, length]) => ({
    caption: periodCaption(period),
    length
  }));
};

export const getGamesheetGameCategory = state => {
  const { category } = selectProperties(state);

  return category && category !== "" ? category : undefined;
};

export const getGamesheetGameType = state => {
  const { code, gamesPlayed, gamesTotal } = selectProperties(state).gameType;

  return {
    progress: gamesTotal > 0 ? `${gamesPlayed} of ${gamesTotal}` : null,
    code
  };
};

export const getGamesheetReferees = state =>
  Object.entries(selectReferees(state)).map(([id, { position, externalId, signature, ...rest }]) => ({
    name: memberName(rest),
    id,
    position: refereePosition(position),
    externalId: externalId && externalId !== "" ? externalId : undefined,
    signature
  }));

export const getGamesheetCurfew = state => {
  const { curfew } = selectProperties(state);

  return {
    ...curfew,
    time: curfew.time && formatTime(curfew.time, "UTC")
  };
};

const selectTeamDivisions = state => {
  const visitorDivision = selectData(state).visitor.division;
  const homeDivision = selectData(state).home.division;

  return [visitorDivision || "N/A", homeDivision || "N/A"];
};

const getGamesheetFlags = state => {
  const { flags } = selectProperties(state);
  for (let i = 0; i < flags.length; i++) {
    if (flags[i]["code"] !== undefined) {
      flags[i]["label"] = getPenaltyLabel(state, flags[i]["code"]);
    }
  }

  return flags;
};

export const getGamesheetProperties = state => ({
  gameNumber: getGamesheetGameNumber(state),
  venue: getGamesheetVenue(state),
  league: selectProperties(state).league,
  divisions: _uniq(selectTeamDivisions(state)).join(", "),
  category: getGamesheetGameCategory(state),
  gameType: getGamesheetGameType(state),
  periods: getGamesheetGamePeriods(state),
  firstFlood: selectProperties(state).firstFlood,
  secondFlood: selectProperties(state).secondFlood,
  curfew: getGamesheetCurfew(state),
  finalScore: getGamesheetFinalScore(state),
  scorekeeper: getGamesheetScorekeeper(state),
  flags: getGamesheetFlags(state),
  fppEnabled: selectProperties(state).fppEnabled
});

export const getGamesheetRefereeReports = state => {
  const penaltyNotes = selectPenaltyNotes(state);
  const refereeNotes = selectRefereeNotes(state);

  return [...penaltyNotes, ...refereeNotes].map(note => ({
    id: nanoid(),
    ...note
  }));
};

export const getGamesheetScorekeeperNotes = state => {
  const generalNotes = selectGeneralScorekeeperNotes(state);
  const injuryNotes = selectInjuryScorekeeperNotes(state);
  const timeoutNotes = selectTimeoutScorekeeperNotes(state);

  return [...generalNotes, ...injuryNotes, ...timeoutNotes].map(note => ({
    id: nanoid(),
    ...note
  }));
};

export const getGamesheetShootout = state => {
  const shootout = selectData(state).shootout;
  const players = selectPlayers(state);

  const prepareAttempts = attempts =>
    attempts.map(({ shooterId, goalieId, ...rest }) => ({
      ...rest,
      goalie: {
        id: goalieId,
        ...players[goalieId]
      },
      shooter: {
        id: shooterId,
        ...players[shooterId]
      }
    }));

  const homeAttempts = prepareAttempts(shootout.homeAttempts);
  const visitorAttempts = prepareAttempts(shootout.visitorAttempts);

  return {
    hasShootout: shootout.hasShootout,
    shotFirst:
      Math.min(...homeAttempts.map(({ number }) => number)) < Math.min(...visitorAttempts.map(({ number }) => number))
        ? "home"
        : "visitor",
    homeAttempts: _orderBy(homeAttempts, ["number"], ["asc"]),
    visitorAttempts: _orderBy(visitorAttempts, ["number"], ["asc"])
  };
};

export const getGamesheet = state => ({
  visitor: getGamesheetTeamData(state, "visitor"),
  home: getGamesheetTeamData(state, "home"),
  properties: getGamesheetProperties(state),
  referees: getGamesheetReferees(state),
  refereeReports: getGamesheetRefereeReports(state),
  scorekeeperNotes: getGamesheetScorekeeperNotes(state),
  shootout: getGamesheetShootout(state)
});

export const getGamesheetForPDF = (state, historyState) => {
  const {
    gameType: { code: gameType, gamesPlayed, gamesTotal },
    league,
    periods
  } = selectProperties(state);

  let associationLogo;
  let leagueLogo;

  if (historyState) {
    //historyState is the unaltered state that gets passed through only when viewing/downloading via the history tab
    associationLogo = historyState.gamesheet.meta.associationLogo
    leagueLogo = historyState.gamesheet.meta.leagueLogo
  } else {
    associationLogo = state.gamesheet.meta.associationLogo
    leagueLogo = state.gamesheet.meta.leagueLogo
  }

  return {
    associationLogo: associationLogo === "" ? null : associationLogo,
    leagueLogo: leagueLogo === "" ? null : leagueLogo,
    gameType,
    gamesPlayed,
    gamesTotal,
    league,
    periods,
    startDate: getGamesheetGameDate(state),
    startTime: getGamesheetGameStartTime(state),
    endTime: getGamesheetGameEndTime(state),
    location: getGamesheetGameLocation(state),
    number: getGamesheetGameNumber(state),
    division: selectData(state).home.division,
    category: getGamesheetGameCategory(state),
    finalScore: getGamesheetFinalScore(state),
    scorekeeper: getGamesheetScorekeeper(state),
    curfew: getGamesheetCurfew(state),
    firstFlood: selectProperties(state).firstFlood,
    secondFlood: selectProperties(state).secondFlood,
    visitor: getGamesheetTeamData(state, "visitor"),
    home: getGamesheetTeamData(state, "home"),
    referees: getGamesheetReferees(state),
    refereeReports: getGamesheetRefereeReports(state),
    scorekeeperNotes: getGamesheetScorekeeperNotes(state),
    shootout: getGamesheetShootout(state),
  };
};

export const getGamesheetIsExporting = state => selectMeta(state).isExporting;

export const getGamesheetHistoryIsModalOpen = state => selectHistory(state).isOpen;

export const getGamesheetHistoryRecords = state => {
  const records = selectHistory(state).records;
  const ids = Object.keys(records);

  return ids
    .map(id => ({ ...records[id], id }))
    .sort(sortGamesheetHistoryRecords)
    .map(({ date, ...rest }) => ({
      ...rest,
      date: formatDateTime(date)
    }));
};

export const getGamesheetHistoryRecordForPDF = (state, id) => {
  return getGamesheetForPDF(selectHistory(state).records[id], state)
};

export const getGamesheetHistoryRecordIsLoaded = (state, id) =>
  selectHistory(state).records[id].gamesheet.meta.isLoaded === true;

export const getGamesheetHistoryIsLoaded = state => selectHistory(state).isLoaded === true;

export const getGamesheetHistoryIsWorking = state =>
  selectHistory(state).isLoading === true ||
  Object.values(selectHistory(state).records).filter(
    ({
      gamesheet: {
        meta: { isExporting }
      }
    }) => isExporting === true
  ).length > 0;

export const getGamesheetHistoryPreviewURL = state => selectHistory(state).previewUrl;

export const getGamesheetHistoryRecordDateTime = (state, id) => formatDateTime(selectHistory(state).records[id].date);

export const getGamesheetCountDashboardNotes = state => selectMeta(state).countNotes;
