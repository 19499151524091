import React from "react";
import PropTypes from "prop-types";

import TeamMemberPenaltyReportGameLink from "../GameLink";

function Penalty({ game, code }) {
  return (
    <tr>
      <td>{game.date}</td>
      <td>
        <TeamMemberPenaltyReportGameLink {...game} />
      </td>
      <td>{code}</td>
    </tr>
  );
}

Penalty.propTypes = {
  game: PropTypes.shape({ date: PropTypes.string.isRequired }).isRequired,
  code: PropTypes.string
};

Penalty.defaultProps = {
  code: ""
};

export default Penalty;
