export const formatDate = (dateString, timeZone) =>
  new Date(dateString).toLocaleDateString("en", {
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone
  });

export const formatTime = (timeString, timeZone) =>
  new Date(timeString).toLocaleTimeString("en", {
    hour: "numeric",
    minute: "numeric",
    timeZone
  });

export const formatDateTime = (dateTimeString, timeZone) =>
  new Date(dateTimeString).toLocaleTimeString("en", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone
  });

export const formatFullDateTime = (dateTimeString, timeZone) =>
  new Date(dateTimeString).toLocaleTimeString("en", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone
  });
