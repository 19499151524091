import React, { Fragment } from "react";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import IpadKeyForm, { useIpadKeyFormClearing } from "@/components/IpadKeyForm";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

function NewIpadKey() {
  const {
    seasonAssociationId,
    seasonLeagueId,
    seasonId,
    season,
    seasonBreadcrumbs,
    seasonIsLoaded,
    seasonIsLoading,
    seasonIsForbidden
  } = useCurrentSeasonContext();

  useIpadKeyFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "ipadKeys", seasonId }}
      action="create"
      skip={!seasonIsLoaded && !seasonIsForbidden}
    >
      <Head title="New iPad Key" />

      {seasonIsLoaded ? (
        <Fragment>
          <Breadcrumbs items={[...seasonBreadcrumbs, { title: "iPad Keys", url: `/seasons/${seasonId}/ipad-keys` }]} />
          <TitleBar title={`New Key for "${season.title}"`} />

          <IpadKeyForm associationId={seasonAssociationId} leagueId={seasonLeagueId} seasonId={seasonId} />
        </Fragment>
      ) : (
        <PageLoader isLoading={seasonIsLoading} isLoaded={seasonIsLoaded} />
      )}
    </ProtectedLayout>
  );
}

export default NewIpadKey;
