import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSeasonId,
  getDivisionList,
  getDivisionListIsLoaded,
  getDivisionListIsLoading,
  getRoleIsGlobal,
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

export default function useLeagueSelect() {
  const seasonId = useSelector(getSeasonId);
  const options = useSelector(state => getDivisionList(state, seasonId));
  const roleIsGlobal = useSelector(getRoleIsGlobal);

  const isLoaded = useSelector(state => getDivisionListIsLoaded(state, seasonId));

  const isLoading = useSelector(state => getDivisionListIsLoading(state, seasonId));

  const dispatch = useDispatch();

  const toggleSelected = useCallback(
    ({ isSelected, id, ...rest }) => {
      const action = isSelected ? "deselectDivision" : "selectDivision";
      const fn = actions[action];

      dispatch(fn({ id, ...rest }));
    },
    [dispatch]
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected
  };
}
