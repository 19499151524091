import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import TotalCount from '@/components/TotalCount';
import useFooter from '../Footer/useFooter';
import getTeamNameRenderer from "./components/TeamNameRenderer";
import { useList } from './useList';
import usePagination from '../Pagination/usePagination';
import { useFilter } from '../Filter/useFilter';

const PAGE_SIZE = 25;

function List({ seasonId, seasonTitle, exportTriggered }) {
  const { scheduledGames, selected, setSelected, csvExportParams, bigExport } = useList({ seasonId, seasonTitle });
  const { meta, setMeta} = usePagination(seasonId);
  const { clearSelections, selectAllGames, setSelectAllGames } = useFooter({ seasonId });
  const filter = useFilter({ seasonId });

  const [gridApi, setGridApi] = useState(null);
  const totalCount = (meta && meta.filteredCount) || 0; 

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  // 780px was chosen becuase the Ipad(Largest Mobile Screen) width is 768px
  const isMobile = window.innerWidth < 780 ? true : false;

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi])

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode(n => {
        if (selected.includes(n.id)) {
          if (!n.isSelected()) {
            n.setSelected(true);
          } 
        } else {
          if (n.isSelected()) {
            n.setSelected(false);
          }
        }
      });

      if(selected.length === 0){
        setSelectAllGames(false);
      }
    }
  }, [selected]);

  const handleSelectionChanged = () => {
    if (gridApi) {
      setSelected(gridApi.getSelectedRows().map(row => row.id));
    }
  }

  const dateComparator = (a, b) => {
    if (a == b) return 0;
    return (Date.parse(a) > Date.parse(b)) ? 1 : -1;
  };

  const timeComparator = (a, b) => {
    if (a == b) return 0;
    return (Date.parse(`01/01/2001 ${a}`) > Date.parse(`01/01/2001 ${b}`)) ? 1 : -1;
  };

  const selectAll = () => {
    if(selectAllGames){
      setSelectAllGames(false)
    }else{
      setSelectAllGames(true);
    }
  }

  const clear = () => {
    selectAll();
    clearSelections();
  }

  useEffect(() => {
    if (gridApi && exportTriggered) {
      if(selected.length >= 1){
        gridApi.exportDataAsCsv(csvExportParams);
      } else {
        bigExport();
      }
    }
  }, [exportTriggered])
  
  return (
    <Fragment>
      <br/>
        <TotalCount plural="games" singular="game" totalCount={totalCount}/>
        {selected.length >= 20 && 
          <div className="selectAllGames ag-theme-alpine ag-gs-theme pb-3 pt-3">
            
            { !selectAllGames && <>
              
              <span>All <strong>{selected.length}</strong> games on this page are selected. </span>
              { filter.isFiltered || <button className="ml-2" onClick={selectAll}>Select all <strong>{totalCount}</strong> games</button> }

            </> || <>
              
              <span>All <strong>{totalCount}</strong> scheduled games are selected. </span>
              <button className="ml-2" onClick={clear}>Clear selection</button>
              
            </>}

          </div>
        }
       
      <div className="ag-theme-alpine ag-gs-theme mb-3">
        <AgGridReact
          frameworkComponents={{
            teamNameRenderer: getTeamNameRenderer(seasonId)
          }}
          defaultColDef={{
            resizable: true,
            wrapText: true,
            autoHeight: true,
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn,
          }}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          rowData={scheduledGames}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          suppressCellSelection={true}
          onSelectionChanged={handleSelectionChanged}
          minrowHeight={44}
          enableCellTextSelection={true}
          getRowNodeId={data => data.id}
        >
          <AgGridColumn headerName="ID" field="id" hide={true} />
          <AgGridColumn headerName="Visiting Team" field="visitorTeam" lockPosition={isMobile} suppressSizeToFit={true} cellRenderer="teamNameRenderer" />
          <AgGridColumn headerName="Home Team" field="homeTeam" lockPosition={isMobile} suppressSizeToFit={true} cellRenderer="teamNameRenderer" />
          <AgGridColumn headerName="Visitor Division" field="visitorDivision" hide={true} />
          <AgGridColumn headerName="Home Division" field="homeDivision" hide={true} />
          <AgGridColumn headerName="Divisions" field="divisions" suppressSizeToFit={true} lockPosition={isMobile} />
          <AgGridColumn headerName="Date" field="scheduledDate"  wrapText={false} minWidth={100} lockPosition={isMobile} comparator={dateComparator} />
          <AgGridColumn headerName="Start Time" field="scheduledTime" minWidth={130} lockPosition={isMobile} comparator={timeComparator} />
          <AgGridColumn headerName="Number" field="number" minWidth={100} lockPosition={isMobile} />
          <AgGridColumn headerName="Game Type" field="gameType" minWidth={130} lockPosition={isMobile} />
          <AgGridColumn headerName="Location" field="location"  suppressSizeToFit={true} minWidth={100} lockPosition={isMobile} />
          <AgGridColumn headerName="Scorekeeper Name" field="scorekeeperName" hide={true} />
          <AgGridColumn headerName="Scorekeeper Phone" field="scorekeeperPhone" hide={true} />
          <AgGridColumn headerName="Broadcast Provider" field="broadcastProvider" hide={true} />
        </AgGridReact>
      </div>
    </Fragment>
  );
}

function isFirstColumn(params) {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
}

List.propTypes = {
  seasonId: PropTypes.string.isRequired
};

export default List;