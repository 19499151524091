import { createActions } from "redux-actions";

const { roleForm: actions } = createActions({
  ROLE_FORM: {
    SET_TITLE: undefined,
    SET_GLOBAL: undefined,
    SELECT_ASSOCIATION: undefined,
    DESELECT_ASSOCIATION: undefined,
    SELECT_LEAGUE: undefined,
    DESELECT_LEAGUE: undefined,
    SELECT_SEASON: undefined,
    DESELECT_SEASON: undefined,
    SELECT_DIVISION: undefined,
    DESELECT_DIVISION: undefined,
    SELECT_TEAM: undefined,
    DESELECT_TEAM: undefined,
    RESET: undefined
  }
});

export default actions;
