import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import Penalty from "./components/Penalty";

class PenaltiesList extends PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    totalMinutes: PropTypes.number,
    penalties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        period: PropTypes.string.isRequired,
        timeOff: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    caption: "Penalties",
    totalMinutes: 0,
    penalties: []
  };

  constructor(props) {
    super();
    this.state = {
      width: 0,
      columnLabels: {
        period: "P.",
        number: "N.",
      },
    };
  }

  checkDimensions = () => {
    const width = document.body.clientWidth;
    this.setState((prev) => {
      if (prev.width === width) {
        return prev;
      } else {
        return {
          ...prev,
          width,
          columnLabels: {
            period: width < 400 ? "P." : "Per.",
            number: width < 400 ? "#" : "No.",
          },
        };
      }
    });
  };

  componentDidMount() {
    this.checkDimensions();
    window.addEventListener('resize', this.checkDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkDimensions);
  }

  render() {
    const { caption, totalMinutes, penalties } = this.props;

    return (
      <Table striped borderless size="sm" className="layout-auto">
        <thead>
          <tr>
            <th scope="colgroup" colSpan="8">
              {caption}
            </th>
          </tr>
          <tr>
            <th scope="col">{this.state.columnLabels.period}</th>
            <th scope="col">{this.state.columnLabels.number}</th>
            <th scope="col">Min</th>
            <th scope="col">Code</th>
            <th scope="col">Off</th>
            <th scope="col">Start</th>
            <th scope="col">On</th>
            <th scope="col">SB</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td colSpan="8">Total Penalty Minutes: {totalMinutes}</td>
          </tr>
        </tfoot>
        <tbody>
          {penalties.map(penalty => (
            <Penalty key={penalty.id} {...penalty} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default PenaltiesList;
