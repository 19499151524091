import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";

import actions from "./actions";

import { associationList, leagueList, seasonList, divisionList, teamList } from "./reducers";
import { initRoutine } from "./routines";

const roleIsGlobal = handleActions(
  {
    [actions.setGlobal]: (state, { payload: { isChecked } }) => isChecked,
    [initRoutine.TRIGGER]: (state, { payload: initRole }) => {
      if (initRole && initRole.level && initRole.level.id === "*" && initRole.level.type === "") {
        return true;
      } else {
        return false;
      }
    },
    [actions.reset]: () => false,
  },
  false
);

const associationId = handleActions(
  {
    [actions.selectAssociation]: (state, { payload: { id } }) => id,
    [combineActions(actions.deselectAssociation, actions.reset)]: () => ""
  },
  ""
);

const leagueId = handleActions(
  {
    [actions.selectLeague]: (state, { payload: { id } }) => id,
    [combineActions(
      actions.selectAssociation,
      actions.deselectAssociation,
      actions.deselectLeague,
      actions.reset
    )]: () => ""
  },
  ""
);

const seasonId = handleActions(
  {
    [actions.selectSeason]: (state, { payload: { id } }) => id,
    [combineActions(
      actions.selectAssociation,
      actions.deselectAssociation,
      actions.selectLeague,
      actions.deselectLeague,
      actions.deselectSeason,
      actions.reset
    )]: () => ""
  },
  ""
);

const divisionId = handleActions(
  {
    [actions.selectDivision]: (state, { payload: { id } }) => id,
    [combineActions(
      actions.selectAssociation,
      actions.deselectAssociation,
      actions.selectLeague,
      actions.deselectLeague,
      actions.selectSeason,
      actions.deselectSeason,
      actions.deselectDivision,
      actions.reset
    )]: () => ""
  },
  ""
);

const teamId = handleActions(
  {
    [actions.selectTeam]: (state, { payload: { id } }) => id,
    [combineActions(
      actions.selectAssociation,
      actions.deselectAssociation,
      actions.selectLeague,
      actions.deselectLeague,
      actions.selectSeason,
      actions.deselectSeason,
      actions.selectDivision,
      actions.deselectDivision,
      actions.deselectTeam,
      actions.reset
    )]: () => ""
  },
  ""
);

const title = handleActions(
  {
    [actions.setTitle]: (state, { payload: title }) => title,
    [initRoutine.TRIGGER]: (state, { payload: initRole = { title: "" } }) => initRole.title,
    [actions.reset]: () => ""
  },
  ""
);

const level = handleActions(
  {
    [actions.selectAssociation]: (state, { payload: { id } }) => ({
      type: "associations",
      id
    }),
    [actions.deselectAssociation]: (state, { payload: { id } }) => null,
    [actions.selectLeague]: (state, { payload: { id } }) => ({
      type: "leagues",
      id
    }),
    [actions.deselectLeague]: (state, { payload: { associationId } }) => ({
      type: "associations",
      id: associationId
    }),
    [actions.selectSeason]: (state, { payload: { id } }) => ({
      type: "seasons",
      id
    }),
    [actions.deselectSeason]: (state, { payload: { leagueId } }) => ({
      type: "leagues",
      id: leagueId
    }),
    [actions.selectDivision]: (state, { payload: { id } }) => ({
      type: "divisions",
      id
    }),
    [actions.deselectDivision]: (state, { payload: { seasonId } }) => ({
      type: "seasons",
      id: seasonId
    }),
    [actions.selectTeam]: (state, { payload: { id } }) => ({
      type: "teams",
      id
    }),
    [actions.deselectTeam]: (state, { payload: { divisionId } }) => ({
      type: "divisions",
      id: divisionId
    }),
    [actions.reset]: () => null
  },
  null
);

const isVisible = handleActions({ [initRoutine.TRIGGER]: () => true, [actions.reset]: () => false }, false);

const isInitializing = handleActions(
  {
    [initRoutine.REQUEST]: () => true,
    [initRoutine.FULFILL]: () => false
  },
  false
);

const initRole = handleActions(
  {
    [initRoutine.TRIGGER]: (state, { payload = null }) => payload,
    [actions.reset]: () => null
  },
  null
);

export default combineReducers({
  roleIsGlobal,
  associationId,
  leagueId,
  seasonId,
  divisionId,
  teamId,
  title,
  level,
  isVisible,
  initRole,
  isInitializing,
  associationList,
  leagueList,
  seasonList,
  divisionList,
  teamList
});
