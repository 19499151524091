import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Table } from "reactstrap";

import PageLoader from "@/components/PageLoader";
import PlayerTeam from "./components/PlayerTeam";
import NewTeamForm from "./components/NewTeamForm";
import EmptyListMessage from "@/components/EmptyListMessage";
import AbilityButton from "@/components/AbilityButton";

class PlayerTeamsList extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    teams: PropTypes.arrayOf(PropTypes.shape({})),
    displayTeamForm: PropTypes.bool,
    teamFormValues: PropTypes.shape({}),
    isTeamFormOperating: PropTypes.bool,
    playerTeamIds: PropTypes.arrayOf(PropTypes.string),
    changeTeamFormValues: PropTypes.func,
    changeTeamFormTeamId: PropTypes.func,
    getTeam: PropTypes.func,
    addPlayerToTeam: PropTypes.func
  };

  static defaultProps = {
    isLoaded: false,
    isLoading: false,
    isTeamFormOperating: false,
    displayTeamForm: false,
    teams: [],
    teamFormValues: {},
    playerTeamIds: [],
    changeTeamFormValues() {},
    changeTeamFormTeamId() {},
    toggleTeamFormDisplay() {},
    getTeam() {},
    addPlayerToTeam() {}
  };

  handleButtonClick = event => {
    event.preventDefault();

    this.props.toggleTeamFormDisplay();
  };

  componentWillUnmount() {
    const { displayTeamForm, toggleTeamFormDisplay } = this.props;

    if (displayTeamForm) {
      toggleTeamFormDisplay();
    }
  }

  handleTeamFormSubmit = ({ teamId, jersey: number, duty, position, affiliated }) => {
    const { seasonId, addPlayerToTeam, playerId, getTeam } = this.props;
    const team = getTeam(teamId);
    const roster = team.roster || { players: [], coaches: [] };

    // @TODO: allow to change status
    const rosterPlayer = {
      id: playerId,
      number,
      duty,
      position,
      affiliated,
      status: "playing"
    };

    const nextRoster = {
      ...roster,
      players: [...(roster.players || []), rosterPlayer]
    };

    const attributes = {
      title: team.title,
      externalId: team.externalId,
      roster: nextRoster,
      data: team.data
    };

    const payload = {
      seasonId,
      divisionId: team.division.id,
      teamId,
      attributes,
      playerId
    };

    addPlayerToTeam(payload);
  };

  render() {
    const {
      isLoading,
      isLoaded,
      teams,
      seasonId,
      playerId,
      changeTeamFormValues,
      changeTeamFormTeamId,
      teamFormValues,
      toggleTeamFormDisplay,
      displayTeamForm,
      isTeamFormOperating,
      playerTeamIds
    } = this.props;

    return (
      <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
        {teams.length > 0 ? (
          <Fragment>
            <Table striped borderless className="mb-3">
              <thead>
                <tr>
                  <th scope="col">Team</th>
                  <th scope="col">Jersey</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Position</th>
                  <th scope="col">Status</th>
                  <th scope="col">
                    <span className="visuallyhidden">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {teams.map(team => (
                  <PlayerTeam playerId={playerId} key={team.id} {...team} />
                ))}
              </tbody>
            </Table>

            {displayTeamForm ? (
              <NewTeamForm
                disallowTeamIds={playerTeamIds}
                changeValues={changeTeamFormValues}
                changeTeamId={changeTeamFormTeamId}
                values={teamFormValues}
                seasonId={seasonId}
                playerId={playerId}
                isOperating={isTeamFormOperating}
                onCancel={toggleTeamFormDisplay}
                onSubmit={this.handleTeamFormSubmit}
              />
            ) : (
              <AbilityButton
                subject={{ type: "players", id: playerId }}
                action="addToTeam"
                outline
                type="button"
                color="success"
                onClick={this.handleButtonClick}
              >
                Add player to another team
              </AbilityButton>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {displayTeamForm ? (
              <NewTeamForm
                disallowTeamIds={playerTeamIds}
                changeValues={changeTeamFormValues}
                changeTeamId={changeTeamFormTeamId}
                values={teamFormValues}
                seasonId={seasonId}
                playerId={playerId}
                isOperating={isTeamFormOperating}
                onCancel={toggleTeamFormDisplay}
                onSubmit={this.handleTeamFormSubmit}
              />
            ) : (
              <EmptyListMessage>
                <p>The player is not a member of any team.</p>

                <AbilityButton
                  subject={{ type: "players", id: playerId }}
                  action="addToTeam"
                  outline
                  type="button"
                  color="success"
                  onClick={this.handleButtonClick}
                >
                  Add player to team
                </AbilityButton>
              </EmptyListMessage>
            )}
          </Fragment>
        )}
      </PageLoader>
    );
  }
}

export default PlayerTeamsList;
