import { handleActions } from "redux-actions";

import { associationListLoadingRoutine as loadingRoutine } from "../routines";

const initialState = {
  isLoaded: false,
  isLoading: false,
  ids: [],
  data: {}
};

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload: { ids, associations } }) {
  const data = associations.reduce((result, { id, title }) => ({ ...result, [id]: { title } }), {});

  return {
    ...state,
    ids,
    data,
    isLoaded: true
  };
}

function reduceLoadingFailure(state) {
  return { ...state, isLoaded: false };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill
  },
  initialState
);
