import { all, takeEvery, takeLatest, put, select, actionChannel, take } from "redux-saga/effects";

import { buffers } from "redux-saga";

import { loadAssociations } from "@/lib/api/associations";
import { loadLeagues, loadLeague } from "@/lib/api/leagues";
import { loadSeasons, loadSeason } from "@/lib/api/seasons";
import { loadDivisions, loadDivision } from "@/lib/api/divisions";
import { loadTeams, loadTeam } from "@/lib/api/teams";

import { fetchList, fetchOne } from "@/redux/api/sagas";

import {
  associationListLoadingRoutine,
  leagueListLoadingRoutine,
  seasonListLoadingRoutine,
  divisionListLoadingRoutine,
  teamListLoadingRoutine,
  initRoutine
} from "./routines";

import actions from "./actions";

import {
  getLeagueListIsLoaded,
  getSeasonListIsLoaded,
  getDivisionListIsLoaded,
  getAssociationId,
  getLeagueListIsLoading,
  getSeasonListIsLoading,
  getDivisionListIsLoading,
  getTeamListIsLoading,
  getTeamListIsLoaded
} from "./selectors";

function* associationListLoadingSaga() {
  yield put(associationListLoadingRoutine.request());

  try {
    const { ids, associations } = yield fetchList("associations", loadAssociations);

    yield put(associationListLoadingRoutine.success({ ids, associations }));
  } catch (error) {
    yield put(associationListLoadingRoutine.failure({ error }));
  } finally {
    yield put(associationListLoadingRoutine.fulfill());
  }
}

function* leagueListLoadingSaga({ payload: { id: associationId } }) {
  const isLoaded = yield select(getLeagueListIsLoaded, associationId);
  const isLoading = yield select(getLeagueListIsLoading, associationId);

  if (isLoaded || isLoading || associationId === "") {
    yield put(leagueListLoadingRoutine.fulfill({ associationId }));
    return;
  }

  yield put(leagueListLoadingRoutine.request({ associationId }));

  try {
    const { ids, leagues } = yield fetchList("leagues", loadLeagues, {
      associationId
    });

    yield put(leagueListLoadingRoutine.success({ associationId, ids, leagues }));
  } catch (error) {
    yield put(leagueListLoadingRoutine.failure({ error, associationId }));
  } finally {
    yield put(leagueListLoadingRoutine.fulfill({ associationId }));
  }
}

function* seasonListLoadingSaga({ payload: { id: leagueId } }) {
  const associationId = yield select(getAssociationId);
  const isLoaded = yield select(getSeasonListIsLoaded, leagueId);
  const isLoading = yield select(getSeasonListIsLoading, leagueId);

  if (isLoaded || isLoading || leagueId === "") {
    yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
    return;
  }

  yield put(seasonListLoadingRoutine.request({ leagueId }));

  try {
    const { ids, seasons } = yield fetchList("seasons", loadSeasons, {
      associationId,
      leagueId
    });

    yield put(seasonListLoadingRoutine.success({ leagueId, ids, seasons }));
  } catch (error) {
    yield put(seasonListLoadingRoutine.failure({ error, leagueId }));
  } finally {
    yield put(seasonListLoadingRoutine.fulfill({ leagueId }));
  }
}

function* divisionListLoadingSaga({ payload: { id: seasonId } }) {
  const isLoaded = yield select(getDivisionListIsLoaded, seasonId);
  const isLoading = yield select(getDivisionListIsLoading, seasonId);

  if (isLoaded || isLoading || seasonId === "") {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
    return;
  }

  yield put(divisionListLoadingRoutine.request({ seasonId }));

  try {
    const { ids, divisions } = yield fetchList("divisions", loadDivisions, {
      seasonId
    });

    yield put(divisionListLoadingRoutine.success({ seasonId, ids, divisions }));
  } catch (error) {
    yield put(divisionListLoadingRoutine.failure({ error, seasonId }));
  } finally {
    yield put(divisionListLoadingRoutine.fulfill({ seasonId }));
  }
}

function* teamListLoadingSaga({ payload: { id: divisionId } }) {
  const isLoaded = yield select(getTeamListIsLoaded, divisionId);
  const isLoading = yield select(getTeamListIsLoading, divisionId);

  if (isLoaded || isLoading || divisionId === "") {
    yield put(teamListLoadingRoutine.fulfill({ divisionId }));
    return;
  }

  yield put(teamListLoadingRoutine.request({ divisionId }));

  try {
    const { ids, teams } = yield fetchList("teams", loadTeams, {
      divisionId
    });

    yield put(teamListLoadingRoutine.success({ divisionId, ids, teams }));
  } catch (error) {
    yield put(teamListLoadingRoutine.failure({ error, divisionId }));
  } finally {
    yield put(teamListLoadingRoutine.fulfill({ divisionId }));
  }
}

function* initializeAssociationLevelRoleSaga(associationId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.selectAssociation({ id: associationId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeLeagueLevelRoleSaga(leagueId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const [league] = yield fetchOne({ type: "leagues", id: leagueId }, loadLeague);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.selectAssociation({ id: league.association.id })),
    put(actions.selectLeague({ id: leagueId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeSeasonLevelRoleSaga(seasonId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const [season] = yield fetchOne({ type: "seasons", id: seasonId }, loadSeason);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.selectAssociation({ id: season.association.id })),
    put(actions.selectLeague({ id: season.league.id })),
    put(actions.selectSeason({ id: seasonId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeDivisionLevelRoleSaga(divisionId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const teamListLoadingRoutineFulfillChannel = yield actionChannel(teamListLoadingRoutine.FULFILL, buffers.dropping(1));

  const [division] = yield fetchOne({ type: "divisions", id: divisionId }, loadDivision);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.selectAssociation({ id: division.association.id })),
    put(actions.selectLeague({ id: division.league.id })),
    put(actions.selectSeason({ id: division.season.id })),
    put(actions.selectDivision({ id: divisionId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel),
    take(teamListLoadingRoutineFulfillChannel)
  ]);
}

function* initializeTeamLevelRoleSaga(teamId) {
  const associationListLoadingFulfillChannel = yield actionChannel(
    associationListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const leagueListLoadingRoutineFulfillChannel = yield actionChannel(
    leagueListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const seasonListLoadingRoutineFulfillChannel = yield actionChannel(
    seasonListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const divisionListLoadingRoutineFulfillChannel = yield actionChannel(
    divisionListLoadingRoutine.FULFILL,
    buffers.dropping(1)
  );

  const teamListLoadingRoutineFulfillChannel = yield actionChannel(teamListLoadingRoutine.FULFILL, buffers.dropping(1));

  const [team] = yield fetchOne({ type: "teams", id: teamId }, loadTeam);

  yield all([
    put(associationListLoadingRoutine()),
    put(actions.selectAssociation({ id: team.association.id })),
    put(actions.selectLeague({ id: team.league.id })),
    put(actions.selectSeason({ id: team.season.id })),
    put(actions.selectDivision({ id: team.division.id })),
    put(actions.selectTeam({ id: teamId })),
    take(associationListLoadingFulfillChannel),
    take(leagueListLoadingRoutineFulfillChannel),
    take(seasonListLoadingRoutineFulfillChannel),
    take(divisionListLoadingRoutineFulfillChannel),
    take(teamListLoadingRoutineFulfillChannel)
  ]);
}

function* initializingSaga({ payload: initialRole = null }) {
  yield put(initRoutine.request());

  if (initialRole) {
    const { id, type } = initialRole.level;

    if (id === "*" && type === "") {
      // global, nothing to load
      yield put(initRoutine.success());
      yield put(initRoutine.fulfill());
      return;
    }

    switch (type) {
      case "associations":
        yield initializeAssociationLevelRoleSaga(id);
        break;
      case "leagues":
        yield initializeLeagueLevelRoleSaga(id);
        break;
      case "seasons":
        yield initializeSeasonLevelRoleSaga(id);
        break;
      case "divisions":
        yield initializeDivisionLevelRoleSaga(id);
        break;
      case "teams":
        yield initializeTeamLevelRoleSaga(id);
        break;
      default:
        throw new Error(`Unable to initialize role with level ${type}`);
    }
  }
  yield put(initRoutine.success());

  yield put(initRoutine.fulfill());
}

export function* roleFormFlow() {
  yield all([
    takeLatest(associationListLoadingRoutine, associationListLoadingSaga),
    takeEvery(actions.selectAssociation, leagueListLoadingSaga),
    takeEvery(actions.selectLeague, seasonListLoadingSaga),
    takeEvery(actions.selectSeason, divisionListLoadingSaga),
    takeEvery(actions.selectDivision, teamListLoadingSaga),
    takeLatest(initRoutine, initializingSaga)
  ]);
}
