import { useState, useCallback } from "react";

import { read, validate } from "@/lib/core/readPenaltyCodesCSV";

export function usePenaltyCodesImporter({ close, penaltyCodes, changePenaltyCodes }) {
  const [step, setStep] = useState("select-file");
  const [parseError, setParseError] = useState(null);
  const [invalidRows, setInvalidRows] = useState([]);
  const [importablePenaltyCodes, setImportablePenaltyCodes] = useState([]);

  const parseFile = useCallback(
    async function(file) {
      const { rows, error } = await read(file);

      if (error) {
        setParseError(error);
        return;
      }

      const invalidRows = validate(rows);

      if (invalidRows.length > 0) {
        setInvalidRows(invalidRows);
        setStep("validation-failure");
        return;
      }

      setImportablePenaltyCodes(rows.map(({ code, label, penaltyClass, duration }) => ({ code, label, penaltyClass, duration })));

      setStep("confirmation");
    },
    [setParseError, setStep, setImportablePenaltyCodes]
  );

  const reset = useCallback(
    () => {
      setStep("select-file");
      setParseError(null);
      setInvalidRows([]);
      setImportablePenaltyCodes([]);
    },
    [setStep, setParseError, setInvalidRows]
  );

  const importPenaltyCodes = useCallback(
    () => {
      const nextPenaltyCodes = [ ...importablePenaltyCodes ];
      changePenaltyCodes(nextPenaltyCodes);
      reset();
      close();
    },
    [changePenaltyCodes, importablePenaltyCodes, penaltyCodes, reset, close]
  );

  return {
    step,
    parseFile,
    parseError,
    invalidRows,
    reset,
    importablePenaltyCodes,
    importPenaltyCodes
  };
}
