import _sortBy from "lodash/sortBy";

import { getResources, getResource } from "@/redux/resources/selectors";

import { playerAffiliated, playerDuty, playerPosition, coachPosition } from "@/utils/codeToTitle";

import { getTokenCanReadTeam, getTokenCanEditTeam } from "@/redux/token/selectors";

const rootSelector = state => state.teams;
const listSelector = state => rootSelector(state).list;
const bigListSelector = state => rootSelector(state).bigList;
const bigInvitationListSelector = state => rootSelector(state).invitationList;
const newSelector = state => rootSelector(state).new;
const currentSelector = state => rootSelector(state).current;

const selectTeams = state => {
  return isTeamListLoaded(state)
    ? getResources(state, "teams", listSelector(state).ids).map(
        ({ id, title, logoUrl, division, season, players = [], coaches = [], invitations = [], data = {} }) => ({
          id,
          title,
          division,
          logoUrl,
          season,
          invitations,
          numPlayers: players.length,
          numCoaches: coaches.length,
          viewUrl: `/seasons/${season.id}/teams/${id}/roster`,
          viewable: getTokenCanReadTeam(state, id),
          editable: getTokenCanEditTeam(state, id),
          data: data
        })
      )
    : [];
};

export const isBigTeamListLoaded = state => bigListSelector(state).isLoaded;
export const getBigListTeams = state => {
  return isBigTeamListLoaded(state)
    ? getResources(state, "teams", bigListSelector(state).ids).map(
        ({ id, title, division, season, players = [], coaches = [], invitations = [] }) => ({
          id,
          title,
          division,
          season,
          invitations,
          numPlayers: players.length,
          numCoaches: coaches.length,
        })
      )
    : [];
};

export const isBigTeamInvitationListLoaded = state => bigInvitationListSelector(state).isLoaded;
export const getBigInvitationListTeams = state => {
  return isBigTeamInvitationListLoaded(state)
    ? getResources(state, "teams", bigInvitationListSelector(state).ids).map(
        ({ id, title, division, season, invitations = [] }) => ({
          id,
          title,
          division,
          season,
          invitations,
        })
      )
    : [];
};

export const isTeamListLoaded = state => listSelector(state).isLoaded;
export const isTeamListLoading = state => listSelector(state).isLoading;
export const isTeamListForbidden = state => listSelector(state).isForbidden;
export const getTeamList = state => {
  if (isTeamListLoaded(state)) {
    return getResources(state, "teams", listSelector(state).ids);
  }
};

export const isTeamCreating = state => newSelector(state).isCreating;

export const isTeamCreated = state => newSelector(state).isCreated;

export const getCreatedTeamName = state => newSelector(state).createdName;

export const getTeamCreationErrors = state => newSelector(state).errors;

export const isCurrentTeamLoaded = state => currentSelector(state).isLoaded;

export const isCurrentTeamOperating = state => {
  const operation = currentSelector(state).operation;

  return typeof operation === "string" && operation.length > 0;
};

export const isCurrentTeamUpdating = state => currentSelector(state).operation === "updating";

export const isCurrentTeamDeleting = state => currentSelector(state).operation === "deleting";

export const isCurrentTeamLoading = state => currentSelector(state).operation === "loading";

export const isCurrentTeamReady = state => isCurrentTeamLoaded(state) && !currentSelector(state).isDeleted;

export const isCurrentTeamForbidden = state => currentSelector(state).isForbidden;

export const isCurrentTeamArchived = state => currentSelector(state).isArchived;

export const getCurrentTeam = state => {
  const { id, title, roster, externalId } = currentSelector(state);
  const association = getCurrentTeamAssociation(state);
  const league = getCurrentTeamLeague(state);
  const season = getCurrentTeamSeason(state);
  const division = getCurrentTeamDivision(state);
  const data = getCurrentTeamData(state);
  const logoUrl = getCurrentTeamLogoUrl(state);

  return {
    id,
    title,
    externalId,
    roster,
    logoUrl,
    association,
    league,
    season,
    division,
    data
  };
};

export const getCurrentTeamTitle = state => {
  const team = getCurrentTeam(state);

  if (team) {
    return team.title;
  }
};

export const getCurrentTeamAssociation = state => currentSelector(state).association;

export const getCurrentTeamLeague = state => currentSelector(state).league;
export const getCurrentTeamSeason = state => currentSelector(state).season;
export const getCurrentTeamDivision = state => currentSelector(state).division;
export const getCurrentTeamData = state => currentSelector(state).data;
export const getCurrentTeamLogoUrl = state => currentSelector(state).logoUrl;

export const getCurrentTeamBreadcrumbs = state => {
  const association = getCurrentTeamAssociation(state);
  const league = getCurrentTeamLeague(state);
  const season = getCurrentTeamSeason(state);
  const division = getCurrentTeamDivision(state);
  const team = getCurrentTeam(state);

  if (isCurrentTeamLoaded(state)) {
    return [
      {
        title: "Associations",
        url: "/associations"
      },
      {
        title: association.title,
        url: `/associations/${association.id}`
      },
      {
        title: league.title,
        url: `/leagues/${league.id}`
      },
      {
        title: season.title,
        url: `/seasons/${season.id}`
      },
      {
        title: division.title,
        url: `/seasons/${season.id}/divisions/${division.id}/teams`
      },
      {
        url: `/seasons/${season.id}/teams/${team.id}/roster`,
        title: team.title
      }
    ];
  }
};

export const getCurrentTeamPlayersRoster = state => {
  if (isCurrentTeamLoaded(state)) {
    return getCurrentTeam(state).roster.players || [];
  }
};

export const getCurrentTeamCoachesRoster = state => {
  if (isCurrentTeamLoaded(state)) {
    return getCurrentTeam(state).roster.coaches || [];
  }
};

export const getCurrentDivisionStats = state => currentSelector(state).stats;

export const selectPlayersRosterList = state => {
  if (!isCurrentTeamReady(state)) {
    return [];
  }

  const team = getCurrentTeam(state);

  const players = team.players || [];
  const roster = team.roster.players || [];

  const teamPlayers = roster.map(({ id, number, duty, position, affiliated }) => {
    const player = players.find(item => item.id === id);
    const { firstName, lastName, externalId } = player;

    return {
      id,
      firstName,
      lastName,
      externalId,
      jersey: number,
      duty: playerDuty(duty),
      position: playerPosition(position),
      status: playerAffiliated(affiliated)
    };
  });

  return _sortBy(teamPlayers, ["lastName", "firstName"]);
};

export const selectPlayersRosterListTotalCount = state => selectPlayersRosterList(state).length;

export const selectPlayersRosterListFilteredCount = state => selectPlayersRosterList(state).length;

export const selectCoachesRosterList = state => {
  if (!isCurrentTeamReady(state)) {
    return [];
  }

  const team = getCurrentTeam(state);
  const coaches = team.coaches || [];
  const roster = team.roster.coaches || [];

  const teamCoaches = roster.map(({ id, position }) => {
    const coach = coaches.find(item => item.id === id);
    const { firstName, lastName, externalId } = coach || {};

    return {
      id,
      firstName,
      lastName,
      externalId,
      position: coachPosition(position)
    };
  });

  return _sortBy(teamCoaches, ["lastName", "firstName"]);
};

export const selectCoachesRosterListTotalCount = state => selectCoachesRosterList(state).length;

export const selectCoachesRosterListFilteredCount = state => selectCoachesRosterList(state).length;

export const getTeam = (state, id) => getResource(state, "teams", id);

export const getTeamListTotalCount = state => listSelector(state).totalCount;

export const getTeamsLocking = state => listSelector(state).isLocking;

export const getSeasonTeamList = state =>
  selectTeams(state).map(({ id, season, ...rest }) => ({
    ...rest,
    id,
    season,
    editUrl: `/seasons/${season.id}/teams/${id}/edit`
  }));

export const getDivisionTeamList = state => {
  return selectTeams(state).map(({ id, season, division, ...rest }) => ({
    ...rest,
    id,
    season,
    division,
    editUrl: `/seasons/${season.id}/divisions/${division.id}/teams/${id}/edit`
  }));
};
