import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "@/components/InfiniteScroll";
import TotalCount from "@/components/TotalCount";

import CoachPenaltyReportCoachesListSearchBar from "./SearchBar";
import CoachPenaltyReportCoachesListTable from "./Table";

class List extends PureComponent {
  static propTypes = {
    coaches: PropTypes.arrayOf(PropTypes.shape({})),
    totalCount: PropTypes.number,
    filteredCount: PropTypes.number,
    hasMore: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAppending: PropTypes.bool,
    loadMoreCoaches: PropTypes.func
  };

  static defaultProps = {
    coaches: [],
    totalCount: 0,
    filteredCount: 0,
    hasMore: false,
    isLoaded: false,
    isLoading: false,
    isAppending: false,
    loadMoreCoaches() {}
  };

  render() {
    const {
      coaches,
      loadMoreCoaches,
      hasMore,
      isLoaded,
      isLoading,
      isAppending,
      totalCount,
      filteredCount
    } = this.props;

    return (
      <Fragment>
        <CoachPenaltyReportCoachesListSearchBar />

        <InfiniteScroll
          dataLength={coaches.length}
          loadList={loadMoreCoaches}
          hasMore={hasMore}
          isLoaded={isLoaded}
          isLoading={isLoading}
          isAppending={isAppending}
          emptyMessage="No bench staff found"
          endMessage="You’ve reached the end of the bench staff list."
        >
          <TotalCount plural="coaches" singular="coach" totalCount={totalCount} filteredCount={filteredCount} />

          <CoachPenaltyReportCoachesListTable coaches={coaches} />
        </InfiniteScroll>
      </Fragment>
    );
  }
}

export default List;
