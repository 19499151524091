import { useCallback } from "react";

export default function useSetScheduledStartTimeValue(dispatchSetValue) {
  const setTimeValue = useCallback(
    time => {
      if (!time || typeof time === "string") {
        return;
      }

      dispatchSetValue(time.format("HH:mm"));
    },
    [dispatchSetValue]
  );

  return setTimeValue;
}
