import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";

import {
  getIsLoaded,
  getIsLoading,
  getIsForbidden,
  getIsFailed,
  getList,
  getTotalCount,
  getDivisionsLocking,
  lockTeamsRoutine
} from "@/redux/divisionsList";

export default function useDivisionsList() {
  const dispatch = useDispatch();

  const isListLoaded = useSelector(getIsLoaded);
  const isListLoading = useSelector(getIsLoading);
  const isListForbidden = useSelector(getIsForbidden);
  const isListFailed = useSelector(getIsFailed);
  const divisions = useSelector(getList);
  const totalCount = useSelector(getTotalCount);
  const divisionsLocking = useSelector(getDivisionsLocking);

  const triggerTeamsLocking = useCallback(
    ({ lock, divisionId }) =>
      dispatch(
        lockTeamsRoutine({
          lock,
          divisionId
        })
      ),
    [dispatch]
  );

  return {
    isListLoaded,
    isListLoading,
    isListForbidden,
    isListFailed,
    divisions,
    totalCount,
    divisionsLocking,
    triggerTeamsLocking
  };
}
