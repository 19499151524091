import React from "react";
import PropTypes from "prop-types";
import { Button, ModalBody, ModalFooter } from "reactstrap";

function pluralized(num) {
  if (num === 1) return "penalty";
  return "penalties";
}
function Confirmation({ numAdd, numDelete, onConfirm }) {
  return (
    <React.Fragment>
      <ModalBody>
          <p>
            {numDelete} {pluralized(numDelete)} will be removed and {numAdd} {pluralized(numAdd)} will be imported.
          </p>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button className="btn btn-success" type="button" onClick={onConfirm}>
          Import Penalty Codes
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
}

Confirmation.propTypes = {
  numAdd: PropTypes.number.isRequired,
  numDelete: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default Confirmation;
