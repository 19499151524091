import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";
import SeasonIpadKeysList from "@/components/SeasonIpadKeysList";
import SeasonIpadKeysManager from "@/components/SeasonIpadKeysManager";

import AbilityButton from "@/components/AbilityButton";

class IpadKeysIndex extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    seasonIsLoading: PropTypes.bool,
    seasonIsLoaded: PropTypes.bool,
    seasonIsForbidden: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isForbidden: PropTypes.bool,
    season: PropTypes.shape({
      title: PropTypes.string.isRequired
    }),
    breadcrumbs: BreadcrumbsProps,
    ipadKeys: PropTypes.arrayOf(PropTypes.shape({})),
    loadKeys: PropTypes.func,
    openKeysManager: PropTypes.func
  };

  static defaultProps = {
    seasonIsLoading: false,
    seasonIsLoaded: false,
    seasonIsForbidden: false,
    associationIsLoaded: false,
    isForbidden: false,
    season: null,
    breadcrumbs: null,
    ipadKeys: [],
    loadKeys() {},
    openKeysManager() {}
  };

  componentDidMount() {
    this.props.loadKeys();
  }

  openKeysManager = () => {
    const { openKeysManager, season, seasonIsLoaded } = this.props;

    if (seasonIsLoaded) {
      openKeysManager({
        associationId: season.association.id,
        leagueId: season.league.id,
        seasonId: season.id,
        seasonTitle: season.title
      });
    }
  };

  render() {
    const {
      seasonId,
      seasonIsLoading,
      seasonIsLoaded,
      seasonIsForbidden,
      isLoading,
      isLoaded,
      isForbidden,
      season,
      breadcrumbs,
      ipadKeys
    } = this.props;

    return (
      <ProtectedLayout condition={!seasonIsForbidden && !isForbidden}>
        {seasonIsLoaded ? (
          <Fragment>
            <Head title={`iPad Keys for ${season.title}`} />
            <Breadcrumbs items={breadcrumbs} />
            <TitleBar title={`iPad Keys for ${season.title}`}>
              <AbilityButton
                subject="ipadKeys"
                action="update"
                color="success"
                size="sm"
                onClick={this.openKeysManager}
              >
                + Add Season to Another Key
              </AbilityButton>

              <AbilityButton
                outline
                subject="ipadKeys"
                action="create"
                color="success"
                size="sm"
                tag={Link}
                to={`/seasons/${seasonId}/ipad-keys/new`}
              >
                Create New Key
              </AbilityButton>
            </TitleBar>

            {isLoaded ? (
              <SeasonIpadKeysList ipadKeys={ipadKeys} seasonId={seasonId} />
            ) : (
              <PageLoader isLoading={isLoading} isLoaded={isLoaded} />
            )}

            <SeasonIpadKeysManager associationId={season.association.id} />
          </Fragment>
        ) : (
          <PageLoader isLoading={seasonIsLoading} />
        )}
      </ProtectedLayout>
    );
  }
}

export default IpadKeysIndex;
