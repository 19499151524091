import React from "react";
import PropTypes from "prop-types";

import { useGameFlaggingSettings, usePenaltyPicker } from "./hooks";

import FormSection from "@/components/FormSection";

import FlaggingCriteria from "./FlaggingCriteria";
import PenaltyPicker from "./PenaltyPicker";

function GameFlaggingSettingsFieldset({ penaltyCodes, value, onChange }) {
  const { criteria, penalties } = useGameFlaggingSettings({
    value,
    onChange
  });

  const penaltyPicker = usePenaltyPicker({ ...penalties, penaltyCodes });

  return (
    <FormSection title="Game Flagging">
      <FlaggingCriteria
        {...criteria}
        openPenaltyPicker={penaltyPicker.open}
        countSelectedPenalties={penaltyPicker.totalCountSelected}
        countMissingPenalties={penaltyPicker.totalCountMissing}
      />
      <PenaltyPicker {...penaltyPicker} />
    </FormSection>
  );
}

GameFlaggingSettingsFieldset.propTypes = {
  penaltyCodes: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default GameFlaggingSettingsFieldset;
