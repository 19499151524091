import React from "react";
import PropTypes from "prop-types";

import AbilityLink from "@/components/AbilityLink";

function GameLink({ seasonId, id, title }) {
  return (
    <AbilityLink
      subject={{ type: "games", id }}
      action="read"
      to={`/seasons/${seasonId}/games/completed/${id}`}
      fallback={title}
    >
      {title}
    </AbilityLink>
  );
}

GameLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired
};

export default GameLink;
