import React from "react";
import PropTypes from "prop-types";

import TitleBar from "@/components/TitleBar";

import { useFairPlayPoints } from "./hooks";

import { CustomInput, FormGroup, Input, Label, Row, Col } from "reactstrap";

import { useDashboardFormNamespaceContext } from "@/components/DashboardForm";

import "./FairPlayFormFields.scss";

function FairPlayFormFields({ value, onChange }) {
  const namespace = useDashboardFormNamespaceContext();

  const { isEnabled, maxPenaltyMinutes, pointValue } = useFairPlayPoints({
    value,
    onChange
  });

  return (
    <div className="fair-play-system">
      <TitleBar titleClassName="fair-play-system__title" title="Fair Play" titleTag="h4" />
      <FormGroup>
        <CustomInput
          id={`${namespace}__fair-play-system__toggle`}
          type="switch"
          label={<span>Allow scorekeepers to award fair play points for each game</span>}
          checked={isEnabled.value}
          onChange={isEnabled.onChange}
        />
        {isEnabled.value && (
          <Row className="mt-3">
            <Col xs="6">
              <Label for="fairPlayPointValue">Fair Play Point Value</Label>
              <Input
                type="text"
                id={`fairPlayPointValue`}
                value={pointValue.value}
                bsSize="sm"
                onChange={pointValue.onChange}
              />
            </Col>
            <Col xs="6">
              <Label for="maxFairPlayMinutes">Max Penalty Mins. Per Game</Label>
              <Input
                type="text"
                id={`maxFairPlayMinutes`}
                value={maxPenaltyMinutes.value}
                bsSize="sm"
                onChange={maxPenaltyMinutes.onChange}
              />
            </Col>
          </Row>
        )}
      </FormGroup>
    </div>
  );
}

FairPlayFormFields.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default FairPlayFormFields;
