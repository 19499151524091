import React, { useRef, Fragment } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentDivisionContext from "@/hooks/useCurrentDivisionContext";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import AbilityButton from "@/components/AbilityButton";
import { Can } from "@/components/Ability";
import Spinner from "@/components/Spinner";
import { ExternalImportButton } from "@/components/ExternalImportButton";
import { SingleActionModal } from "@/components-16.8/SingleActionModal";

import TeamList, { useTeamsListLoading, useTeamsListClearing, useDivisionTeamsList } from "@/components/TeamsList";
import CopyTeamsModal, { useCopyTeamsModalOpen } from "@/components/CopyTeamsModal";

import padlock from "@/components-16.8/LockImg/padlock.png";
import openPadlock from "@/components-16.8/LockImg/open-padlock.png";

import "./style.scss";

const PAGE_SIZE = 25;

function TeamsIndex({ isRosterLocking, lockRosters }) {
  const { seasonId, season } = useCurrentSeasonContext();

  const {
    divisionId,
    divisionIsForbidden,
    divisionIsLoaded,
    divisionIsLoading,
    division,
    divisionBreadcrumbs
  } = useCurrentDivisionContext();

  const { pageNumber, sort } = useTeamsListLoading({ seasonId, divisionId, pageSize: PAGE_SIZE });
  useTeamsListClearing();

  const { isLoaded, isLoading, isForbidden, teams, lockingTeams, triggerLockTeam } = useDivisionTeamsList();
  const openCopyTeamsModal = useCopyTeamsModalOpen({ seasonId, divisionId });
  const hasUnlockedTeams = teams.filter(t => !t.data.rosterLocked).length > 0;

  const lockRostersCallback = () => {
    lockRosters({ lock: true });
  };

  const unlockRostersCallback = () => {
    lockRosters({ lock: false });
  };

  const myCsvRef = useRef();

  const handleOnClick = ({invitationsOnly}) => {
    if (teams.length === 0) {
      console.error("Must have Teams to Export");
      return;
    }

    if(myCsvRef.current && invitationsOnly){
      myCsvRef.current.ExportInvitationsToCSV()
    }

    if (myCsvRef.current && !invitationsOnly) {
      myCsvRef.current.ExportToCSV();
    }
  };

  return (
    <ProtectedLayout className="teams-page" condition={!divisionIsForbidden && !isForbidden}>
      {divisionIsLoaded && isLoaded ? (
        <Fragment>
          <Head title={`${division.title} Teams`} />

          <Breadcrumbs items={divisionBreadcrumbs} />

          <TitleBar title="Teams">
            {teams.length > 0 && (
              <Can I="update" this={{ type: "divisions", id: divisionId }}>
                <Can I="update" this={{ type: "teamLocks" }}>
                  {isRosterLocking && (
                    <div className="roster-lock-spinner">
                      <Spinner theme="loader" color="yellow" size="m" />
                    </div>
                  )}
                  {(hasUnlockedTeams && (
                    <SingleActionModal
                      buttonText={
                        <>
                          <img src={padlock} height={12} className="lock-teams-button" />
                          <span className="lock-teams-button">Lock Teams</span>
                        </>
                      }
                      headerText={`Lock Teams in ${division.title}`}
                      bodyText={
                        <>
                          <p>
                            Locking prevents teams and their rosters from being modified. Would you like to proceed with
                            locking all teams in {division.title}?
                          </p>
                          <p>
                            <a
                              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
                              target="_blank"
                            >
                              More Information
                            </a>
                          </p>
                        </>
                      }
                      confirmText="Lock"
                      buttonColor="secondary"
                      confirmColor="success"
                      confirmCallback={lockRostersCallback}
                      disabled={isRosterLocking}
                    />
                  )) || (
                    <SingleActionModal
                      buttonText={
                        <>
                          <img src={openPadlock} height={12} className="lock-teams-button" />
                          <span className="lock-teams-button">Unlock Teams</span>
                        </>
                      }
                      headerText={`Unlock Teams in ${division.title}`}
                      bodyText={
                        <>
                          <p>
                            Unlocking allows teams and their rosters to be modified. Would you like to proceed with
                            unlocking all teams in {division.title}?
                          </p>
                          <p>
                            <a
                              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
                              target="_blank"
                            >
                              More Information
                            </a>
                          </p>
                        </>
                      }
                      confirmText="Unlock"
                      buttonColor="secondary"
                      confirmColor="success"
                      confirmCallback={unlockRostersCallback}
                      disabled={isRosterLocking}
                    />
                  )}
                </Can>
              </Can>
            )}

            <Button onClick={() => handleOnClick({invitationsOnly:true})} size="sm" outline color="primary">
            DOWNLOAD INVITATION CODES
            </Button>

            <Button onClick={() => handleOnClick({invitationsOnly:false})} size="sm" outline color="primary">
              Export to CSV
            </Button>

            <AbilityButton
              subject={{ type: "teams", divisionId }}
              action="create"
              outline
              color="success"
              size="sm"
              tag={Link}
              to={`/seasons/${seasonId}/divisions/${divisionId}/teams/new`}
            >
              + New Team
            </AbilityButton>

            <AbilityButton
              subject={{ type: "teams", divisionId }}
              action="copy"
              outline
              color="success"
              size="sm"
              onClick={openCopyTeamsModal}
            >
              Copy Teams
            </AbilityButton>

            <ExternalImportButton divisionId={divisionId} />
          </TitleBar>

          <TeamList
            title={`${season.title} ${division.title}`}
            seasonId={seasonId}
            divisionId={divisionId}
            teams={teams}
            displayDivision={false}
            csvRef={myCsvRef}
            lockingTeams={lockingTeams}
            triggerLockTeam={triggerLockTeam}
            pageSize={PAGE_SIZE}
            pageNumber={pageNumber}
            sort={sort}
          />

          <CopyTeamsModal seasonId={seasonId} />
        </Fragment>
      ) : (
        <PageLoader isLoading={divisionIsLoading || isLoading} />
      )}
    </ProtectedLayout>
  );
}

export default TeamsIndex;
