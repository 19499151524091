import { connect } from "react-redux";

import {
  isCurrentTeamOperating,
  isCurrentTeamReady,
  getCurrentTeamCoachesRoster,
  getCurrentTeam
} from "@/redux/teams/selectors";

import {
  isCurrentCoachOperating,
  isCurrentCoachReady,
  getCurrentCoach,
  getCurrentTeamCoach,
  getCurrentTeamCoachBreadcrumbs,
  getCurrentCoachFullName,
  isCurrentCoachForbidden,
  isCurrentCoachArchived
} from "@/redux/coaches/selectors";

import TeamRosterCoachesEdit from "./TeamRosterCoachesEdit";

import { CurrentCoachLoadingRoutine } from "@/redux/coaches/routines";
import { clearCurrentCoach } from "@/redux/coaches/actions";
import { CurrentTeamLoadingRoutine, CurrentTeamUpdatingRoutine } from "@/redux/teams/routines";

const mapStateToProps = state => ({
  isTeamReady: isCurrentTeamReady(state),
  isTeamOperating: isCurrentTeamOperating(state),
  isCoachReady: isCurrentCoachReady(state),
  isCoachOperating: isCurrentCoachOperating(state),
  isCoachForbidden: isCurrentCoachForbidden(state),
  isCoachArchived: isCurrentCoachArchived(state),
  coachesRoster: getCurrentTeamCoachesRoster(state),
  coach: getCurrentCoach(state),
  teamCoach: getCurrentTeamCoach(state),
  coachFullName: getCurrentCoachFullName(state),
  breadcrumbs: getCurrentTeamCoachBreadcrumbs(state),
  isCoachCreating: false,
  team: getCurrentTeam(state)
});

const mapDispatchToProps = dispatch => ({
  loadTeam: id => dispatch(CurrentTeamLoadingRoutine({ id })),
  loadCoach: ({ id, seasonId }) => dispatch(CurrentCoachLoadingRoutine({ id, seasonId })),
  updateTeam: ({ id, seasonId, divisionId, attributes, tab, redirect, logo }) =>
    dispatch(CurrentTeamUpdatingRoutine({ id, seasonId, divisionId, attributes, logo, tab, redirect })),
  clearCoach: () => dispatch(clearCurrentCoach())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamRosterCoachesEdit);
