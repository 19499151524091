import React from "react";
import PropTypes from "prop-types";

import GameLink from "./components/GameLink";
import CountNotesBubble from "./CountNotesBubble";
import ViewedStatus from "./ViewedStatus";
import Flags from "./Flags";
import WrapIf from "./hooks/useWrapIf";

function Game(props) {
  const {
    visitor,
    score,
    home,
    divisions,
    date,
    number,
    location,
    id,
    countNotes,
    viewedStatus,
    flags,
    seasonId
  } = props;

  const touch = matchMedia("(hover: none)").matches;

  return (
    <tr>
      <td>
        <GameLink id={id} seasonId={seasonId}>
          {visitor}
        </GameLink>
      </td>
      <td className="score">
        <GameLink id={id} seasonId={seasonId}>
          {score}
        </GameLink>
      </td>
      <td>
        <GameLink id={id} seasonId={seasonId}>
          {home}
        </GameLink>
      </td>
      <td>{divisions}</td>
      <td>{date}</td>
      <td>{number}</td>
      <td>{location}</td>
      <td>
        <CountNotesBubble id={id} countNotes={countNotes} seasonId={seasonId} />
      </td>
      <td>
        <ViewedStatus id={id} seasonId={seasonId} {...viewedStatus} />
      </td>
      <td>
        <WrapIf cond={!touch} wrapper={GameLink} id={id} seasonId={seasonId}>
          <Flags id={id} flags={flags} seasonId={seasonId} />
        </WrapIf>
      </td>
    </tr>
  );
}

Game.propTypes = {
  id: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  visitor: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
  home: PropTypes.string.isRequired,
  divisions: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  countNotes: PropTypes.number.isRequired,
  flags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewedStatus: PropTypes.shape({
    isViewed: PropTypes.bool,
    isChanging: PropTypes.bool
  }).isRequired
};

export default Game;
