import React from "react";
import PropTypes from "prop-types";

function PenaltyCode({ code, label, penaltyClass, duration }) {
  return (
    <tr key={code}>
      <td>{label}</td>
      <td>{code}</td>
      <td>{penaltyClass}</td>
      <td>{duration}</td>
    </tr>
  );
}

PenaltyCode.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  penaltyClass: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
};

export default PenaltyCode;
