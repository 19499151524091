import _orderBy from "lodash/orderBy";

const selectRoot = state => state.scheduledGamesCSVImportWizard;

export const getMeta = state => selectRoot(state).meta;

export const getInvalidRows = state => {
  const { failedRecords } = selectRoot(state);

  return _orderBy(
    failedRecords.map(({ lineNumber, validationErrors, ...record }) => ({
      record,
      lineNumber,
      validationErrors
    })),
    "lineNumber",
    "asc"
  );
};

export const getRecords = state => selectRoot(state).records;

export const getNumRecords = state => getRecords(state).records.length;

export const getNumRecordsToInsert = state => {
  return getRecords(state).records.filter(({ id }) => !id || id === "" || id === 0).length;
}

export const getNumRecordsToUpdate = state => {
  return getRecords(state).records.filter(({ id }) => !!id && id !== "" && id !== 0).length;
}

export const getCreated = state => getRecords(state).created;

export const getUpdated = state => getRecords(state).updated;

export const getImportedSuccess = state => getCreated(state) + getUpdated(state);

export const getImportedFailure = state => getRecords(state).failed;

export const getFormattedGames = state => {
  const records = getRecords(state).records;
  return records.map(record => ({
    id: Number(record.id),
    gameType: record.gameType,
    homeDivisionId: Number(record.home.division),
    visitorDivisionId: Number(record.visitor.division),
    homeId: Number(record.home.team),
    visitorId: Number(record.visitor.team),
    number: record.number,
    location: record.location,
    scorekeeper: record.scorekeeper,
    scheduledStartTime: `${record.scheduledStartTime}T${record.startTime}:00Z`,
    data: {
      broadcaster: record.broadcastProvider,
    },
    status: record.status
  }));
}
