import { atom, selector } from "recoil";
import Service from "@/services";
import {
  coachesImportResponsesState,
  coachImportProgressState, lastMountState, playerImportProgressState, playerImportResponsesState,
  recordsState,
  seasonIdState
} from "@/components-16.8/RosterImportWizard/state/atoms";
import { coachRecordsState } from "@/components-16.8/RosterImportWizard/state/coaches";
import { mergeTeamRoster } from "../functions/mergeTeamRoster";

export const seasonState = selector({
  key: 'seasonState',
  get: async ({ get }) => {
    const seasonId = get(seasonIdState);

    if (!seasonId) {
      return {};
    }

    return Service.api().seasons.season( seasonId ).get().then(response => {
      return response.data.attributes;
    });

  }
});

export const seasonTeamsState = selector({
  key: 'seasonTeams',
  get: async ({ get }) => {

    const lastMount = get(lastMountState);
    const seasonId = get(seasonIdState);
    
    if (!seasonId) {
      return [];
    }

    return Service.api().seasons.season(seasonId).teams.get()
      .then(response => {
        return response.data.filter(record => record.type==="teams");
      });
  }
});

export const teamIdsState = selector({
  key: 'teamIds',
  get: ({ get }) => {
    // get the teamIds for record, filter out null values, and then make array unique
    const records = get(recordsState);
    return records.map( record => record.teamId ).filter( x => !!x ).filter((x,i,a) => a.indexOf(x) === i);
  }
})

export const rosterLockedTeamIdsState = selector({
  key: 'rosterLockedTeamIds',
  get: ({ get }) => {
    return get(seasonTeamsState).filter(team => !!team.attributes.data.roster_locked).map(team => team.id);
  }
})

export const validTeamIdsState = selector({
  key: 'validTeamIds',
  get: ({ get }) => {
    return get(seasonTeamsState).map(team => team.id);
  }
});

export const affectedTeamIds = selector({
  key: 'affectedTeamIds',
  get: ({ get }) => {
    const recordTeamIds = get(teamIdsState);
    const validTeamIds = get(validTeamIdsState);
    return recordTeamIds.filter( id => validTeamIds.includes( id ));
  }
});

export const teamUpdatesAfterImportState = selector({
  key: 'teamUpdatesAfterImport',
  get: async ({ get }) => {

    const teamIds = get(affectedTeamIds);
    const teams = get(seasonTeamsState);
    const seasonId = get(seasonIdState);
    const importedPlayers = get(playerImportResponsesState).map( player => ({ id: player.record.data.data.id, ...player.record.data.data.attributes, team_ids: player.player.teamIds, ...player.player }));    
    const importedCoaches = get(coachesImportResponsesState).map( coach => ({ id: coach.record.data.data.id, ...coach.record.data.data.attributes, team_ids: coach.coach.teamIds, ...coach.coach }));
    
    return teamIds.map( teamId => {
      const team = teams.find( t => t.id === teamId );
      const patch = JSON.parse(JSON.stringify({
        type: team.type,
        id: team.id,
        attributes: {
          title: team.attributes.title,
          external_id: team.attributes.external_id,
          roster: { 
            players: mergeTeamRoster(
              team.attributes.roster.players,
              importedPlayers
                .filter( importedPlayer => importedPlayer.team_ids.includes( teamId ))
                .map(player => ({
                  id: player.id,
                  number: player.jerseyNumber,
                  position: player.position,
                  duty: player.designation,
                  affiliated: player.affiliated,
                  starting: false,
                  added_at_game_time: false
                }))
            ), 
            coaches: mergeTeamRoster(
              team.attributes.roster.coaches,
              importedCoaches
                .filter( importedCoach => importedCoach.team_ids.includes( teamId ))
                .map(coach => ({
                  id: coach.id,
                  position: coach.position
                }))
            )
          }
        },
        relationships: {
          division: team.relationships.division
        }
      }));

      // send updated team
      return function(){
        return Service.api().seasons.season(seasonId).teams.v2_team( team.id ).patch(patch);
      }
    });
  }
})
