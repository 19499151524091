import axios from 'axios';

export const scheduledGamesService = (path, config) => {
  path = `${path}/schedule`;

  const getParamString = ({ include, pageSpec, filters }) => {
    const params = {};
    
    if (include.length) {
      params["include"] = include;
    }
    if (pageSpec.pageNumber) {
      params["page[number]"] = pageSpec.pageNumber;
    }
    if (pageSpec.pageSize) {
      params["page[size]"] = pageSpec.pageSize;
    }
    if (filters.startDate) {
      params["filter[scheduled_start_time_from]"] = filters.startDate
    }
    if (filters.endDate) {
      params["filter[scheduled_start_time_to]"] = `${filters.endDate}T23:59:59Z`
    }
    if (filters.divisions && filters.divisions.length) {
      params["filter[division_id]"] = filters.divisions;
    }
    if (filters.query) {
      params["filter[query]"] = filters.query
    }
    if(filters.gameType){
      params["filter[game_type]"] = filters.gameType
    }

    return Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  }

  return {
    get: async ({ include=[], pageSpec={}, filters={} }={}) => {
      const paramString = getParamString({ include, pageSpec, filters });
      path += paramString.length ? `?${paramString}` : '';
      return axios.get(path, config).then(response => {
        return response.data;
      }).catch(e => {
        console.log("Get Season ScheduledGames Error", e);
        return {};
      });
    },
    delete: async ({ gameId }) => {
      path += `/${gameId}`;
      return axios.delete(path, config).then(response => {
        return true;
      }).catch(e => {
        console.log("Delete Season ScheduledGame Error", e);
        return false;
      });
    },
    deleteAll: async () => {
      return axios.delete(path, config).then(response => {
        return true;
      }).catch(e => {
        console.log("Delete All Season ScheduledGames Error", e);
        return false;
      });
    }
  }
}