import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { Input, Label } from "reactstrap";

import { getHockeyYearOptions, toHockeyYear, toStartEndDates } from "./DateUtilities";

function HockeyYearField({ startDate, endDate }) {
  const options = getHockeyYearOptions();
  const value = toHockeyYear(startDate.value, endDate.value);

  const onChange = useCallback(
    ({ target: { value } }) => {
      const { startDate: newStart, endDate: newEnd } = toStartEndDates(value);
      startDate.setValue(newStart);
      endDate.setValue(newEnd);
    },
    [startDate, endDate]
  );

  return (
    <Fragment>
      <Label for="seasonHokeyYear">Hockey Year</Label>
      <Input id="seasonHokeyYear" type="select" value={value} onChange={onChange}>
        {options.map(hockeyYear => (
          <option value={hockeyYear} key={hockeyYear}>
            {hockeyYear}
          </option>
        ))}
      </Input>
    </Fragment>
  );
}

HockeyYearField.propTypes = {
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({})
};

export default HockeyYearField;
