import React from "react";
import PropTypes from "prop-types";
import GameFlagsList from "@/components/GameFlagsList";

const Flags = ({ flags }) => {
  return flags.length > 0 ? (
    <section className="gamesheet__flags">
      <h3>Flags</h3>
      <GameFlagsList flags={flags} />
    </section>
  ) : (
    ""
  );
};

Flags.propTypes = {
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired
    })
  )
};

export default Flags;
