export default function sortByPeriodAndTime(objectA, objectB) {
  if (objectA.period === objectB.period) {
    if (objectA.time === objectB.time) {
      return 0;
    }

    return objectA.time > objectB.time ? -1 : 1;
  }

  return objectA.period > objectB.period ? 1 : -1;
}
