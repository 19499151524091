import React, { Fragment } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import useCurrentDivisionContext from "@/hooks/useCurrentDivisionContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import TitleBar from "@/components/TitleBar";
import Breadcrumbs from "@/components/Breadcrumbs";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import DivisionForm, { useDivisionFormClearing } from "@/components/DivisionForm";

function EditDivision() {
  const {
    divisionId,
    divisionFormFields,
    division,
    divisionIsForbidden,
    divisionIsLoading,
    divisionIsLoaded,
    divisionBreadcrumbs
  } = useCurrentDivisionContext();

  const { seasonId, seasonGameSettings, seasonIsLoaded, seasonIsLoading } = useCurrentSeasonContext();

  useDivisionFormClearing();

  return (
    <ProtectedLayout condition={!divisionIsForbidden} className="divisions-page">
      {seasonIsLoaded ? (
        divisionIsLoaded ? (
          <Fragment>
            <Head title={division.title} />
            <Breadcrumbs items={divisionBreadcrumbs} />
            <TitleBar title="Edit Division" />

            <DivisionForm
              initialValues={divisionFormFields}
              seasonId={seasonId}
              divisionId={divisionId}
              seasonGameSettings={seasonGameSettings}
            />
          </Fragment>
        ) : (
          <PageLoader isLoading={divisionIsLoading} />
        )
      ) : (
        <PageLoader isLoading={seasonIsLoading} />
      )}
    </ProtectedLayout>
  );
}

export default EditDivision;
