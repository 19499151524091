export const TIE_BREAKERS = [
  {
    title: "points",
    label: "Points",
    description: "The team with the most points gains the higher position.",
    enabled: true,
  },
  {
    title: "wins",
    label: "Wins",
    description:
      "The team with the most wins gains the higher position. (whether in regulation, overtime or shootout).",
    enabled: true,
  },
  {
    title: "diff",
    label: "Goal Differential",
    description:
      "Goals against subtracted from goals scored, with positions being determined in the order of the greatest surplus.",
    enabled: true,
  },
  {
    title: "goal_quotient",
    label: "Goal Quotient",
    description:
      "Goals scored divided by goals against, the positions being determined in order of the greatest quotient. A quotient involving dividing by zero (0) has higher standing than a quotient from dividing by any number other than zero.",
    enabled: true,
  },
  {
    title: "goal_quotient2",
    label: "Goal Quotient 2",
    description:
      "Goals scored divided by the sum of the goals scored and goals against, the positions being determined in the order of the greatest percentage.",
    enabled: true,
  },
  {
    title: "manual",
    label: "Manual",
    description: "",
    enabled: true,
  },
  {
    title: "goals_for",
    label: "Goals For",
    description: "The team with the most goals scored gains the higher position.",
    enabled: true,
  },
  {
    title: "goals_against",
    label: "Goals Against",
    description: "The team with the least goals against gains the higher position.",
    enabled: true,
  },
  {
    title: "games_played",
    label: "Games Played",
    description: "The team with the least games played gains the higher position.",
    enabled: true,
  },
  {
    title: "ties",
    label: "Ties",
    description: "The team with the least ties gains the higher position.",
    enabled: true,
  },
  {
    title: "losses",
    label: "Losses",
    description: "The team with the least losses gains the higher position.",
    enabled: true,
  },
  {
    title: "period_points",
    label: "Period Points",
    description: "The team with the most period points gains the higher position. ",
    enabled: true,
  },
  {
    title: "shutouts",
    label: "Most Shutouts",
    description: "The team with the most shutouts gains the higher position.",
    enabled: true,
  },

  {
    title: "periods_won",
    label: "Most Periods Won  (Pts)",
    description: "Points are awarded for each regulation period won and for each regulation period tied. Point values are customizable. The team with the larger point total gains the higher position.",
    enabled: true,
  },
  {
    title: "overtime_shootout_wins",
    label: "Overtime/Shootout Wins",
    description: "The team with the most wins in overtime or shootout gains the higher position.",
    enabled: true,
  },
  {
    title: "overtime_shootout_losses",
    label: "Overtime/Shootout Losses",
    description:
      "The team with the least losses in overtime or shootout gains the higher position.",
    enabled: true,
  },
  {
    title: "quickest_goal",
    label: "Quickest First Goal",
    description:
      "The team that scored nearest to the beginning of the first period shall be ranked highest.",
    enabled: true,
  },
  {
    title: "penalty_minutes",
    label: "Penalties in Minutes",
    description: "The team with the least penalty minutes gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_points",
    label: "Head to Head Points",
    description: "For games in head to head competition between the tied teams, the team with the larger points total gains the higher position.",
    enabled: true,
  },
  {
    title: "points_percent",
    label: "Points Percentage",
    description: "The team that earns the highest percentage of possible points gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_wins",
    label: "Head to Head Wins",
    description: "For games in head to head competition between the tied teams, the team with the most wins gains the higher position.",
    enabled: true,
  },
  {
    title: "reg_wins",
    label: "Regulation Wins",
    description: "The team with more wins in regulation gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_reg_wins",
    label: "Head to Head Regulation Wins",
    description: "For games in head to head competition between the tied teams, the team with more regulation wins gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_diff",
    label: "Head to Head Goal Differential",
    description: "For games in head to head competition between the tied teams, subtract goals against from goals scored. The team with the larger value gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_quotient1",
    label: "Head to Head Goal Quotient",
    description: "For games in head to head competition between the tied teams, divide goals scroed by goals against. The team with the larger value gains the higher position. A quotient involving dividing by zero (0) has higher standing than a quotient from dividing by any number other than zero.",
    enabled: true,
  },
  {
    title: "h2h_periods_won",
    label: "Head to Head Most Periods Won (by Pts)",
    description: "For games in head to head competition between the tied teams, points are awarded for each regulation period won (2pts) and for each regulation period tied (1pt). The team with the larger point total gains the higher position.",
    enabled: true,
  },
  {
    title: "fair_play_points",
    label: "Fair Play Points",
    description: "The team with the larger fair play points total gains the higher position.",
    enabled: true,
  },
  {
    title: "h2h_fair_play_points",
    label: "Head to Head Fair Play Points",
    description: "For games in head to head competition between the tied teams, the team with more fair play points gains the higher position.",
    enabled: true,
  },

];
