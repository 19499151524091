import { all, put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { createFile } from "@/lib/api/file";

import { createSeason, updateSeason, deleteSeason } from "@/lib/api/seasons";

import { responseErrorsFullMessages } from "@/lib/api/utils";
import { gamesheetAPIRequest } from "@/redux/api/sagas";

import { FIELDS_MAPPING } from "@/components/SeasonForm";

import { submittingRoutine, deletingRoutine } from "./routines";

import { actions as currentSeasonActions, loadingRoutine as currentSeasonLoadingRoutine } from "@/redux/currentSeason";

import { standardizePenaltySettings } from "@/utils/standardizePenaltySettings";

function* uploadCouponSaga(file) {
  const {
    data: { data },
  } = yield call(gamesheetAPIRequest, createFile, {
    file,
  });

  return data.attributes.url;
}

function* submittingSaga({ payload }) {
  const { associationId, leagueId, seasonId, values } = payload;

  const {
    title,
    externalId,
    startDate,
    endDate,
    generalSettings,
    statsSettings,
    penaltySettings,
    flaggingSettings,
    playerOfTheGame,
    liveScoringMode,
    leagueAppMode
  } = values;

  yield put(submittingRoutine.request());

  if (playerOfTheGame !== null) {
    const coupon = playerOfTheGame.coupon;

    try {
      playerOfTheGame.coupon =
        coupon instanceof File ? yield call(uploadCouponSaga, coupon) : coupon;
    } catch (error) {
      yield all([
        put(
          submittingRoutine.failure({
            error,
            validationErrors: {
              "playerOfTheGame/coupon": ["Failed to upload coupon"],
            },
          })
        ),
        put(submittingRoutine.fulfill()),
      ]);
      return;
    }
  }

  { // new scope to avoid potential variable name conflicts
    const { penaltyCodes, penaltyLengths, penaltyDurations, penaltyClasses } = standardizePenaltySettings(penaltySettings);
    penaltySettings.penaltyCodes = penaltyCodes;
    penaltySettings.penaltyClasses = penaltyClasses;
    penaltySettings.penaltyDurations = penaltyDurations;

    // keep legacy penaltyLengths unless new penaltyDurations has some data 
    if (penaltyDurations.length > 0) {
      penaltySettings.penaltyLengths = penaltyLengths;
    }
  }

  const attributes = {
    title,
    externalId,
    startDate,
    endDate,
    flaggedPenalties: flaggingSettings.penalties,
    flaggingCriteria: flaggingSettings.criteria,
    settings: {
      ...statsSettings,
      ...generalSettings,
      ...penaltySettings,
      leagueAppMode
    },
    playerOfTheGame,
    liveScoringMode,
  };

  try {
    const service = seasonId ? updateSeason : createSeason;

    const result = yield call(
      gamesheetAPIRequest,
      service,
      {
        attributes,
        identity: { type: "seasons", id: seasonId },
        associationId,
        leagueId,
      },
      true
    );

    let newSeasonId = seasonId;
    if (result && result.data && result.data.seasons) {
      const ids = Object.keys(result.data.seasons);
      if (ids.length >= 1) {
        newSeasonId = ids[0]
      }
    }

    yield put(submittingRoutine.success());
    if (!!newSeasonId) {
      yield put(currentSeasonActions.clear());
      yield put(currentSeasonLoadingRoutine({ seasonId: newSeasonId }));
      yield put(push(`/seasons/${newSeasonId}`));
    } else {
      yield put(push(`/leagues/${leagueId}/seasons`));
    }
    
  } catch (error) {
    const { response } = error;
    const validationErrors = response ? responseErrorsFullMessages(response, FIELDS_MAPPING) : {};

    yield put(submittingRoutine.failure({ error, validationErrors }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}

function* deletingSaga({ payload: { associationId, leagueId, seasonId } }) {
  yield put(deletingRoutine.request());

  try {
    yield gamesheetAPIRequest(deleteSeason, {
      identity: { type: "seasons", id: seasonId },
      associationId,
      leagueId,
    });

    yield put(deletingRoutine.success());
    yield put(push(`/leagues/${leagueId}/seasons`));
  } catch (e) {
    yield put(deletingRoutine.failure({ error: e }));
  } finally {
    yield put(deletingRoutine.fulfill());
  }
}

export function* seasonFormFlow() {
  yield all([
    takeLatest(submittingRoutine, submittingSaga),
    takeLatest(deletingRoutine, deletingSaga),
  ]);
}
