import { all, select, put, call, takeLatest } from "redux-saga/effects";

import { loadSeason } from "@/lib/api/seasons";
import { fetchOne, gamesheetAPIRequest } from "@/redux/api/sagas";

import { loadingRoutine, lockRostersRoutine } from "./routines";

import { updateSeasonRosterLock } from "@/lib/api/seasons";

import { getMeta, getSeason } from "./selectors";

function* loadingSaga({ payload: { seasonId } }) {
  yield put(loadingRoutine.request());

  try {
    const [season] = yield fetchOne({ type: "seasons", id: seasonId }, loadSeason, { include: "associations,leagues" });

    yield put(loadingRoutine.success({ season }));
  } catch (error) {
    const { response } = error;

    yield put(
      loadingRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(loadingRoutine.fulfill());
  }
}

function* lockRostersSaga({ payload: { lock, successCb } }) {
  yield put(lockRostersRoutine.request());

  const meta = yield select(getMeta);
  if (!meta.isLoaded) {
    yield put(lockRostersRoutine.fulfill());
    return;
  }

  const season = yield select(getSeason);

  try {
    yield call(
      gamesheetAPIRequest,
      updateSeasonRosterLock,
      {
        seasonId: season.id,
        attributes: { lock }
      },
      true
    );

    yield put(lockRostersRoutine.success());

    if (successCb && typeof successCb === "function") {
      successCb();
    }
  } catch (error) {
    const { response } = error;

    yield put(
      lockRostersRoutine.failure({
        error,
        response
      })
    );
  } finally {
    yield put(lockRostersRoutine.fulfill());
  }
}

export function* currentSeasonFlow() {
  yield all([takeLatest(loadingRoutine.TRIGGER, loadingSaga), takeLatest(lockRostersRoutine.TRIGGER, lockRostersSaga)]);
}
