import _isEmpty from "lodash/isEmpty";
import _isBoolean from "lodash/isBoolean";

const VALID_POSITIONS = {
  player: ["goalie", "forward", "defence", "right_wing", "left_wing", "centre", "transition"],
  coach: [
    "head_coach",
    "assistant_coach",
    "trainer",
    "assistant_trainer",
    "manager",
    "head_coach_at_large",
    "assistant_coach_at_large",
    "trainer_at_large"
  ]
};

const VALID_PLAYER_DUTIES = ["captain", "alternate_captain"];

export function validateFirstName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("First name can not be empty");
  }

  return errors;
}

export function validateLastName(value) {
  const errors = [];

  if (_isEmpty(value)) {
    errors.push("Last name can not be empty");
  }

  return errors;
}

export function validateExternalId(value) {
  const errors = [];

  if (value.match(/[^a-z\d-]/i)) {
    errors.push("External Id is invalid");
  }

  return errors;
}

export function validateAffiliated(value) {
  const errors = [];

  if (!_isBoolean(value)) {
    errors.push("Status is invalid");
  }

  return errors;
}

export function validatePlayerPosition(value) {
  const errors = [];

  if (value !== "" && !VALID_POSITIONS.player.includes(value)) {
    errors.push("Position is invalid");
  }

  return errors;
}

export function validateCoachPosition(value) {
  const errors = [];

  if (value !== "" && !VALID_POSITIONS.coach.includes(value)) {
    errors.push("Position is invalid");
  }

  return errors;
}

export function validateDuty(value) {
  const errors = [];

  if (value !== "" && !VALID_PLAYER_DUTIES.includes(value)) {
    errors.push("Duty is invalid");
  }

  return errors;
}

export function validateNumber(value) {
  const number = Number(value);
  const length = value.length;
  const errors = [];

  if (length > 0 && (length > 2 || !(Number.isInteger(number) && number >= 0))) {
    errors.push("Jersey is invalid");
  }

  return errors;
}

export function validatePlayer(values) {
  const { firstName, lastName, externalId, number, affiliated, position, duty } = values;

  let errors = null;

  const firstNameErrors = validateFirstName(firstName);
  const lastNameErrors = validateLastName(lastName);
  const externalIdErrors = validateExternalId(externalId);
  const numberErrors = validateNumber(number);
  const affiliatedErrors = validateAffiliated(affiliated);
  const positionErrors = validatePlayerPosition(position);
  const dutyErrors = validateDuty(duty);

  if (firstNameErrors.length > 0) {
    errors = { ...errors, firstName: firstNameErrors };
  }

  if (lastNameErrors.length > 0) {
    errors = { ...errors, lastName: lastNameErrors };
  }

  if (externalIdErrors.length > 0) {
    errors = { ...errors, externalId: externalIdErrors };
  }

  if (numberErrors.length > 0) {
    errors = { ...errors, number: numberErrors };
  }

  if (affiliatedErrors.length > 0) {
    errors = { ...errors, number: affiliatedErrors };
  }

  if (positionErrors.length > 0) {
    errors = { ...errors, number: positionErrors };
  }

  if (dutyErrors.length > 0) {
    errors = { ...errors, number: dutyErrors };
  }

  return errors;
}

export function validateCoach(values) {
  const { firstName, lastName, externalId, position } = values;

  let errors = null;

  const firstNameErrors = validateFirstName(firstName);
  const lastNameErrors = validateLastName(lastName);
  const externalIdErrors = validateExternalId(externalId);
  const positionErrors = validateCoachPosition(position);

  if (firstNameErrors.length > 0) {
    errors = { ...errors, firstName: firstNameErrors };
  }

  if (lastNameErrors.length > 0) {
    errors = { ...errors, lastName: lastNameErrors };
  }

  if (externalIdErrors.length > 0) {
    errors = { ...errors, externalId: externalIdErrors };
  }

  if (positionErrors.length > 0) {
    errors = { ...errors, number: positionErrors };
  }

  return errors;
}
