import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { Row, FormGroup, Label, Button } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import { Can } from "@/components/Ability";

import ScheduledGameLocationInput from "@/components-16.8/ScheduledGameLocationInput";

import useScheduledGameForm from "./hooks/useScheduledGameForm";
import useScheduledGameFormSubmitting from "./hooks/useScheduledGameFormSubmitting";
import useScheduledGameFormDeleting from "./hooks/useScheduledGameFormDeleting";

import TeamInput from "./TeamInput";
import ScheduledDateInput from "./ScheduledDateInput";
import ScheduledStartTimeInput from "./ScheduledStartTimeInput";
import TextInput from "./TextInput";
import GameTypeInput from "./GameTypeInput";
import BroadcastProviderInput from "./BroadcastProviderInput";

import ScorekeeperInput from "./ScorekeeperInput";



function ScheduledGameForm({ gameId, initialValues }) {
  const { fields, noTeamsSelected, switchTeams, isInvalid, isPristine, values, updateBroadcast, reset } = useScheduledGameForm(
    initialValues
  );

  const { isSubmitting, submit, clear, setClear } = useScheduledGameFormSubmitting({
    values,
    gameId
  });

  const handleSubmit = ({goBack = true})=> {
    submit({goBack})
    if(!goBack){
      reset()
      setClear(old => old + 1) // change value so any listening useEffects will run
    }
  }

  const { isDeleting, deleteGame } = useScheduledGameFormDeleting(gameId);
  return (
    <DashboardForm isOperating={isSubmitting || isDeleting} onSubmit={handleSubmit}>
      <Row form className="mb-3">
        <FormGroup
          className={classNames({
            "col-md-5": !noTeamsSelected,
            "col-md-6": noTeamsSelected
          })}
        >
          <Label for="scheduledGameFormVisitor">Visitor Team</Label>

          <TeamInput
            id="scheduledGameFormVisitor"
            name="visitor"
            opponentId={fields.home.value.team}
            {...fields.visitor}
          />
        </FormGroup>

        {!noTeamsSelected && (
          <FormGroup className="col-md-2">
            <Label>&nbsp;</Label>

            <Button block color="light" onClick={switchTeams}>
              ⇄
            </Button>
          </FormGroup>
        )}

        <FormGroup
          className={classNames("required", {
            "col-md-5": !noTeamsSelected,
            "col-md-6": noTeamsSelected
          })}
        >
          <Label for="scheduledGameFormHome">Home Team</Label>

          <TeamInput id="scheduledGameFormHome" name="home" opponentId={fields.visitor.value.team} {...fields.home} />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-3 required">
          <Label for="scheduledGameFormScheduledStartTime">Game Date</Label>

          <ScheduledDateInput id="scheduledGameFormScheduledStartTime" {...fields.scheduledStartTime} />
        </FormGroup>

        <FormGroup className="col-md-3 required">
          <Label for="scheduledGameFormStartTime">Scheduled Start Time</Label>

          <ScheduledStartTimeInput id="scheduledGameFormStartTime" {...fields.startTime} />
        </FormGroup>

        <FormGroup className="col-md-3 required">
          <Label for="scheduledGameFormNumber">Game Number</Label>

          <TextInput id="scheduledGameFormNumber" {...fields.number} />
        </FormGroup>

        <FormGroup className="col-md-3 required">
          <Label for="scheduledGameFormGameType">Game Type</Label>

          <GameTypeInput id="scheduledGameFormGameType" {...fields.gameType} />
        </FormGroup>
      </Row>

      <Row form>
        <FormGroup className="col-md-6">
          <Label for="scheduledGameFormLocation">Location</Label>

          <ScheduledGameLocationInput
            id="scheduledGameFormLocation"
            location={fields.location.value}
            setLocation={fields.location.setValue}
            clear={clear}
          />
        </FormGroup>

        <FormGroup className="col-md-6">
          <Label for="scheduledGameFormScorekeeperName">Scorekeeper</Label>

          <ScorekeeperInput {...fields.scorekeeper} />
        </FormGroup>
      </Row>
      <Row form>
        <FormGroup className="col-md-6">
          <Label for="scheduledGameFormBroadcastProvider">Broadcast Provider</Label>
          {/* {console.log("FIELDS: ",fields)}  */}
          <BroadcastProviderInput
            selectedCode={fields.broadcaster.value}
            id="scheduledGameFormBroadcastProvider"
            {...fields.broadcaster}
          />
        </FormGroup>
      </Row>

      <DashboardFormFooter>
        {gameId ? (
          <Fragment>
            <Can I="delete" this={{ type: "scheduledGames", id: gameId }}>
              <div className="dashboard-form-footer__secondary">
                <ConfirmDeletionModal
                  resourceTitle={initialValues.number.trimEnd() !== "" ? initialValues.number : `#${gameId}`}
                  resourceType="scheduled game"
                  isDeleting={isDeleting}
                  isOperating={isSubmitting}
                  onDelete={deleteGame}
                />
              </div>
            </Can>

            <div className="dashboard-form-footer__primary">
              <Button
                onClick={handleSubmit}
                color="success"
                disabled={isPristine || isInvalid}
                outline={isPristine || isInvalid}
              >
                {isSubmitting ? "Saving Changes…" : "Save Changes"}
              </Button>
            </div>
          </Fragment>
        ) : (
          <div className="dashboard-form-footer__primary">
             <Button onClick={() => handleSubmit({goBack:false})} type="submit" color="success" disabled={isPristine || isInvalid} outline={isPristine || isInvalid}>
              {isSubmitting ? "Creating Scheduled Game…" : "Create And Add Another"}
            </Button>
            <Button onClick={handleSubmit} color="success" disabled={isPristine || isInvalid} outline={isPristine || isInvalid}>
              {isSubmitting ? "Creating Scheduled Game…" : "Create Scheduled Game"}
            </Button>
          </div>
        )}
      </DashboardFormFooter>
    </DashboardForm>
  );
}

ScheduledGameForm.propTypes = {
  gameId: PropTypes.string,
  initialValues: PropTypes.shape({})
};

export default ScheduledGameForm;
