import _orderBy from "lodash/orderBy";

import { getCurrentSeason, getCurrentSeasonBreadcrumbs } from "@/redux/seasons/selectors";

const selectRoot = state => state.ipadKeys;
const selectList = state => selectRoot(state).list;

export const getIpadKeysListBreadcrumbs = state => {
  const season = getCurrentSeason(state);
  const seasonBreadcrumbs = getCurrentSeasonBreadcrumbs(state);

  if (season && seasonBreadcrumbs) {
    return [
      ...seasonBreadcrumbs,
      {
        url: `/seasons/${season.id}/ipad-keys`,
        title: "iPad Keys"
      }
    ];
  }
};

export const getIpadKeysList = state => {
  const { ids, data } = selectList(state);

  const keys = ids.reduce(
    (result, id) => [
      ...result,
      {
        id,
        value: data[id].value,
        description: data[id].description
      }
    ],
    []
  );

  return _orderBy(keys, [({ value }) => value.toLowerCase()], ["asc"]);
};

export const getIpadKeysListIsLoaded = state => selectList(state).isLoaded;
export const getIpadKeysListIsLoading = state => selectList(state).isLoading;
export const getIpadKeysListIsForbidden = state => selectList(state).isForbidden;
