import { selectorFamily } from "recoil";
import { formatDate, formatTime } from "@/utils/date";
import Service from "@/services";
import { forceListUpdateState } from './atoms';
import { startDateFilterState, endDateFilterState, divisionsFilterState, queryStringFilterState, gameTypeFilterState } from "../../Filter/state";
import { currentPageState } from "../../Pagination/state";

export const scheduledGamesState = selectorFamily({
  key: 'scheduledGames',
  get: ({ seasonId }) => async ({ get }) => {
    get(forceListUpdateState);
    const startDate = get(startDateFilterState);
    const endDate = get(endDateFilterState);
    const divisions = get(divisionsFilterState);
    const queryString = get(queryStringFilterState);
    const gameType = get(gameTypeFilterState)
    const currentPage = get(currentPageState);
    const API_PAGE_SIZE = 20;
    
    return await getGames({startDate, endDate, divisions, queryString, gameType, currentPage, API_PAGE_SIZE, seasonId});
  }
});

export const getGames = async ({startDate, endDate, divisions, queryString, gameType, currentPage, API_PAGE_SIZE, seasonId}) => {
  if (!seasonId) {
    return { meta: {}, games: [] };
  }

  const formatGameType = (raw) => {
    return raw.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
  }

  const formatDivisions = ( d1, d2 ) => {
    return [ ...(new Set([ d1, d2 ].filter(d => d)))].sort().join(", ")
  }

  const getTitleOr = (s, obj) => (obj && 'title' in obj) ? obj['title'] : s;

  return await Service
    .api().seasons.season( seasonId ).scheduledGames.get({
      include: ["teams", "divisions"],
      pageSpec: { pageSize: API_PAGE_SIZE, pageNumber: currentPage },
      filters: { startDate, endDate, divisions, query: queryString, gameType } })
    .then(response => {

      if (!response.meta || !response.included || !response.data) {
        return {
          meta: {
            totalCount: 0,
            filteredCount: 0,
            totalPagesMeta: 1,
            resetMeta: Math.random()
          },
          games: []
        };
      }

      const divisions = !response.included ? {} :
        response.included
          .filter(x => x.type === "divisions")
          .reduce((out, division) => ({ ...out, [division.id]: division.attributes }), {});

      const teams = !response.included ? {} :
        response.included
          .filter(x => x.type === "teams")
          .reduce((out, team) => ({ ...out, [team.id]: team.attributes }), {});
      
      return {
        meta: {
          totalCount: response.meta['total-count'],
          filteredCount: response.meta['filtered-count'],
          totalPagesMeta: response.meta['total-pages'],
          resetMeta: Math.random()
        },
        games: response.data.map(game => {
          const homeTeamData = game.relationships.home_team.data;
          const visitorTeamData = game.relationships.visitor_team.data;
          const homeDivisionData = game.relationships.home_division.data;
          const visitorDivisionData = game.relationships.visitor_division.data;

          const homeTeam = homeTeamData ? getTitleOr('', teams[homeTeamData.id]) : '';
          const visitorTeam = visitorTeamData ? getTitleOr('', teams[visitorTeamData.id]) : '';
          const homeDivision = homeDivisionData ? getTitleOr('', divisions[homeDivisionData.id]) : '';
          const visitorDivision = visitorDivisionData ? getTitleOr('', divisions[visitorDivisionData.id]) : '';

          return {
            id: game.id,
            number: game.attributes.number,
            scheduledDate: formatDate(game.attributes.scheduled_start_time, "UTC"),
            scheduledTime: formatTime(game.attributes.scheduled_start_time, "UTC"),
            location: game.attributes.location,
            homeTeam,
            visitorTeam,
            homeDivision,
            visitorDivision,
            divisions: formatDivisions( homeDivision, visitorDivision ),
            gameType: formatGameType(game.attributes.game_type),
            scorekeeperName: game.attributes.scorekeeper.name,
            scorekeeperPhone: game.attributes.scorekeeper.phone,
            broadcastProvider: game.attributes.data.broadcaster
          }
        })
      };
    }
  );
}