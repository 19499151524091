import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Switch, Route, Link } from "react-router-dom";
import {
  Alert,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormText
} from "reactstrap";

import ArchivedSeasonContent from "@/layouts/ArchivedSeasonContent";
import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import PageLoader from "@/components/PageLoader";
import AbilityButton from "@/components/AbilityButton";
import { LockImg } from "@/components-16.8/LockImg";
import { SingleActionModal } from "@/components-16.8/SingleActionModal";
import { Can, canCheck } from "@/components/Ability";
import padlock from "@/components-16.8/LockImg/padlock.png";
import openPadlock from "@/components-16.8/LockImg/open-padlock.png";
import { HCR_ORAGNIZATIONS } from "@/constants/hcrOrganizations";

import { PlayersRoster, CoachesRoster } from "@/components/TeamRoster";

import useTeamRoster from "./hooks/useTeamRoster";
import { useUSAHRegistrySync } from "./hooks/useUSAHRegistrySync";
import { useHCRRegistrySync } from "./hooks/useHCRRegistrySync";
import { RecoilRoot } from "recoil";

import { useDispatch } from "react-redux";
import { loadingRoutine } from "@/redux/teamRoster";
import actions from "@/redux/teamRoster/actions";
import { useOTP } from "@/lib/core/useOTP";

import "./style.scss";
import { EditableTeamRosterHeader } from "@/components-16.8/EditTeamRosterHeader";

function TeamRosterShow(params) {
  return (
    <RecoilRoot>
      <TeamRosterShowInRecoil {...params} />
    </RecoilRoot>
  );
}

function TeamRosterShowInRecoil({ match: { params }, teamId: id, seasonId, isRosterLocking, lockRosters }) {
  const dispatch = useDispatch();
  const { isLoaded, isLoading, isForbidden, isOperating, isEditing, isArchived, breadcrumbs, team } = useTeamRoster({
    id,
    seasonId
  });

  const [showUSAHImport, setShowUSAHImport] = useState(false);
  const openUSAHImport = () => setShowUSAHImport(true);
  const closeUSAHImport = () => setShowUSAHImport(false);

  const [showHCRImport, setShowHCRImport] = useState(false);
  const openHCRImport = () => setShowHCRImport(true);
  const closeHCRImport = () => setShowHCRImport(false);

  const lockRostersCallback = () => {
    lockRosters({
      lock: true,
      successCb: () => {
        dispatch(actions.clear());
        dispatch(loadingRoutine({ id, seasonId }));
      }
    });
  };

  const unlockRostersCallback = () => {
    lockRosters({
      lock: false,
      successCb: () => {
        dispatch(actions.clear());
        dispatch(loadingRoutine({ id, seasonId }));
      }
    });
  };

  const { tab } = params;

  const { otp } = useOTP();

  const ignoreLock = canCheck({ I: "ignore", this: { type: "teamLocks" } });

  return (
    <ArchivedSeasonContent condition={isArchived}>
      <ProtectedLayout className="roster-page" condition={!isForbidden}>
        <PageLoader isLoaded={isLoaded} isLoading={isLoading}>
          <Breadcrumbs items={breadcrumbs} />

          {isLoaded && (
            <Fragment>
              <Head title={`${team.title} Roster`} />


              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div>
                  <EditableTeamRosterHeader team={team} />
                </div>
                <div style={{ marginTop: '17px', marginBottom: '25px' }}>
                  {(!team.data.rosterLocked || ignoreLock) && (
                    <>
                      <Can I="import" this={{ type: "roster", teamId: id }}>
                        <span className="import-from-text">Import From</span><br />
                      </Can>
                      
                      <ButtonGroup size="sm">
                        <AbilityButton
                          subject={{ type: "roster", teamId: id }}
                          action="import"
                          color="success"
                          to={`/seasons/${seasonId}/teams/${id}/roster/import-from-csv`}
                          tag={Link}
                          outline
                          disabled={isEditing || isOperating}
                          // {isEditing || isOperating || isImporting}
                          style={{ marginRight: 0 }}
                        >
                          CSV
                        </AbilityButton>
                        <AbilityButton
                          subject={{ type: "roster", teamId: id }}
                          action="import"
                          color="success"
                          onClick={openUSAHImport}
                          outline={!showUSAHImport}
                          style={{ marginRight: 0 }}
                        >
                          USA Hockey
                        </AbilityButton>
                        <AbilityButton
                          subject={{ type: "roster", teamId: id }}
                          action="import"
                          color="success"
                          onClick={openHCRImport}
                          outline={!showHCRImport}
                          style={{ marginRight: 0 }}
                        >
                          HCR
                        </AbilityButton>
                      </ButtonGroup>
                    </>
                  )}

                  {(!team.data.rosterLocked) && (
                    <Can I="update" this={{ type: "roster", teamId: id }}>
                      <Can I="update" this={{ type: "teamLocks" }}>
                        <LockRosterModal teamTitle={team.title} lockRostersCallback={lockRostersCallback} disabled={isRosterLocking || isEditing} />
                      </Can>
                    </Can>
                  ) || (
                      <>
                        <Can I="update" this={{ type: "roster", teamId: id }}>
                          <Can I="update" this={{ type: "teamLocks" }}>
                            <UnlockRosterModal teamTitle={team.title} isRosterLocking={isRosterLocking} unlockRostersCallback={unlockRostersCallback} />
                          </Can>
                        </Can>
                        <LockImg height="36" className="d-inline-block" />
                      </>
                    )}
                </div>
              </div>

              <ImportUSAHModal seasonId={seasonId} teamId={id} isOpen={showUSAHImport} close={closeUSAHImport} />
              <ImportHCRModal seasonId={seasonId} teamId={id} isOpen={showHCRImport} close={closeHCRImport} />

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tab === "players"
                    })}
                    tag={Link}
                    to={`/seasons/${seasonId}/teams/${id}/roster/players`}
                    disabled={isEditing || isOperating}
                  >
                    Players
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tab === "coaches"
                    })}
                    tag={Link}
                    to={`/seasons/${seasonId}/teams/${id}/roster/coaches`}
                    disabled={isEditing || isOperating}
                  >
                    Coaches
                  </NavLink>
                </NavItem>
              </Nav>

              <Switch>
                <Route
                  path={`/seasons/:seasonId/teams/:teamId/roster/players`}
                  render={() => (<PlayersRoster otp={otp} />)}
                />
                <Route
                  path={`/seasons/:seasonId/teams/:teamId/roster/coaches`}
                  render={() => (<CoachesRoster />)}
                />
              </Switch>
            </Fragment>
          )}
        </PageLoader>
      </ProtectedLayout>
    </ArchivedSeasonContent >
  );
}

TeamRosterShow.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  teamId: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired
};

export default TeamRosterShow;

const LockRosterModal = ({ teamTitle, lockRostersCallback, disabled }) => {
  return (
    <SingleActionModal
      buttonText={
        <>
          <img src={padlock} height={12} className="lock-teams-button" />
          <span className="lock-teams-button">Lock</span>
        </>
      }
      headerText={`Lock ${teamTitle}`}
      bodyText={
        <>
          <p>
            Locking prevents a team and its roster from being modified. Would you like to proceed
            with locking {teamTitle}?
          </p>
          <p>
            <a
              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
              target="_blank"
            >
              More Information
            </a>
          </p>
        </>
      }
      confirmText="Lock"
      buttonColor="secondary"
      confirmColor="success"
      confirmCallback={lockRostersCallback}
      disabled={disabled}
      className="lock-button"
    />
  )
}

const UnlockRosterModal = ({ teamTitle, unlockRostersCallback, isRosterLocking }) => {
  return (
    <SingleActionModal
      buttonText={
        <>
          <img src={openPadlock} height={12} className="lock-teams-button" />
          <span className="lock-teams-button">Unlock</span>
        </>
      }
      headerText={`Unlock ${teamTitle}`}
      bodyText={
        <>
          <p>
            Unlocking allows a team and its roster to be modified. Would you like to proceed with
            unlocking {teamTitle}?
          </p>
          <p>
            <a
              href="https://help.gamesheet.app/article/82-team-roster-locking-unlocking"
              editEnabled target="_blank"
            >
              More Information
            </a>
          </p>
        </>
      }
      confirmText="Unlock"
      buttonColor="secondary"
      confirmColor="success"
      confirmCallback={unlockRostersCallback}
      disabled={isRosterLocking}
      className="unlock-button"
    />
  )
}

const ImportUSAHModal = ({ seasonId, teamId, isOpen, close }) => {

  const {
    isImporting,
    isComplete,
    isFailed,
    failureMessage,
    startImport,
    ProgressBar,
    inputIsInvalid,
    setInputIsInvalid,
    reset: resetImportForm
  } = useUSAHRegistrySync({
    teamId,
    seasonId
  });

  const [registryId, setRegistryId] = useState("");
  const dispatch = useDispatch();

  const handleCancel = () => {
    setRegistryId("");
    resetImportForm();
    close();
  }

  useEffect(
    () => {
      if (isComplete) {
        setRegistryId("");
        resetImportForm();
        dispatch(loadingRoutine({ id: teamId, seasonId }));
        close();
      }
    },
    [isComplete]
  );

  useEffect(() => {
    if (!isOpen) return;

    function handleKeyUp(event) {
      switch (event.key) {
        case "Escape":
          handleCancel();
          break;
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [isOpen]);

  useEffect(() => {
    setInputIsInvalid(false)
  }, [registryId])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={handleCancel}>Import USA Hockey Roster</ModalHeader>
      <ModalBody>
        {isFailed && <Alert color="danger">{failureMessage}</Alert>}

        {isImporting && (
          <>
            <ProgressBar />
            <FormText color="muted">
              This process may take several minutes to complete. Do not close this window or leave the page.
            </FormText>
          </>
        )}

        {!isImporting && (
          <>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>USA Hockey Team ID</InputGroupText>
              </InputGroupAddon>
              <Input
                bsSize="sm"
                type="text"
                name="usah-id"
                placeholder="COH1071-989"
                style={{ borderRadius: 0, display: isImporting ? "none" : "block", backgroundPosition: "right" }}
                value={registryId}
                disabled={isImporting}
                onChange={e => setRegistryId(e.target.value)}
                invalid={inputIsInvalid}
              />
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  color="success"
                  outline
                  onClick={() => startImport(registryId)}
                  disabled={isImporting}
                >
                  {(isImporting && `Importing ${registryId} (${progress}%)...`) || "Import"}
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <FormText color="muted">Enter your team's USA Hockey ID to begin the import process.</FormText>
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

const ImportHCRModal = ({ seasonId, teamId, isOpen, close }) => {

  const {
    isImporting,
    isComplete,
    isFailed,
    failureMessage,
    startImport,
    ProgressBar,
    inputIsInvalid,
    setInputIsInvalid,
    reset: resetImportForm
  } = useHCRRegistrySync({
    teamId,
    seasonId
  });

  const sortedOrganizations = HCR_ORAGNIZATIONS.sort((a, b) => a.title.localeCompare(b.title));
  const defaultOrgIndex = 0;

  const [registryId, setRegistryId] = useState("");
  const [organisationId, setOrganisationId] = useState(sortedOrganizations[defaultOrgIndex].id);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setRegistryId("");
    setOrganisationId("");
    resetImportForm();
    close();
  }

  useEffect(
    () => {
      if (isComplete) {
        setRegistryId("");
        setOrganisationId("");
        resetImportForm();
        dispatch(loadingRoutine({ id: teamId, seasonId }));
        close();
      }
    },
    [isComplete]
  );

  useEffect(() => {
    if (!isOpen) return;

    setOrganisationId(sortedOrganizations[defaultOrgIndex].id);

    function handleKeyUp(event) {
      switch (event.key) {
        case "Escape":
          handleCancel();
          break;
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [isOpen]);


  useEffect(() => {
    setInputIsInvalid(false)
  }, [registryId])

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={handleCancel}>Import HCR Roster</ModalHeader>
      <ModalBody className="HCR-import-modal">
        {isFailed && <Alert color="danger">{failureMessage}</Alert>}

        {isImporting && (
          <>
            <ProgressBar title={registryId} />
            <FormText color="muted">
              This process may take several minutes to complete. Do not close this window or leave the page.
            </FormText>
          </>
        )}

        {!isImporting && (
          <>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Organization</InputGroupText>
              </InputGroupAddon>
              <Input
                type="select"
                size="sm"
                name="hcr-organisation"
                id="hcr-organization-select"
                onChange={e => setOrganisationId(e.target.value)}
                defaultValue={organisationId}
              >
                {sortedOrganizations.map(org => {
                  return (
                    <option key={org.id} value={org.id}>{org.title}</option>
                  )
                })}
              </Input>
            </InputGroup>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Team ID</InputGroupText>
              </InputGroupAddon>
              <Input
                size="sm"
                type="text"
                name="hcr-id"
                placeholder="2021532800052580"
                style={{ borderRadius: 0, display: isImporting ? "none" : "block", backgroundPosition: "right" }}
                value={registryId}
                disabled={isImporting}
                onChange={e => setRegistryId(e.target.value)}
                className="override-border-radius"
                invalid={inputIsInvalid}
              />
            </InputGroup>
            <div className="d-flex flex-row-reverse justify-content-between">
              <Button
                size="sm"
                color="success"
                outline
                onClick={() => startImport(registryId, organisationId)}
                disabled={isImporting}
              >
                {(isImporting && `Importing ${registryId} (${progress}%)...`) || "Import"}
              </Button>
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  )
}
