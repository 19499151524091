const selectRoot = state => state.currentSeason;
const selectData = state => selectRoot(state).data;
const selectMeta = state => selectRoot(state).meta;

export const getMeta = state => selectMeta(state);
export const getSeason = state => selectData(state);

export const getPenaltyClassOptions = state => {
    const { penaltySettings } = getSeason(state);
    return penaltySettings.penaltyClasses;
}
