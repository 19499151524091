import moment from "moment";
import { all } from 'redux-saga/effects'

import { GAME_TYPE_VALUES } from "@/constants/gameTypes";
import { BROADCAST_PROVIDER_VALUES } from "@/constants/broadcastProviders";

import { loadTeams } from "@/lib/api/teams";
import { loadAllScheduledGames } from "@/lib/api/schedule";

import { fetchList } from "@/redux/api/sagas";

export function makeTeamMapKey({ team, divisionId }) {
  return `${team.trim().toLowerCase()}@${divisionId.trim()}`;
}

export function* buildLookups({ seasonId }) {
  const [{ teams }, { scheduledGames }] = yield all([
    fetchList("teams", loadTeams, {
      seasonId,
      skipAbilitiesUpdate: true
    }),
    fetchList("scheduledGames", loadAllScheduledGames, {
      seasonId,
      skipAbilitiesUpdate: true
    })
  ])

  const teamMap = new Map();
  const divisionMap = new Map();
  teams.forEach(team => {
    teamMap.set(makeTeamMapKey({ team: team.title, divisionId: team.division.id }), team.id);
    divisionMap.set(team.division.title.trim().toLowerCase(), team.division.id);
  });

  const idList = scheduledGames.map(({ id }) => id)

  return { idList, teamMap, divisionMap };
}

export function parseID(id, idList, duplicateIds) {
  if (!id || id == "") {
    return [0];
  }

  if (!idList.includes(id)) {
    return [0, "Not found"]
  }

  if (duplicateIds.includes(id)) {
    return [0, "Duplicate"]
  }

  const asInt = parseInt(id)

  if (!asInt) {
    return [0, "Not an integer"]
  }

  return [asInt];
}

export function parseStartDate(startDateString) {
  if (startDateString === "") {
    return ["", "Can’t be blank"];
  }

  const startDate = moment(startDateString);

  if (!startDate.isValid()) {
    return ["", "Invalid format"];
  }

  return [startDate.format("YYYY-MM-DD")];
}

export function parseStartTime(startTimeString) {
  if (startTimeString === "") {
    return ["", "Can’t be blank"];
  }

  const startTime = moment(startTimeString, "hh:mmA");

  if (!startTime.isValid()) {
    return ["", "Invalid"];
  }

  return [startTime.format("HH:mm")];
}

export function findVisitorDivisionId({ divisionMap, visitorDivision, visitorTeam }) {
  if (!visitorDivision) {
    if (!visitorTeam) {
      return [""];
    } else {
      return ["", "Can’t be blank when visitor team is specified"];
    }
  } else {
    const divisionId = divisionMap.get(visitorDivision.trim().toLowerCase());
    if (!divisionId) {
      return ["", "Not found"];
    } else {
      return [divisionId];
    }
  }
}

export function findVisitorTeamId({ teamMap, visitorTeam, visitorDivision, visitorDivisionId }) {
  if (!visitorTeam || !visitorDivision || !visitorDivisionId) {
    return [""];
  } else {
    const teamId = teamMap.get(makeTeamMapKey({ team: visitorTeam, divisionId: visitorDivisionId }));
    if (!teamId) {
      return ["", "Not found"];
    } else {
      return [teamId];
    }
  }
}

export function findHomeDivisionId({ divisionMap, homeDivision }) {
  if (!homeDivision) {
    return ["", "Can’t be blank"];
  } else {
    const divisionId = divisionMap.get(homeDivision.trim().toLowerCase());
    if (!divisionId) {
      return ["", "Not found"];
    } else {
      return [divisionId];
    }
  }
}

export function findHomeTeamId({ teamMap, homeTeam, homeDivision, homeDivisionId }) {
  if (!homeTeam || !homeDivision || !homeDivisionId) {
    return [""];
  } else {
    const teamId = teamMap.get(makeTeamMapKey({ team: homeTeam, divisionId: homeDivisionId }));
    if (!teamId) {
      return ["", "Not found"];
    } else {
      return [teamId];
    }
  }
}

export function parseGameNumber(gameNumber) {
  if (gameNumber === "") {
    return ["", "Can’t be blank"];
  }

  return [gameNumber];
}

export function parseGameType(gameType) {
  if (gameType === "") {
    return ["", "Can’t be blank"];
  } else if (!GAME_TYPE_VALUES.includes(gameType)) {
    return [gameType, `Invalid. Allowed values: ${GAME_TYPE_VALUES.join(", ")}`];
  }

  return [gameType];
}

export function parseBroadcastProvider(broadcastProvider) {
  if (!broadcastProvider || broadcastProvider === "") {
    return [""];
  } else if (!BROADCAST_PROVIDER_VALUES.includes(broadcastProvider.toUpperCase())) {
    return [
      broadcastProvider,
      `Invalid; list of allowed values on the import page.`
    ];
  }

  return [broadcastProvider.toUpperCase()];
}
