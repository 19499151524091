import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import ContentSection from "@/components/ContentSection";

import Flags from "./components/Flags";
import Venue from "./components/Venue";
import Category from "./components/Category";
import GameType from "./components/GameType";
import Periods from "./components/Periods";
import Flood from "./components/Flood";
import Curfew from "./components/Curfew";
import FinalScore from "./components/FinalScore";
import Scorekeeper from "./components/Scorekeeper";

class Properties extends PureComponent {
  static propTypes = {
    flags: PropTypes.arrayOf(
      PropTypes.shape({
        event: PropTypes.string.isRequired
      })
    ),
    gameNumber: PropTypes.string,
    venue: PropTypes.shape({}),
    league: PropTypes.string,
    divisions: PropTypes.string,
    category: PropTypes.string,
    gameType: PropTypes.shape({}),
    periods: PropTypes.arrayOf(PropTypes.shape({})),
    firstFlood: PropTypes.bool,
    secondFlood: PropTypes.bool,
    curfew: PropTypes.shape({}),
    finalScore: PropTypes.shape({}),
    scorekeeper: PropTypes.shape({})
  };

  render() {
    const {
      gameNumber,
      venue,
      league,
      divisions,
      category,
      gameType,
      periods,
      firstFlood,
      secondFlood,
      curfew,
      finalScore,
      scorekeeper,
      flags
    } = this.props;

    return (
      <ContentSection>
        {flags.length > 0 ? (
          <Row>
            <Col>
              <Flags flags={flags} />
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col md="9">
            <Venue gameNumber={gameNumber} {...venue} />

            <Category league={league} divisions={divisions} category={category} gameType={gameType} />

            <Row>
              <Col md="4">
                <GameType {...gameType} />
              </Col>

              <Col md="4">
                <Periods periods={periods} />
              </Col>

              <Col md="4">
                <Flood firstFlood={firstFlood} secondFlood={secondFlood} />
                <Curfew {...curfew} />
              </Col>
            </Row>
          </Col>

          <Col md="3">
            <FinalScore {...finalScore} />
            <Scorekeeper {...scorekeeper} />
          </Col>
        </Row>
      </ContentSection>
    );
  }
}

export default Properties;
