import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";

import CareerStats from "./CareerStats";
import StatsBySeason from "./StatsBySeason";
import MajorPenalties from "./MajorPenalties";
import InfractionHistory from "./InfractionHistory";

import "./Report.scss";

function TeamMemberPenaltyReport(props) {
  const handlePrint = useCallback(() => window.print(), []);

  const {
    member: { firstName, lastName, externalId },
    memberTitle,
    careerStats,
    statsBySeason,
    majorPenalties,
    infractionHistory,
    isLoading,
    isLoaded
  } = props;

  return (
    <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
      <TitleBar
        title={`${firstName} ${lastName} — ${memberTitle} Penalty Report`}
        subtitle={`External ID: ${externalId}`}
      >
        <Button outline color="primary" size="sm" onClick={handlePrint}>
          Print
        </Button>
      </TitleBar>

      <CareerStats {...careerStats} />
      <StatsBySeason stats={statsBySeason} />
      <MajorPenalties penalties={majorPenalties} />
      <InfractionHistory history={infractionHistory} />
    </PageLoader>
  );
}

TeamMemberPenaltyReport.propTypes = {
  externalId: PropTypes.string.isRequired,
  member: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    externalId: PropTypes.string
  }),
  memberTitle: PropTypes.string.isRequired,
  careerStats: PropTypes.shape({}),
  statsBySeason: PropTypes.arrayOf(PropTypes.shape({})),
  majorPenalties: PropTypes.arrayOf(PropTypes.shape({})),
  infractionHistory: PropTypes.arrayOf(PropTypes.shape({})),
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool
};

TeamMemberPenaltyReport.defaultProps = {
  member: {
    firstName: "",
    lastName: "",
    externalId: ""
  },
  memberTitle: "",
  careerStats: {},
  statsBySeason: [],
  majorPenalties: [],
  infractionHistory: [],
  isLoaded: false,
  isLoading: false
};

export default TeamMemberPenaltyReport;
