import React from "react";
import PropTypes from "prop-types";
import { Switch, Redirect } from "react-router-dom";

import LoggedInRoute from "@/containers/LoggedInRoute";

import TeamRosterShow from "./pages/TeamRosterShow";
import TeamRosterPlayersAdd from "./pages/TeamRosterPlayersAdd";
import TeamRosterCoachesAdd from "./pages/TeamRosterCoachesAdd";
import TeamRosterPlayersEdit from "./pages/TeamRosterPlayersEdit";
import TeamRosterCoachesEdit from "./pages/TeamRosterCoachesEdit";
import TeamRosterCsvImport from "./pages/TeamRosterCsvImport";

const TeamRosterRoute = props => {
  const { match } = props;
  const {
    params: { seasonId, teamId, playerId, coachId }
  } = match;

  return (
    <Switch>
      <LoggedInRoute
        path={`${match.path}/players/:playerId/edit`}
        playerId={playerId}
        teamId={teamId}
        seasonId={seasonId}
        component={TeamRosterPlayersEdit}
      />
      <LoggedInRoute
        path={`${match.path}/coaches/:coachId/edit`}
        coachId={coachId}
        teamId={teamId}
        seasonId={seasonId}
        component={TeamRosterCoachesEdit}
      />
      <LoggedInRoute
        path={`${match.path}/players/add`}
        seasonId={seasonId}
        teamId={teamId}
        component={TeamRosterPlayersAdd}
      />
      <LoggedInRoute
        path={`${match.path}/coaches/add`}
        seasonId={seasonId}
        teamId={teamId}
        component={TeamRosterCoachesAdd}
      />
      <LoggedInRoute
        path={`${match.url}/import-from-csv`}
        seasonId={seasonId}
        teamId={teamId}
        component={TeamRosterCsvImport}
      />
      <LoggedInRoute path={`${match.path}/:tab`} seasonId={seasonId} teamId={teamId} component={TeamRosterShow} />

      <Redirect to={`${match.url}/players`} />
    </Switch>
  );
};

TeamRosterRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired,
      teamId: PropTypes.string.isRequired,
      playerId: PropTypes.string,
      coachId: PropTypes.string
    })
  }).isRequired
};

export default TeamRosterRoute;
