import { connect } from "react-redux";

import selectors from "@/redux/coachPenaltyReport/selectors";

import { coachPenaltyReportLoadingRoutine } from "@/redux/coachPenaltyReport/routines";
import actions from "@/redux/coachPenaltyReport/actions";

import Report from "@/components/TeamMemberPenaltyReport";

const {
  getCoachPenaltyReportMember,
  getCoachPenaltyReportCareerStats,
  getCoachPenaltyReportMajorPenalties,
  getCoachPenaltyReportInfractionHistory,
  getCoachPenaltyReportStatsBySeason,
  getCoachPenaltyReportIsLoading,
  getCoachPenaltyReportIsLoaded
} = selectors;

const mapStateToProps = state => ({
  member: getCoachPenaltyReportMember(state),
  memberTitle: "Bench Staff",
  careerStats: getCoachPenaltyReportCareerStats(state),
  statsBySeason: getCoachPenaltyReportStatsBySeason(state),
  majorPenalties: getCoachPenaltyReportMajorPenalties(state),
  infractionHistory: getCoachPenaltyReportInfractionHistory(state),
  isLoading: getCoachPenaltyReportIsLoading(state),
  isLoaded: getCoachPenaltyReportIsLoaded(state)
});

const mapDispatchToProps = (dispatch, { externalId }) => ({
  loadReport: () => dispatch(coachPenaltyReportLoadingRoutine({ externalId })),
  clearReport: () => dispatch(actions.report.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
