import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

export function usePenaltyCodesExport() {
    const { season } = useCurrentSeasonContext();

    const exportCodes = () => {
        // format each penalty type into a string
        const rows = season.penaltySettings.penaltyCodes.map((row) => {
            return `"${row.label}","${row.code}","${row.penaltyClass}","${row.duration}"`;
        });

        // add csv headers
        rows.unshift(`"Penalty Label","Code","Class","Duration"`);

        // join all rows into a single string
        const content = rows.reduce((collector, row) => {
            return collector + row + "\r\n";
        }, "");

        // create a hidden link and click it to download the csv
        const hiddenLink = document.createElement("a");
        hiddenLink.href = "data:text/csv;charset=utf-8," + encodeURIComponent(content);
        hiddenLink.download = `${season.title} Penalty List`;
        hiddenLink.click();
        hiddenLink.remove();
    }

    return exportCodes;
}
