import React from "react";
import PropTypes from "prop-types";

import Spinner from "@/components/Spinner";

import Option from "./Option";

import "./List.scss";

function ResourceSelect(props) {
  const { isLoading, options, label, isEnabled = false, toggleSelected } = props;

  return (
    <div className={`role-form-resource-select-list ${isEnabled ? "" : "disabled"}`}>
      <p className="role-form-resource-select-list__label">{label}</p>

      <div className="role-form-resource-select-list__input list-group list-group-flush">
        {isLoading ? (
          <Spinner className="role-form-resource-select-list__spinner" theme="loader" color="yellow" size="xxl" />
        ) : (
          options.map(({ id, ...rest }) => <Option key={id} id={id} toggleSelected={isEnabled ? toggleSelected : () => {}} {...rest} />)
        )}
      </div>
    </div>
  );
}

ResourceSelect.propTypes = {
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ),
  label: PropTypes.string,
  toggleSelected: PropTypes.func
};

ResourceSelect.defaultProps = {
  isLoading: false,
  isLoaded: false,
  options: [],
  label: "Select options",
  toggleSelected() {}
};

export default ResourceSelect;
