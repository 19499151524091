import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Table, Button } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import EmptyListMessage from "@/components/EmptyListMessage";

import { usePenaltyCodesList } from "./hooks/usePenaltyCodesList";
import { useNewPenaltyCodeForm } from "./hooks/useNewPenaltyCodeForm";
import { usePenaltyCodesImporterModal } from "./hooks/usePenaltyCodesImporterModal";
import { usePenaltyCodesExport } from "./hooks/usePenaltyCodesExport";

import NewPenaltyCodeForm from "./NewPenaltyCodeForm";
import PenaltyCode from "./PenaltyCode";
import PenaltyCodesImporter from "./PenaltyCodesImporter";

import "./PenaltyCodes.scss";

function PenaltyCodes({ value, onChange }) {
  const list = usePenaltyCodesList({ value, onChange });
  const form = useNewPenaltyCodeForm({ value, onChange });
  const importerModal = usePenaltyCodesImporterModal();
  const exportCodes = usePenaltyCodesExport();

  return (
    <Fragment>
      <TitleBar title="Penalty Types" titleTag="h4" slim>
        <Button type="button" size="sm" color="primary" outline onClick={exportCodes}>
          Download Penalty List
        </Button>
        <Button type="button" size="sm" color="success" outline onClick={importerModal.open}>
          Import from CSV
        </Button>
        <PenaltyCodesImporter {...importerModal} penaltyCodes={value} changePenaltyCodes={onChange} />
      </TitleBar>

      {list.isNotBlank || form.isActive ? (
        <Table
          striped
          borderless
          className={classNames("penalty-settings__penalty-code-table", {
            "mb-5": !list.isCollapsible
          })}
        >
          <thead>
            <tr>
              <th className="penalty-settings__penalty-code-table__label">Penalty Label</th>
              <th className="penalty-settings__penalty-code-table__code">Code</th>
              <th className="penalty-settings__penalty-code-table__class">Class</th>
              <th className="penalty-settings__penalty-code-table__duration">Duration</th>
            </tr>
          </thead>
          <tbody>
            <NewPenaltyCodeForm {...form} />
            {list.penaltyCodes.map(({ id, ...props }) => (
              <PenaltyCode key={id} {...props} />
            ))}
            {list.isCollapsible && (
              <tr className="show-all">
                <td colSpan="3">
                  <Button onClick={list.toggleCollapsed} color="link">
                    {list.isCollapsed ? `Show All (${list.totalCount})` : "Show fewer"}
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <EmptyListMessage>
          No penalty types specified.
          <br />
          <div className="mt-2">
            <Button color="success" outline onClick={importerModal.open}>
              Import from CSV
            </Button>{" "}
          </div>
        </EmptyListMessage>
      )}
    </Fragment>
  );
}

PenaltyCodes.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PenaltyCodes;
