import React, { Fragment } from "react";
import PropTypes from "prop-types";

import _trim from "lodash/trim";

import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import ContentSection from "@/components/ContentSection";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import FormItem from "@/components/FormItem";
import DivisionSelectFieldContainer from "@/containers/DivisionSelectFieldContainer";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";
import { Can } from "@/components/Ability";
import AbilityButton from "@/components/AbilityButton";
import { ImagePicker } from "./ImagePicker";
import { hasUrlParamOn } from "@/lib/core/hasUrlParamOn";

class TeamForm extends React.PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    id: PropTypes.string,
    title: PropTypes.string,
    externalId: PropTypes.string,
    roster: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    logoUrl: PropTypes.string,
    divisionId: PropTypes.string,
    allowChangeDivision: PropTypes.bool,
    isOperating: PropTypes.bool,
    isDeleting: PropTypes.bool,
    division: PropTypes.shape({
      id: PropTypes.string
    }),
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    submittingErrors: PropTypes.shape({}),
    updateSubmittingErrors: PropTypes.func
  };

  static defaultProps = {
    title: "",
    externalId: "",
    roster: {
      coaches: [],
      players: []
    },
    data: {},
    logoUrl: "",
    divisionId: "",
    allowChangeDivision: false,
    isOperating: false,
    isDeleting: false,
    division: { id: "" },
    onSubmit() {},
    onDelete() {},
    submittingErrors: {},
    updateSubmittingErrors: () => {}
  };

  defaultState = {
    title: this.props.title,
    externalId: this.props.externalId,
    divisionId: this.props.divisionId || (this.props.division || { id: "" }).id,
    logo: {
      file: null,
      url: this.props.logoUrl
    },
    badInputs: [],
    submitAttempt: false,
    isPristine: true,
    clear: 0 // so the useImagePicker can listen for when it should reset
  };

  state = this.defaultState;

  checkBadInputs = () => {
    const badInputs = [];
    if (!this.state.title) {
      badInputs.push("title");
    }
    if (!this.state.divisionId) {
      badInputs.push("division");
    }
    this.setState({
      badInputs: badInputs
    });
    return badInputs;
  };

  handleChange = ({ target }) => {
    this.props.updateSubmittingErrors({ name: target.name, value: "" });
    this.setState({ [target.name]: target.value, isPristine: false }, () => {
      if (this.state.submitAttempt) {
        this.checkBadInputs();
      }
    });
  };

  handleSubmit = ({ goBack = true }) => {
    const { id, roster, onSubmit, data } = this.props;
    const { title, externalId, divisionId, logo } = this.state;

    this.setState({
      submitAttempt: true,
      isPristine: true
    });

    const badInputs = this.checkBadInputs();
    if (badInputs.length > 0) {
      return;
    }

    onSubmit({
      id,
      attributes: {
        title: _trim(title),
        externalId: _trim(externalId),
        roster,
        data
      },
      logo,
      divisionId,
      goBack
    });

    if (!goBack) {
      this.setState({
        ...this.defaultState,
        clear: this.state.clear + 1
      });
    }
  };

  handleDelete = () => {
    const { id, onDelete } = this.props;

    id && onDelete(id);
  };

  hasSubmittingErrors = () => Object.values(this.props.submittingErrors).reduce((out, elem) => !!elem || out, false);

  render() {
    
    const placeholder = "https://imagedelivery.net/ErrQpIaCOWR-Tz51PhN1zA/e4d8b129-b545-4ed1-c2a5-2f7787e5c300/128";
    const { isOperating, allowChangeDivision, id, seasonId, isDeleting, submittingErrors } = this.props;
    const { title, externalId, divisionId, badInputs, isPristine, logo } = this.state;
    const showLogo = !!logo.url ? { ...logo, url: `${logo.url}/128` } : { url: placeholder }
    const cannotSave = badInputs.length > 0 || isPristine || this.hasSubmittingErrors();
    const infoText = "The image should be square, and we recommend dimensions of 256✕256px. Maximum file size is 2 MB. Supported formats are JPG and PNG."

    return (
      <DashboardForm className="team-form" onSubmit={this.handleSubmit} isOperating={isOperating || isDeleting}>
        <ContentSection>
          <FormItem>
            <FormGroup className="col-md-6 required">
              <Label for="teamName">Team Name</Label>
              <Input
                id="teamName"
                name="title"
                placeholder="e.g. Peterborough Petes"
                onChange={this.handleChange}
                value={title}
                className={badInputs.includes("title") ? "is-invalid" : ""}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label for="externalId">External ID</Label>
              <Input
                id="externalId"
                name="externalId"
                placeholder="Optional"
                onChange={this.handleChange}
                value={externalId}
                invalid={!!submittingErrors.externalId}
              />
              <FormFeedback>{submittingErrors.externalId}</FormFeedback>
            </FormGroup>
          </FormItem>
        </ContentSection>

        {(showLogo || allowChangeDivision) && (
          <ContentSection>
            <FormItem>              
              {/* Team Logo */}
              <FormGroup className="col-md-6">
                <Label for="logo-picker">Logo</Label>
                <ImagePicker infoText={infoText} clear={this.state.clear} maxSize={2} id="logo-picker" initialLogo={showLogo} onChange={this.handleChange} placeholder={placeholder}/>
              </FormGroup>
              
              {allowChangeDivision && (
                <FormGroup className="col-md-6 required">
                  <Label for="teamDivision">Division</Label>
                  <DivisionSelectFieldContainer
                    key={`division-select-field-${divisionId}`}
                    name="divisionId"
                    defaultValue={divisionId}
                    onChange={this.handleChange}
                    valid={!badInputs.includes("division")}
                  />
                </FormGroup>
              )}
            </FormItem>
          </ContentSection>
        )}

        <DashboardFormFooter>
          {id ? (
            <Fragment>
              <Can I="delete" this={{ type: "teams", id }}>
                <div className="dashboard-form-footer__secondary">
                  <ConfirmDeletionModal
                    resourceTitle={title}
                    resourceType="Team"
                    isOperating={isOperating}
                    isDeleting={isDeleting}
                    onDelete={this.handleDelete}
                  />
                </div>
              </Can>

              <div className="dashboard-form-footer__primary">
                <AbilityButton
                  subject={{ type: "teams", id }}
                  action="update"
                  type="button"
                  color="success"
                  onClick={this.handleSubmit}
                  outline={cannotSave}
                  disabled={cannotSave}
                >
                  Save Changes
                </AbilityButton>
              </div>
            </Fragment>
          ) : (
            <div className="dashboard-form-footer__primary">
              <AbilityButton
                subject={{
                  type: "teams",
                  ...(divisionId ? { divisionId } : { seasonId })
                }}
                action="create"
                type="button"
                color="success"
                onClick={() => this.handleSubmit({ goBack: false })}
              >
                Create and Add Another
              </AbilityButton>

              <AbilityButton
                subject={{
                  type: "teams",
                  ...(divisionId ? { divisionId } : { seasonId })
                }}
                action="create"
                type="button"
                color="success"
                onClick={this.handleSubmit}
              >
                Create Team
              </AbilityButton>
            </div>
          )}
        </DashboardFormFooter>
      </DashboardForm>
    );
  }
}

export default TeamForm;
