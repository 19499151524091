import { connect } from "react-redux";

import {
  isCurrentPlayerTeamFormDisplaying,
  getCurrentPlayerTeamFormValues,
  isCurrentPlayerTeamFormOperating,
  getCurrentPlayerTeamIds
} from "@/redux/players/selectors";

import { getTeam } from "@/redux/teams/selectors";

import {
  changePlayerTeamFormValues,
  changePlayerTeamFormTeamId,
  togglePlayerTeamFormDisplay
} from "@/redux/players/actions";

import { AddPlayerToTeamRoutine } from "@/redux/players/routines";

import PlayerTeamsList from "./PlayerTeamsList";

const mapStateToProps = state => ({
  displayTeamForm: isCurrentPlayerTeamFormDisplaying(state),
  teamFormValues: getCurrentPlayerTeamFormValues(state),
  isTeamFormOperating: isCurrentPlayerTeamFormOperating(state),
  getTeam: teamId => getTeam(state, teamId),
  playerTeamIds: getCurrentPlayerTeamIds(state)
});

const mapDispatchToProps = dispatch => ({
  changeTeamFormValues: payload => dispatch(changePlayerTeamFormValues(payload)),
  changeTeamFormTeamId: payload => dispatch(changePlayerTeamFormTeamId(payload)),
  toggleTeamFormDisplay: () => dispatch(togglePlayerTeamFormDisplay()),
  addPlayerToTeam: payload => dispatch(AddPlayerToTeamRoutine.trigger(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerTeamsList);
