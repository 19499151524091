import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Footer.scss";

const Footer = ({ className }) => {
  const year = new Date().getFullYear();

  return (
    <footer className={classNames("footer", className)}>
      <div className="container">
        <div className="row">
          <div className="col">
            <p>Gamesheet Dashboard. Patents pending. Copyright {year} Gamesheet Inc.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
