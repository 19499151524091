import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Head from "@/components/Head";
import Breadcrumbs, { BreadcrumbsProps } from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import ArchivedSeasonContent from "@/layouts/ArchivedSeasonContent";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import TeamCoachForm from "@/components/TeamCoachForm";
import PageLoader from "@/components/PageLoader";

class TeamRosterCoachesEdit extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        coachId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    teamId: PropTypes.string.isRequired,
    isTeamReady: PropTypes.bool.isRequired,
    isTeamOperating: PropTypes.bool.isRequired,
    isCoachReady: PropTypes.bool.isRequired,
    isCoachOperating: PropTypes.bool.isRequired,
    isCoachForbidden: PropTypes.bool.isRequired,
    isCoachArchived: PropTypes.bool.isRequired,
    team: PropTypes.shape({}),
    coachesRoster: PropTypes.arrayOf(PropTypes.shape({})),
    coach: PropTypes.shape({}),
    teamCoach: PropTypes.shape({}),
    coachFullName: PropTypes.string,
    breadcrumbs: BreadcrumbsProps,
    loadTeam: PropTypes.func,
    loadCoach: PropTypes.func,
    updateTeam: PropTypes.func,
    clearCoach: PropTypes.func
  };

  static defaultProps = {
    team: null,
    coach: null,
    coachesRoster: [],
    teamCoach: null,
    coachFullName: null,
    breadcrumbs: null,
    loadTeam() {},
    loadCoach() {},
    updateTeam() {},
    clearCoach() {}
  };

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  coachId = this.props.match.params.coachId;

  componentDidMount() {
    const { loadTeam, loadCoach, seasonId, teamId } = this.props;

    loadCoach({ id: this.coachId, seasonId });
    loadTeam(teamId);
  }

  componentWillUnmount() {
    this.props.clearCoach();
  }

  goBack = () => {
    window.history.go(-1);
  };

  handleNextCoachesRoster = nextCoachesRoster => {
    const { team, updateTeam } = this.props;

    const attributes = {
      title: team.title,
      externalId: team.externalId,
      roster: { ...team.roster, coaches: nextCoachesRoster },
      data: team.data
    };

    updateTeam({
      seasonId: team.season.id,
      divisionId: team.division.id,
      attributes,
      id: team.id,
      logo: {
        url: team.logoUrl,
        file: null
      },
      tab: "players",
      redirect: () => {
        this.goBack();
      }
    });

    this.setState(state => ({ ...state, loading: true }));
  };

  render() {
    const {
      teamId,
      isTeamOperating,
      isTeamReady,
      isCoachOperating,
      isCoachReady,
      isCoachForbidden,
      isCoachArchived,
      breadcrumbs,
      coachesRoster,
      teamCoach,
      coach,
      team
    } = this.props;

    const { loading } = this.state;

    return (
      <ArchivedSeasonContent condition={isCoachArchived}>
        <ProtectedLayout
          subject={{ type: "teams", id: teamId }}
          action="update"
          skip={!isCoachReady && !isCoachForbidden}
        >
          <PageLoader isLoading={isCoachOperating || loading} isLoaded={isCoachReady && !loading}>
            <Head title="Edit Coach" />

            <Breadcrumbs items={breadcrumbs} />

            {isCoachReady && <TitleBar title="Edit Coach" />}

            {isTeamReady && isCoachReady ? (
              <TeamCoachForm
                isOperating={isTeamOperating}
                coachesRoster={coachesRoster}
                coachId={coach.id}
                onSubmit={this.handleNextCoachesRoster}
                onDelete={this.handleNextCoachesRoster}
                rosterLocked={team.data.rosterLocked}
                {...teamCoach}
              />
            ) : (
              <PageLoader isLoading={isTeamOperating} />
            )}
          </PageLoader>
        </ProtectedLayout>
      </ArchivedSeasonContent>
    );
  }
}

export default TeamRosterCoachesEdit;
