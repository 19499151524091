import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Properties from "./components/Properties";
import TeamsLayout from "./components/TeamsLayout";
import Shootout from "./components/Shootout";
import Referees from "./components/Referees";
import RefereeReports from "./components/RefereeReports";
import GameNotes from "./components/GameNotes";
import FairPlayPoints from "./components/FairPlayPoints";

import ShowIf from "@/components-16.8/ShowIf";

import "./Gamesheet.scss";

class Gamesheet extends PureComponent {
  static propTypes = {
    properties: PropTypes.shape({}).isRequired,
    visitor: PropTypes.shape({
      title: PropTypes.string.isRequired
    }).isRequired,
    home: PropTypes.shape({
      title: PropTypes.string.isRequired
    }).isRequired,
    referees: PropTypes.arrayOf(PropTypes.shape({})),
    refereeReports: PropTypes.arrayOf(PropTypes.shape({})),
    scorekeeperNotes: PropTypes.arrayOf(PropTypes.shape({})),
    shootout: PropTypes.shape({})
  };

  render() {
    const { properties, visitor, home, shootout, referees, refereeReports, scorekeeperNotes } = this.props;

    return (
      <div className="gamesheet">
        <Properties {...properties} />
        <TeamsLayout home={home} visitor={visitor} />
        <Shootout {...shootout} visitorTitle={visitor.title} homeTitle={home.title} />
        <Referees referees={referees} />
        <RefereeReports reports={refereeReports} />
        <GameNotes notes={scorekeeperNotes} />
        <ShowIf cond={properties.fppEnabled} value={true}>
          <FairPlayPoints home={home} visitor={visitor} />
        </ShowIf>
      </div>
    );
  }
}

export default Gamesheet;
