import React, { PureComponent } from "react";
// import PropTypes from "prop-types";

import ProtectedLayout from "@/layouts/ProtectedLayout";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";

import CoachPenaltyReportCoachesList from "@/components/CoachPenaltyReportCoachesList";

class SearchPage extends PureComponent {
  render() {
    return (
      <ProtectedLayout subject="reports" action="read">
        <Head title="Bench Staff Penalty Reports" />

        <Breadcrumbs
          items={[
            { title: "Reports" },
            {
              title: "Bench Staff Penalty Reports",
              url: "/reports/bench-staff-penalty"
            }
          ]}
        />

        <TitleBar title="Search Bench Staff" />

        <CoachPenaltyReportCoachesList />
      </ProtectedLayout>
    );
  }
}

export default SearchPage;
