import React from "react";

import Head from "@/components/Head";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import SeasonForm, { useSeasonFormClearing } from "@/components/SeasonForm";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import useCurrentLeagueContext from "@/hooks/useCurrentLeagueContext";

function NewSeason() {
  const {
    leagueId,
    leagueAssociationId,
    leagueIsLoaded,
    leagueIsLoading,
    leagueIsForbidden,
    leagueBreadcrumbs
  } = useCurrentLeagueContext();

  useSeasonFormClearing();

  return (
    <ProtectedLayout
      subject={{ type: "seasons", leagueId }}
      action="create"
      skip={!leagueIsLoaded && !leagueIsForbidden}
      className="seasons-page"
    >
      <Head title="New Season" />

      <PageLoader isLoading={leagueIsLoading} isLoaded={leagueIsLoaded}>
        <Breadcrumbs items={leagueBreadcrumbs} />

        <TitleBar title="New Season" />

        <SeasonForm associationId={leagueAssociationId} leagueId={leagueId} />
      </PageLoader>
    </ProtectedLayout>
  );
}

export default NewSeason;
