import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import { handleActions } from "redux-actions";

import actions from "../actions";
import { loadingRoutine, roleLevelLoadingRoutine } from "../routines";

function getFailureCode(error) {
  const responseStatus = _get(error, "response.status");
  const errorObject = _get(error, "response.data.errors[0]", {
    source: { pointer: null },
    code: null
  });

  if (responseStatus === 403 && _get(errorObject, "source.pointer") === "/" && errorObject.code === "archived") {
    return "archived";
  } else if (responseStatus === 401) {
    return "unauthorized";
  } else if (responseStatus === 404) {
    return "notFound";
  }
}

function reduceAdding(state, { payload: { role, resourceTitle } }) {
  return [
    ...state,
    {
      ...role,
      resourceTitle,
      isLoading: false,
      isLoaded: true,
      isFailed: false,
      failureCode: ""
    }
  ];
}

function reduceChanging(state, { payload: { initRole, role, resourceTitle } }) {
  return state.map(({ title, level, ...rest }) => {
    if (title === initRole.title && _isEqual(level, initRole.level)) {
      return {
        ...role,
        resourceTitle,
        isLoading: false,
        isLoaded: true,
        isFailed: false,
        failureCode: ""
      };
    }

    return { title, level, ...rest };
  });
}

function reduceRemoving(state, { payload: role }) {
  return state.filter(({ title, level }) => title !== role.title || !_isEqual(level, role.level));
}

function reduceFormLoading(
  state,
  {
    payload: {
      user: { roles }
    }
  }
) {
  return [
    ...(roles || []).map(role => ({
      ...role,
      resourceTitle: "",
      isLoaded: false,
      isLoading: false,
      isFailed: false,
      failureCode: ""
    }))
  ];
}

function reduceLoadingRequest(state, { payload: { type, id } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoading: true };
    }

    return { level, ...rest };
  });
}

function reduceLoadingSuccess(state, { payload: { type, id, title } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoaded: true, resourceTitle: title };
    }

    return { level, ...rest };
  });
}

function reduceLoadingFailure(state, { payload: { type, id, status, error, failureCode = "" } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return {
        ...rest,
        level,
        failureCode: failureCode || getFailureCode(error),
        isFailed: true
      };
    }

    return { level, ...rest };
  });
}

function reduceLoadingFulfill(state, { payload: { type, id } }) {
  return state.map(({ level, ...rest }) => {
    if (level.type === type && level.id === id) {
      return { ...rest, level, isLoading: false };
    }

    return { level, ...rest };
  });
}

export default handleActions(
  {
    [actions.addRole]: reduceAdding,
    [actions.changeRole]: reduceChanging,
    [actions.removeRole]: reduceRemoving,
    [loadingRoutine.SUCCESS]: reduceFormLoading,
    [roleLevelLoadingRoutine.REQUEST]: reduceLoadingRequest,
    [roleLevelLoadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [roleLevelLoadingRoutine.FAILURE]: reduceLoadingFailure,
    [roleLevelLoadingRoutine.FULFILL]: reduceLoadingFulfill,
    [actions.reset]: () => []
  },
  []
);
