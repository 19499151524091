import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";
import { parsePenaltySettings, parsePenaltyDurationsFromLengths } from "@/utils/standardizePenaltySettings";

const initialState = {
  id: "",
  title: "",
  externalId: "",
  association: { id: "", title: "" },
  league: { id: "", title: "" },
  startDate: null,
  endDate: null,
  generalSettings: {
    periodLengths: {},
    gameCategories: [],
    signatures_required: "",
    shootout: false
  },
  statsSettings: {
    assistValue: 0,
    goalValue: 0,
    playerStatsDisplay: ""
  },
  penaltySettings: {
    penaltyCodes: [],
    penaltyDurations: [],
    penaltyClasses: [],
    penaltyLengths: []
  },
  flaggingSettings: {
    criteria: {},
    penalties: []
  },
  playerOfTheGame: null,
  liveScoringMode: "disabled",
};

function reduceLoadingSuccess(state, { payload: { season } }) {
  const {
    id,
    title,
    externalId,
    association,
    league,
    settings,
    flaggedPenalties,
    flaggingCriteria,
    playerOfTheGame,
    startDate,
    endDate,
    liveScoringMode,
  } = season;

  let { penaltyCodes, penaltyDurations } = parsePenaltySettings(settings);
  let penaltyClasses = settings.penaltyClasses || [];
  let penaltyLengths = settings.penaltyLengths || [];
  // fallback to penaltyLengths if we have lengths but not durations
  if (penaltyDurations.length <= 0 && penaltyLengths.length > 0) {
    penaltyDurations = parsePenaltyDurationsFromLengths(settings);
  }

  return {
    id,
    title,
    externalId,
    startDate,
    endDate,
    association: { id: association.id, title: association.title },
    league: { id: league.id, title: league.title },
    generalSettings: {
      periodLengths: settings.periodLengths,
      gameCategories: settings.gameCategories || [],
      signatures_required: settings.signaturesRequired || "head_coach",
      default_player_status: settings.defaultPlayerStatus || "",
      shootout: settings.shootout || false
    },
    statsSettings: {
      assistValue: settings.assistValue,
      goalValue: settings.goalValue,
      playerStatsDisplay: settings.playerStatsDisplay,
      pointSystem: settings.pointSystem,
      fairPlayPoints:
        "fairPlayPoints" in settings
          ? settings.fairPlayPoints
          : {
              isEnabled: false,
              maxPenaltyMinutes: 16,
              pointValue: 1
            },
      tieBreakers: settings.tieBreakers,
      maxGoalDifferential: settings.maxGoalDifferential
    },
    penaltySettings: {
      penaltyCodes,
      penaltyDurations,
      penaltyClasses,
      penaltyLengths,
    },
    flaggingSettings: {
      criteria: flaggingCriteria || {},
      penalties: flaggedPenalties || []
    },
    playerOfTheGame,
    liveScoringMode,
    leagueAppMode: settings.leagueAppMode || "disabled"
  };
}

export default handleActions(
  {
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
