import Papa from "papaparse";
import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";

// these columns must always be present
const NECESSARY_COLUMNS = ["label", "code"];

// these columns are optional, but if one is present, both must be
const OPTIONAL_COLUMNS = ["penaltyClass", "duration"];

export function validate(rows) {
  const validatedRows = rows.reduce((result, { code, label, penaltyClass, duration }, index) => {
    const blankLabel = label.trim() === "";
    const blankCode = code.trim() === "";
    const blankClass = penaltyClass.trim() === "";
    const blankDuration = duration.replace(";","").trim() === "";
    const dupeCode = !!result.find(rec => rec.code === code);
    let invalid = blankCode || blankLabel || (!blankClass && blankDuration) || dupeCode;

    return [
      ...result,
      {
        code,
        label,
        penaltyClass,
        duration,
        blankLabel,
        blankCode,
        blankClass,
        blankDuration,
        dupeCode,
        lineNumber: index + 2,
        invalid
      }
    ];
  }, []);

  return validatedRows.filter(({ invalid }) => invalid);
}

function reader(file) {
  return new Promise(resolve => {
    Papa.parse(file, {
      delimiter: "",
      newline: "",
      skipEmptyLines: "greedy",
      header: true,
      transformHeader: h => {
        const header = _camelCase(_trim(h).toLowerCase());

        if (header === "penaltyLabel") return "label";
        if (header === "class") return "penaltyClass";

        return header;
      },
      transform: (value, column) => {
        if (column === "code") {
          return value
            .trimEnd()
            .trimStart()
            .toUpperCase();
        }

        return value.trimEnd().trimStart();
      },
      delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: res => resolve(res)
    });
  });
}

export async function read(file) {
  const { data: rows, meta, errors } = await reader(file);

  if (errors.length > 0) {
    return { error: "CSV file is invalid" };
  } else if (rows.length === 0) {
    return { error: "CSV file is empty" };
  }

  const columns = meta.fields;
  const missingColumns = NECESSARY_COLUMNS.filter(column => !columns.includes(column));

  if (missingColumns.length > 0) {
    return { error: "CSV file has incorrect headers" };
  }

  const hasOneOptionalColumn = OPTIONAL_COLUMNS.some(column => columns.includes(column));
  const hasAllOptionalColumns = OPTIONAL_COLUMNS.every(column => columns.includes(column));

  if (hasOneOptionalColumn && !hasAllOptionalColumns) {
    return { error: "CSV file has incorrect headers" };
  }

  return { rows };
}
