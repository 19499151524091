import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, ModalBody, ModalFooter, Table } from "reactstrap";
import { FilePicker } from "react-file-picker";

function CSVFilePicker({ onSelect, parseError }) {
  return (
    <Fragment>
      <ModalBody>
        {parseError && <p className="alert alert-danger">{parseError}</p>}
        <Table borderless size="sm" className="csv-template">
          <caption>Expected Layout of CSV</caption>
          <thead>
            <tr>
              <th>Penalty Label</th>
              <th>Code</th>
              <th>Class</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Holding</td>
              <td>HO</td>
              <td>Minor</td>
              <td>1;1.5;2</td>
            </tr>
            <tr>
              <td>Body Checking</td>
              <td>BC</td>
              <td>Minor</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Illegal Equipment</td>
              <td>IE</td>
              <td>Minor</td>
              <td>2;3</td>
            </tr>
            <tr>
              <td>Slashing</td>
              <td>SL</td>
              <td>Major</td>
              <td>5</td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <FilePicker onChange={onSelect}>
          <Button className="btn btn-success" type="button">
            Select File
          </Button>
        </FilePicker>
      </ModalFooter>
    </Fragment>
  );
}

CSVFilePicker.propTypes = {
  parseError: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default CSVFilePicker;
