import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import PenaltyLabel from "./PenaltyLabel";
import PlayerCode from "@/components/Gamesheet/components/TeamsLayout/components/PlayersLineup/components/PlayerCode";
import { formatPeriodName } from "../../../formatPeriodName";

class Penalty extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    period: PropTypes.string,
    member: PropTypes.string,
    memberName: PropTypes.string,
    length: PropTypes.string,
    code: PropTypes.string,
    timeOff: PropTypes.string,
    timeStart: PropTypes.string,
    timeOn: PropTypes.string,
    servedBy: PropTypes.string,
    label: PropTypes.string,
    servedByName: PropTypes.string
  };

  static defaultProps = {
    period: "N/A",
    member: "N/A",
    memberName: "N/A",
    length: "N/A",
    code: "N/A",
    timeOff: "N/A",
    timeStart: "N/A",
    timeOn: "N/A",
    servedBy: "N/A",
    servedByName: "N/A",
    label: "N/A"
  };

  render() {
    const {
      id,
      period,
      member,
      memberName,
      length,
      code,
      timeOff,
      timeStart,
      timeOn,
      servedBy,
      servedByName,
      label
    } = this.props;

    return (
      <tr>
        <td>{formatPeriodName(period)}</td>
        <td>
          <PlayerCode scope="memberno" id={id} code={member} name={memberName} />
        </td>
        <td>{length}</td>
        <td className="text-uppercase">
          <PenaltyLabel id={id} scope="penalty" label={label} code={code} />
        </td>
        <td>{timeOff}</td>
        <td>{timeStart}</td>
        <td>{timeOn}</td>
        <td>
          <PlayerCode scope="servedBy" id={id} code={servedBy} name={servedByName} />
        </td>
      </tr>
    );
  }
}

export default Penalty;
