const VALID_POSITIONS = {
  player: ["forward", "defence", "goalie", "left_wing", "right_wing", "centre", "transition"],
  coach: [
    "head_coach",
    "assistant_coach",
    "trainer",
    "assistant_trainer",
    "manager",
    "head_coach_at_large",
    "assistant_coach_at_large",
    "trainer_at_large"
  ]
};

const ALL_VALID_POSITIONS = [
  ...VALID_POSITIONS.player,
  ...VALID_POSITIONS.coach
];

export default function validatePosition({ type, position }) {
  if (position === "") {
    return [];
  }

  const isValidPosition =
    type === "player" || type === "coach"
      ? (VALID_POSITIONS[type] || []).includes(position.toLowerCase())
      : ALL_VALID_POSITIONS.includes(position);

  if (!isValidPosition) {
    return [{ field: "position", error: "invalid" }];
  }

  return [];
}
