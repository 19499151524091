import React from "react";
import PropTypes from "prop-types";

import useScrollTo from "@/hooks/useScrollTo";

const GameFlagsList = ({ flags, noLink = false }) => {
  const message = ({ event, code, label, member, team }) => {
    let msg;

    const { scrollTo: scrollToPenalties } = useScrollTo("penalties");

    switch (event) {
      case "scorekeeper_timeout_note":
        msg = `Timeout by ${team}`;
        break;
      case "scorekeeper_injury_note":
        msg = `Injury to ${member} on ${team}`;
        break;
      case "scorekeeper_general_note":
        msg = `General note by scorekeeper`;
        break;
      case "referee_penalty_note":
        msg = `Penalty note by referee on ${code}`;
        break;
      case "referee_game_note":
        msg = "Game note by referee";
        break;
      case "non_affiliated_player":
        msg = `Non-affiliated Player ${member} on ${team}`;
        break;
      case "added_at_game_time":
        msg = `Player ${member} on ${team} added at game time`;
        break;
      case "penalty":
        let text = code === label ? code + " penalty" : code + " - " + label;
        msg = noLink ? (
          <span>{text}</span>
        ) : (
          <a role="button" onClick={scrollToPenalties}>
            {text}
          </a>
        );
        break;
      case "suspension_served":
        msg = `Suspension served by ${member} on ${team}`;
        break;
      case "unlocked":
        msg = "Game was unlocked";
        break;
      default:
        msg = "Unexpected flag";
    }

    return msg;
  };

  return (
    <ul>
      {flags.map((flag, i) => (
        <li key={message(flag) + i}>{message(flag)}</li>
      ))}
    </ul>
  );
};

GameFlagsList.propTypes = {
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired
    })
  ).isRequired
};

export default GameFlagsList;
