import moment from "moment";
import { handleActions } from "redux-actions";

import { loadGameFormRoutine } from "../routines";
import actions from "../actions";

const initField = value => ({
  error: null,
  isDirty: false,
  value
});

const initialState = {
  date: {
    value: "",
    error: null,
    isDirty: false
  },
  startTime: {
    value: "",
    error: null,
    isDirty: false
  },
  endTime: {
    value: "",
    error: null,
    isDirty: false
  },
  curfewTime: {
    value: "",
    error: null,
    isDirty: false
  },
  category: {
    value: "",
    error: null,
    isDirty: false
  },
  number: {
    value: "",
    error: null,
    isDirty: false
  },
  gameType: {
    value: "",
    error: null,
    isDirty: false
  },
  gamesPlayed: {
    value: "",
    error: null,
    isDirty: false
  },
  gamesTotal: {
    value: "",
    error: null,
    isDirty: false
  },
  location: {
    value: "",
    error: null,
    isDirty: false
  }
};

const initFields = (state, { payload: { game } }) => {
  const startTime = game.startTime && moment(game.startTime).utc();
  const endTime = game.endTime && moment(game.endTime).utc();
  const curfewTime = game.curfew.time && moment(game.curfew.time).utc();

  return {
    date: initField(startTime ? startTime.format("YYYY-MM-DD") : ""),
    startTime: initField(startTime ? startTime.format("HH:mm") : ""),
    endTime: initField(endTime ? endTime.format("HH:mm") : ""),
    curfewTime: initField(curfewTime ? curfewTime.format("HH:mm") : ""),
    category: initField(game.category),
    number: initField(game.number),
    gameType: initField(game.gameType.title),
    gamesPlayed: initField(game.gameType.gamesPlayed),
    gamesTotal: initField(game.gameType.gamesTotal),
    location: initField(game.location)
  };
};

const changeField = (state, { payload: { name, value } }) => ({
  ...state,
  [name]: { ...state[name], value }
});

export default handleActions(
  {
    [loadGameFormRoutine.SUCCESS]: initFields,
    [actions.changeField]: changeField,
    [actions.clear]: () => ({ ...initialState })
  },
  initialState
);
