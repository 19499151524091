import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Field({ error, value }) {
  return (
    <td
      className={classNames({
        "scheduled-games-csv-import__error__table__error": !!error
      })}
    >
      {value !== "" && (
        <span
          className={classNames({
            "scheduled-games-csv-import__error__table__error__value": !!error
          })}
        >
          {value}
        </span>
      )}
      {error && <span className="scheduled-games-csv-import__error__table__error__text">{error}</span>}
    </td>
  );
}

Field.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string
};

Field.defaultProps = {
  error: null,
  value: ""
};

export default Field;
