import { connect } from "react-redux";

import selectors from "@/redux/coachPenaltyReport/selectors";
import actions from "@/redux/coachPenaltyReport/actions";

import SearchBar from "./SearchBar";

const {
  getCoachPenaltyReportListSearchNextQuery,
  getCoachPenaltyReportListSearchCurrentQuery,
  getCoachPenaltyReportListSearchQueryIsDirty,
  getCoachPenaltyReportListIsLoading
} = selectors;

const mapStateToProps = state => ({
  nextQuery: getCoachPenaltyReportListSearchNextQuery(state),
  currentQuery: getCoachPenaltyReportListSearchCurrentQuery(state),
  isDirty: getCoachPenaltyReportListSearchQueryIsDirty(state),
  isLoading: getCoachPenaltyReportListIsLoading(state)
});

const mapDispatchToProps = dispatch => ({
  setQuery: query => dispatch(actions.list.search.setNextQuery(query)),
  commit: () => dispatch(actions.list.search.commit()),
  clear: () => dispatch(actions.list.search.clear())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
