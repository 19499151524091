import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { Row, FormGroup, Button } from "reactstrap";

import { Can } from "@/components/Ability";

import {
  getFullRoleName,
  getIsValid,
  getIsInvalid,
  getIsInitializing,
  getRole,
  getInitRole,
  getResourceTitle,
  getIsPristine
} from "@/redux/roleForm/selectors";

import actions from "@/redux/roleForm/actions";

import TitleBar from "@/components/TitleBar";
import PageLoader from "@/components/PageLoader";
import DashboardForm from "@/components/DashboardForm";
import DashboardFormFooter from "@/components/DashboardFormFooter";
import ConfirmDeletionModal from "@/components/ConfirmDeletionModal";

import TitleSelect from "./TitleSelect";
import AssociationSelect from "./AssociationSelect";
import LeagueSelect from "./LeagueSelect";
import SeasonSelect from "./SeasonSelect";
import DivisionSelect from "./DivisionSelect";
import TeamSelect from "./TeamSelect";
import GlobalUserCheckbox from "./GlobalUserCheckbox";

function RoleForm({ onSubmit, onRemove }) {
  const dispatch = useDispatch();
  const isValid = useSelector(getIsValid);
  const isInvalid = useSelector(getIsInvalid);
  const isInitializing = useSelector(getIsInitializing);
  const role = useSelector(getRole);
  const initRole = useSelector(getInitRole);
  const resourceTitle = useSelector(getResourceTitle);
  const isPristine = useSelector(getIsPristine);
  const reset = useCallback(() => dispatch(actions.reset()), [dispatch]);
  const fullRoleName = useSelector(state => getFullRoleName(state));

  const submit = useCallback(
    () => {
      onSubmit({ initRole, role, resourceTitle });
      reset();
    },
    [onSubmit, initRole, role, resourceTitle, reset]
  );

  const remove = useCallback(
    () => {
      onRemove(initRole);
      reset();
    },
    [onRemove, initRole, reset]
  );

  if (isInitializing) {
    return <PageLoader isLoading />;
  }

  return (
    <Fragment>
      <TitleBar title={initRole ? "Edit Role" : "Add Role"} />

      <DashboardForm onSubmit={submit}>
        <Row form>
          <FormGroup className="col-md-3">
            <TitleSelect />
          </FormGroup>

          <Can I="update" this={{ type: "all" }}>
            <FormGroup className="col-md-3 d-flex align-items-center pl-4">
              <GlobalUserCheckbox />
            </FormGroup>
          </Can>
        </Row>

        <Row form>
          <FormGroup className="col-md-2">
            <AssociationSelect />
          </FormGroup>

          <FormGroup className="col-md-2">
            <LeagueSelect />
          </FormGroup>

          <FormGroup className="col-md-2">
            <SeasonSelect />
          </FormGroup>

          <FormGroup className="col-md-3">
            <DivisionSelect />
          </FormGroup>

          <FormGroup className="col-md-3">
            <TeamSelect />
          </FormGroup>
        </Row>

        <DashboardFormFooter>
          <div className="dashboard-form-footer__secondary">
            <Button outline size="sm" color="secondary" onClick={reset}>
              Cancel
            </Button>

            {initRole && <ConfirmDeletionModal resourceType="Role" resourceTitle={fullRoleName} onDelete={remove} />}
          </div>

          <div className="dashboard-form-footer__primary">
            <Button
              type="submit"
              outline={isInvalid || isPristine}
              color={isInvalid || isPristine ? "secondary" : "success"}
              disabled={isInvalid || isPristine}
            >
              {isValid
                ? initRole
                  ? `Change Role to ${fullRoleName}`
                  : `Add ${fullRoleName} Role`
                : initRole
                  ? `Change Role`
                  : `Add Role`}
            </Button>
          </div>
        </DashboardFormFooter>
      </DashboardForm>
    </Fragment>
  );
}

RoleForm.propTypes = {
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func
};

RoleForm.defaultProps = {
  onSubmit() { },
  onRemove() { }
};

export default RoleForm;
