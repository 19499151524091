import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { loadAccountRoutine } from "@/redux/account/routines";
import { logoutAction } from "@/redux/logout/actions";
import { getAccountIsLoaded, getAccountUserFullName } from "@/redux/account/selectors";
import { Can } from "@/components/Ability";
import { useOTP } from "@/lib/core/useOTP";
import { config } from "@/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTryTeamDashboard } from "@/hooks/useTryTeamDashboard";

function AccountNav() {

  const dispatch = useDispatch()
  const history = useHistory()
  const isLoaded = useSelector(getAccountIsLoaded)
  const fullName = useSelector(getAccountUserFullName)
  const { isTeamManager } = useTryTeamDashboard();

  const dispatchLogout = useCallback(() => {
    history.push("/")
    dispatch(logoutAction())
  }, [dispatch, logoutAction])
  const loadAccount = useCallback(() => dispatch(loadAccountRoutine()), [dispatch, loadAccountRoutine])
  const { otp } = useOTP()

  useEffect(() => {
    isLoaded || loadAccount()
  }, [isLoaded])

  const switchToTeamDashboard = () => {
    const destination = new URL("/", config.TEAM_DASHBOARD)
    destination.searchParams.append("otp", otp)
    window.location.href = destination.href
  }

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret data-testid="current-username">
        {isLoaded && fullName}
      </DropdownToggle>
      <DropdownMenu right data-testid="account-menu">
        {isLoaded && <>
          <DropdownItem tag={Link} to="/account/edit">
            Profile
          </DropdownItem>
        </>}
        <Can I="manage" an="email-notification-settings">
          <DropdownItem tag={Link} to="/account/email-notification-settings">
            Email Notifications
          </DropdownItem>
        </Can>
        <DropdownItem tag={Link} to="/account/invitation">
          Accept Invitation
        </DropdownItem>
        <DropdownItem divider />
        {isTeamManager && (
          <DropdownItem onClick={() => switchToTeamDashboard()}>
            Try Team Dashboard (Beta)
          </DropdownItem>)}
        <DropdownItem onClick={() => dispatchLogout()}>Logout</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default AccountNav;
