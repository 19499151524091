import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";
import { FilePicker } from "react-file-picker";

import { useImagePicker } from "./useImagePicker";
import "./style.scss";

export function ImagePicker({ infoText, maxSize, id, initialLogo, onChange, placeholder=null, clear }) {
  
  const { imgSrc, hasImg, error, setError, updateImg, destroy } = useImagePicker({ initialLogo, updateParent: onChange, clear, placeholder });

  return (
    <div id={id} className="filepicker">
      <div>{(hasImg || placeholder) && <img src={imgSrc || placeholder} width="128" height="128" />}</div>
      <div>
        <div className="buttons-container">
          <FilePicker
            onChange={updateImg}
            onError={setError}
            extensions={["png", "jpg", "jpeg"]}
            maxSize={maxSize}
            dims={{
              minWidth: 96,
              minHeight: 96,
              maxWidth: 256,
              maxHeight: 256
            }}
          >
            <Button type="button" size="sm" color="primary" outline>
              {hasImg ? "Update" : "Select Image"}
            </Button>
          </FilePicker>
          {hasImg && (
            <Button type="button" size="sm" color="danger" outline onClick={destroy}>
              Remove
            </Button>
          )}
        </div>
        {!!error && <p className="text-danger">{error}</p>}
        <p>{infoText}</p>
      </div>
    </div>
  );
}

ImagePicker.propTypes = {
  maxSize: PropTypes.number,
  id: PropTypes.string,
  initialLogo: PropTypes.shape({}),
  onChange: PropTypes.func
};

ImagePicker.defaultProps = {
  maxSize: 2,
  id: "",
  initialLogo: {
    file: null,
    url: ""
  },
  onChange: () => {}
};
