import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAssociationList,
  getAssociationListIsLoaded,
  getAssociationListIsLoading,
  getRoleIsGlobal,
} from "@/redux/roleForm/selectors";

import { associationListLoadingRoutine } from "@/redux/roleForm/routines";
import actions from "@/redux/roleForm/actions";

export default function useAssociationSelect() {
  const options = useSelector(getAssociationList);
  const isLoaded = useSelector(getAssociationListIsLoaded);
  const isLoading = useSelector(getAssociationListIsLoading);
  const roleIsGlobal = useSelector(getRoleIsGlobal);
  const dispatch = useDispatch();
  const toggleSelected = useCallback(
    ({ isSelected, id, ...rest }) => {
      const action = isSelected ? "deselectAssociation" : "selectAssociation";
      const fn = actions[action];

      dispatch(fn({ id, ...rest }));
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (!isLoaded) {
        dispatch(associationListLoadingRoutine());
      }
    },
    [dispatch, isLoaded]
  );

  return {
    options,
    isLoaded,
    isLoading,
    isEnabled: !roleIsGlobal,
    toggleSelected
  };
}
