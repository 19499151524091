import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { coachPosition } from "@/utils/codeToTitle";

import FieldValue from "./FieldValue";
import ActionsDropdown from "./ActionsDropdown";

function Coach(props) {
  const { id, muted, firstName, lastName, externalId, position, team, isDeleting } = props;

  return (
    <tr className={classNames({ "coaches-roster__coach--muted": muted })}>
      <td>
        <FieldValue
          teamId={team.id}
          muted={muted}
          value={firstName}
          member={{ type: "coaches", id }}
          name="firstName"
        />
      </td>
      <td>
        <FieldValue teamId={team.id} muted={muted} value={lastName} member={{ type: "coaches", id }} name="lastName">
          {lastName => <span className="coaches-roster__coach__last-name">{lastName}</span>}
        </FieldValue>
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          muted={muted}
          value={externalId}
          member={{ type: "coaches", id }}
          name="externalId"
        />
      </td>
      <td>
        <FieldValue
          teamId={team.id}
          muted={muted}
          value={position}
          member={{ type: "coaches", id }}
          name="position"
          transform={coachPosition}
        />
      </td>
      <td className="text-right">
        <ActionsDropdown
          seasonId={team.season.id}
          teamId={team.id}
          member={{ type: "coaches", id, title: "Coach" }}
          isDeleting={isDeleting}
          disabled={muted}
          rosterLocked={team.data.rosterLocked}
        />
      </td>
    </tr>
  );
}

Coach.propTypes = {
  muted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  team: PropTypes.shape({
    season: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  isDeleting: PropTypes.bool.isRequired
};

Coach.defaultProps = {
  muted: false
};

export default Coach;
