import { connect } from "react-redux";

import List from "./List";

import selectors from "@/redux/coachPenaltyReport/selectors";

import { coachPenaltyReportListLoadingRoutine } from "@/redux/coachPenaltyReport/routines";

const {
  getCoachPenaltyReportListIsLoading,
  getCoachPenaltyReportListIsLoaded,
  getCoachPenaltyReportList,
  getCoachPenaltyReportListTotalCount,
  getCoachPenaltyReportListFilteredCount,
  getCoachPenaltyReportListSearchCurrentQuery,
  getCoachPenaltyReportListHasMore,
  getCoachPenaltyReportListIsAppending
} = selectors;

const mapStateToProps = state => ({
  isLoading: getCoachPenaltyReportListIsLoading(state),
  isLoaded: getCoachPenaltyReportListIsLoaded(state),
  coaches: getCoachPenaltyReportList(state),
  totalCount: getCoachPenaltyReportListTotalCount(state),
  filteredCount: getCoachPenaltyReportListFilteredCount(state),
  currentQuery: getCoachPenaltyReportListSearchCurrentQuery(state),
  hasMore: getCoachPenaltyReportListHasMore(state),
  isAppending: getCoachPenaltyReportListIsAppending(state)
});

const mapDispatchToProps = dispatch => ({
  loadMoreCoaches: () =>
    dispatch(
      coachPenaltyReportListLoadingRoutine({
        append: true
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
